/* ========================================================================================== 
Theme Name: Pro Cleankers 
Author: Metropolitanthemes 
Description: Cleaning Service PSD Template 
Version: 1.0 
========================================================================================== */
/*------------------------------------------------------- CSS INDEX =================== 
0. Preloader 
1. Theme Default CSS 
2. Header 
3. Banner 
4. About 
5. Service 
6. Cta 
7. How it works 
8. Why us 
9. Testimonial 
10. Pricing 
11. Blog 
12. Cta 
13. Footer 
14. Team 
15. Video area 
16. Clents logo 
17. Faq area 
18. Portfolio 
19. About Us 
20. Blog Standared 
21. Blog Details 
22. Contact us 
23. Portfolio 
24. Service 
25. Service details 
26. Error 
27. Login Signup 
28. Coming soon 
29. Partners 
30. Portfolio details 
31. Shop listing 
32. Cart 
33. Checkout 
34. Product detail 
35. Cost Calculator 
36.Team 
37.Gallery 
38.My account
-------------------------------------------------------*/
/*Importing Font*/
/* @import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,400i,500,700,900&display=swap"); */
/* .................................... 0. Preloader .......................................*/
body {
  font-family: "Inter", sans-serif !important;
}
.loader-out {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 110;
  background-color: #fff;
  background-image: url("../img/bg/cleanitems2.jpg");
}
.loader {
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
  0% {
    -webkit-box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    -webkit-box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    -webkit-box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    -webkit-box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    -webkit-box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    -webkit-box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    -webkit-box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    -webkit-box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    -webkit-box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    -webkit-box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    -webkit-box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    -webkit-box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* .................................... 1. Theme Default CSS .......................................*/
/* body {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.4px;
  overflow-y: hidden;
} */
body,
html {
  width: 100%;
  overflow-x: hidden;
}
body.modal-open {
  position: fixed;
}
*:focus {
  outline: 0;
}
.img,
img {
  max-width: 100%;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.f-left {
  float: left;
}
.f-right {
  float: right;
}
.fix {
  overflow: hidden;
}
a,
.button {
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
a:focus,
a:hover {
  text-decoration: none;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
a,
button {
  outline: medium none;
}
button {
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: 0.3s;
}
button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 1.3;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
  line-height: 1.3;
}
h1 {
  font-size: 38px;
  line-height: 1.2;
  font-weight: bold;
}
h2 {
  font-size: 35px;
}
h3 {
  font-size: 28px;
}
h4 {
  font-size: 22px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 16px;
}
ul {
  margin: 0px;
  padding: 0px;
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
}
li {
  list-style: none;
}
p {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.9;
  margin-bottom: 15px;
}
label {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}
*::-moz-selection {
  color: #fff;
  text-shadow: none;
}
::-moz-selection {
  background: #444;
  color: #fff;
  text-shadow: none;
}
::selection {
  background: #444;
  color: #fff;
  text-shadow: none;
}
*::-moz-placeholder {
  font-size: 14px;
  font-weight: 500;
  opacity: 1;
}
*::placeholder {
  font-size: 14px;
  font-weight: 500;
  opacity: 1;
}
.menu ul li {
  display: flex;
  align-items: center;
  justify-content: center;
}
.downloadApp {
  display: flex;
  align-items: center;
  gap: 10px;

  background-color: #f3f7ff;
  border: none;
  border-radius: 5px;
  color: #667283;
  padding: 10px;
  font-size: 1rem;
  overflow: hidden;
  margin-right: 0.4rem;
}

.downloadApp img {
  margin-right: 0.2rem;
}

.downloadApp:hover {
  color: #667283;
  background-color: #f3f7ff;
}

.helpCenter,
.partnerWUs,
.basket,
.login-link {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.helpCenter span,
.partnerWUs span,
.basket span,
.login-link span {
  color: #000;
}
.basket {
  margin-left: 0.2rem;
}
.basket button {
  background-color: transparent;
  border: none;
}
.partnerWUs img,
.login-link img {
  margin-right: 0.4rem;
  width: 20px;
  height: 20px;
  padding: 0;
}
.helpCenter svg {
  font-size: 1.8rem;
  margin-right: 0.4rem;
  width: 24px;
  height: 24px;
}

.basket svg {
  position: relative;
  font-size: 1.8rem;
  margin-right: 0.2rem;
  width: 24px;
  height: 24px;
}
.partnerWUs .pro-badge {
  background: linear-gradient(#e0a269, #e96d6d);
  color: white;
  padding: 2px 5px;
  margin-left: 5px;
  font-size: 12px;
  border-radius: 3px;
}
.basket .badge {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 28px;
  right: 75px;
  width: 15px;
  height: 15px;
  border-radius: 50px;
  background: #3dc0ae;
}
/* .dropdown-menu {
  display: flex;
} */
.grayscaleprefernces {
  filter: grayscale(100%);
}
.input-border::-moz-placeholder {
  color: #201c15;
  font-weight: normal;
}
.input-border::placeholder {
  color: #201c15;
  font-weight: normal;
}

.card-title h1 {
  font-size: 22px !important;
}
.btn.focus,
.btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control {
  border-radius: 0px;
}
.underline {
  text-decoration: underline;
}
.white {
  color: #fff;
}
.bg-white {
  background: #ffffff;
}
.input-white {
  border: none;
  position: relative;
  border-radius: 5px;
  height: 51px;
  padding: 0px 25px;
}
.input-white + i {
  position: absolute;
  right: 25px;
  font-size: 17px;
  opacity: 0.4;
}
/*Buttons*/
.btn,
.btn:hover {
  border-radius: 0px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.btn-blue {
  min-width: 185px;
  text-align: center;
  color: #fff;
  padding: 13px 25px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
}
.btn-blue:hover {
  color: #fff;
  border-radius: 6px;
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
}
.btn-blue-border {
  min-width: 185px;
  text-align: center;
  padding: 13px 25px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  border: 2px solid;
}
.btn-blue-border:hover {
  color: #fff;
  border-radius: 6px;
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
}
.btn-white-border {
  min-width: 185px;
  text-align: center;
  padding: 13px 25px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  border: 2px solid #fff;
}
.btn-white-border:hover {
  color: #fff;
  border-radius: 6px;
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
}
.btn-black {
  min-width: 185px;
  text-align: center;
  color: #fff;
  padding: 13px 25px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
}
.btn-black:hover {
  color: #fff;
  border-radius: 6px;
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
}
.btn-lg {
  min-width: 150px;
  font-size: 14px;
  padding: 10px 24px;
}
/*shine effect*/
.shine-btn {
  transition: 0.6s;
  overflow: hidden;
  position: relative;
}
.shine-btn:focus {
  outline: 0;
}
.shine-btn:before {
  content: "";
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  width: 60px;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0.5;
  -webkit-filter: blur(30px);
  filter: blur(30px);
  -webkit-transform: translateX(-100px) skewX(-15deg);
  transform: translateX(-100px) skewX(-15deg);
}
.shine-btn:after {
  content: "";
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  width: 30px;
  height: 100%;
  left: 30px;
  top: 0;
  opacity: 0;
  -webkit-filter: blur(5px);
  filter: blur(5px);
  -webkit-transform: translateX(-100px) skewX(-15deg);
  transform: translateX(-100px) skewX(-15deg);
}
.shine-btn:hover {
  cursor: pointer;
}
.shine-btn:hover:before {
  -webkit-transform: translateX(300px) skewX(-15deg);
  transform: translateX(300px) skewX(-15deg);
  opacity: 0.6;
  transition: 0.7s;
}
.shine-btn:hover:after {
  -webkit-transform: translateX(300px) skewX(-15deg);
  transform: translateX(300px) skewX(-15deg);
  opacity: 1;
  transition: 0.7s;
}
.sub-head {
  font-size: 14px;
  margin-bottom: 12px;
}
/* .................................... 2. Header .......................................*/
.social-icons li {
  display: inline-block;
  margin-right: 20px;
}
.social-icons li:last-child {
  margin-right: 0px;
}
.email-head,
.social-head {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.main-menu-1 ul li {
  display: inline-block;
  margin-right: 6px;
  position: relative;
}
.main-menu-1 > div > ul > li {
  padding: 7px 0px;
  padding-top: 9px;
}
.main-menu-1 > ul > li {
  padding: 15px 0px;
}
.main-menu-1 ul li a {
  font-size: 15px;
  font-weight: 400;
  padding: 5px 12px;
}
/* .main-menu-1 ul li a:hover {
  background-color: #6fd1ba;
  color: white;
} */

/* .main-menu-1 ul li a.selected {
  color: red;
} */

.main-menu-1 ul li a.active {
  border-radius: 50px;
  position: relative;
  color: #2f846e;
}

.main-menu-1 li .submenu {
  position: absolute;
  width: 220px;
  left: 17%;
  top: 100%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  padding: 13px 0px;
  padding-bottom: 0px;
  opacity: 0;
  visibility: hidden;
  z-index: 25;
  text-align: left;
  background-color: #fff;
}
.main-menu-1 li .mega-menu {
  width: 100%;
  margin: 0 15px;
  padding: 20px 0px;
}
.main-menu-1 li .submenu.right-align {
  left: initial;
  right: 0;
}

.main-menu-1 ul li a.active:after {
  display: none;
  position: absolute;
  content: close-quote;
  left: 13px;
  right: 17px;
  bottom: -28px;
  height: 2px;
  color: #2f846e;
}
.line-after-1 .main-menu-1 ul li a.active:after {
  bottom: -30px;
}
.line-after-1 .main-menu-1 ul li a:active {
  bottom: -30px;
}
.head-type-1.fixed-nav .line-after-1 .main-menu-1 ul li a.active:after {
  bottom: -23px;
}
.bottom-head .row > div.col-12,
.head-type-1 .row > div.col-12 {
  display: none;
}
.main-menu-1 ul > li:hover > .submenu {
  opacity: 1;
  visibility: visible;
  left: 0;
}
.main-menu-1 ul > li:hover > .submenu.right-align {
  left: initial;
}
.main-menu-1 > ul > li > a:hover,
.main-menu-1 > ul > li > a.active {
  border-radius: 50px;
}
.main-menu-1 > ul > li a:hover {
  border-radius: 50px;
}
.main-menu-1 li .submenu li a {
  font-size: 13px;
  width: 100%;
  display: block;
  padding: 7px 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.main-menu-1 li .submenu li {
  width: 100%;
  margin-right: 0px;
}
.main-menu-1 li .submenu li:not(:last-child) {
  border-bottom: 1px solid #f7f7f7;
}
.main-menu-1 li .submenu li:last-child a {
  border: none;
}
.main-menu-1 ul li a span {
  margin-left: 5px;
}
.main-menu-1 li .submenu li a:hover {
  border-radius: 0px;
}
.emergency {
  padding: 15px 40px;
  position: relative;
  overflow: hidden;
  text-align: center;
}
.head-type-1 {
  background-color: #fff;
}
.subheader {
  background: #fff;
}
.serviceList {
  margin-top: 1rem;
  margin-bottom: 0.5rem;

  display: flex;
  justify-content: space-between;
}
.serviceList li {
  cursor: pointer;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
}
.serviceList .discount-per {
  font-size: 12px;
  width: 105px;
  height: 24px;
  padding: 8px;
  border-radius: 20px;
  background: linear-gradient(to right, #ffe4f1, #ffd2e3);
  color: #e573dc;
  font-weight: 600;
  font-size: 12px;

  line-height: 22px;
  text-align: left;
}

.serviceList .discount-per img {
  width: 15px;
  color: #e573dc;
}
.subheader-mob {
  display: none;
}
.serviceList-mob {
  display: flex;

  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin-bottom: 1rem;

  li {
    border-radius: 4px;
    cursor: pointer;
    /* margin-bottom: 0.5rem; */
    position: relative;
    font-size: 14px;

    .offer {
      padding: 0;
      margin-top: -0.8rem;
      color: #e60073;
      font-size: 0.8rem;
    }
  }
}
.serviceList-mob .discount-per {
  position: absolute;
  font-size: 10px;
  width: 98px;
  height: 4px;
  top: 15px;
  /* top: -px; */
  color: #e573dc;
  font-weight: 600;

  text-align: left;
}

.serviceList-mob .discount-per img {
  width: 15px;
  color: #e573dc;
}
.see-more button {
  background-color: #f0f0f0;
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 5px 8px;
  border-radius: 100px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  .arrow-header {
    margin-left: 0.5rem;
  }
}
.subheader-mob-seemore {
  display: none;
  margin-bottom: 1rem;
}
.serviceList-mob-seemore-start,
.serviceList-mob-seemore-mid,
.serviceList-mob-seemore-end {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  align-items: center;
  /* 
  align-items: center; */
}
.serviceList-mob-seemore {
  font-size: 14px;
}
.serviceList-mob-seemore li {
  position: relative;
}
.serviceList-mob-seemore .discount-per {
  position: relative;
  font-size: 10px;
  width: 98px;
  height: 4px;
  top: -8px;

  color: #e573dc;
  font-weight: 600;
}
.serviceList-mob-seemore-start li:not(:nth-last-child(-n + 1))::after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 20px;
  background-color: #111111;
  opacity: 0.3;
  margin-left: 12px;
  vertical-align: middle;
}
.serviceList-mob-seemore-mid li:not(:nth-last-child(-n + 1))::after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 20px;
  background-color: #111111;
  opacity: 0.3;
  margin-left: 18px;
  vertical-align: middle;
}

.serviceList-mob-seemore-end {
}
.serviceList-mob-seemore-end li:not(:nth-last-child(-n + 2))::after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 20px;
  background-color: #111111;
  opacity: 0.3;
  margin-left: 8px;
  vertical-align: middle;
}
.see-less button {
  background-color: #f0f0f0;
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  .arrow-header {
    margin-left: 0.5rem;
  }
}
.head-type-1.fixed-nav .emergency p {
  display: none;
}
.emergency img {
  position: absolute;
  left: -22px;
  top: 0;
  opacity: 0.15;
}
.head-1-book {
  border: 2px solid;
  padding: 7px 18px;
  font-size: 15px;
  border: 1px solid #000;
  box-shadow: rgb(0 0 0 / 64%) 3.4px 3.4px 2.2px;
}
.head-1-book:hover {
  color: #fff;
}
.emergency p {
  margin-bottom: 0px;
}
.shine-inf {
  background-image: linear-gradient(
    to right,
    transparent 33%,
    rgba(255, 255, 255, 0.15) 50%,
    transparent 66%
  );
  background-size: 300% 100%;
  animation: shine 2s infinite;
}
@keyframes shine {
  0% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
}
.head-type-1.fixed-nav,
.head-type-2.fixed-nav {
  position: fixed;
  -webkit-transition: all 0.4s;
  left: 0;
  top: 0;
  right: 0;
  transition: all 0.4s;
  box-shadow: 0 4px 10px rgba(40, 47, 65, 0.06);
  z-index: 20;
  -webkit-animation: showHeader 0.3s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  -moz-animation: showHeader 0.3s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  -o-animation: showHeader 0.3s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  animation: showHeader 0.3s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}
.head-type-2.fixed-nav {
  box-shadow: 0 4px 10px rgba(40, 47, 65, 0.26);
  padding: 8px 0px;
}
.mean-container .mean-nav ul li a.mean-expand {
  height: 28px;
}
.head-type-1.fixed-nav .emergency {
  padding: 21px 40px;
}
.mean-container .mean-nav {
  max-height: 80vh;
  overflow: scroll;
}
.head-type-1.fixed-nav .main-menu-1 ul li a.active:after {
  bottom: -18px;
}
.mobile-trigger {
  display: none;
  border: 2px solid #1e1e2a;
  width: 50px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 3px;
  cursor: pointer;
  padding: 11px 8px;
  background-color: #1e1e2a;
}
.head-type-1 .mobile-trigger {
  right: 15px;
}
.logo {
  margin-top: 10px;
  width: 70px;
  height: 70px;
}
.mobile-trigger > span {
  width: 30px;
  height: 3px;
  background: #fff;
  display: block;
  margin-bottom: 4px;
  transition: all 0.3s;
}
.mobile-trigger > span:last-child {
  margin-bottom: 0;
}
.mobile-trigger.active > span:first-child {
  transform: rotate(45deg);
  position: relative;
  top: 8px;
}
.mobile-trigger.active > span:nth-child(2) {
  display: none;
}
.mobile-trigger.active > span:last-child {
  transform: rotate(-45deg);
  position: relative;
  top: 1px;
}
@media (max-width: 991px) {
  .mobile-trigger {
    display: block;
  }
  .main-menu-1 {
    position: fixed;
    top: 0;
    height: 100%;
    width: 280px;
    background: #fff;
    left: 0;
    z-index: 200;
    transition: 0.3s all;
    transform: translateX(-100%);
    overflow-y: auto;
  }
  .main-menu-1.active {
    transform: translateX(0%);
  }
  .main-menu-1 > div {
    justify-content: flex-start !important;
    display: block !important;
    padding: 40px 0;
  }
  .main-menu-1 ul > li {
    display: block !important;
    width: 100%;
    padding: 0 !important;
  }
  .main-menu-1 ul > li.has-menu > ul {
    margin: 0 5px;
  }
  .main-menu-1 ul > li.has-menu.open > a > span {
    transform: rotate(45deg);
  }
  .main-menu-1 ul > li.has-menu.open > a {
    color: #fff !important;
    background-color: #f15933;
  }
  .main-menu-1 ul > li > a {
    padding: 10px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid;
    color: #111111 !important;
  }
  .main-menu-1 ul > li > .submenu {
    opacity: 1;
    visibility: visible;
    left: 0;
    top: 0 !important;
    position: relative;
    display: none;
    width: calc(100% - 10px);
    background: #fff !important;
  }

  .main-menu-1 li .submenu:after {
    display: none !important;
  }
  .bottom-head {
    height: 50px;
  }
}
@media (min-width: 992px) {
  .main-menu-1 ul > li > .submenu {
    display: block !important;
  }
}
.mobile-menu .mean-nav {
  margin: 0;
  margin-top: 13px;
  background-color: #fff;
  background: #fff;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.mean-container a.meanmenu-reveal span {
  height: 2px;
  width: 30px;
  margin-top: 6px;
}
.mean-container a.meanmenu-reveal {
  top: -49px;
  margin-right: 10px;
  -webkit-transition: none;
  transition: none;
}
.mean-container .mean-bar {
  min-height: 0px;
  padding: 0px;
  background-color: #fff;
  background: #fff;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 99;
}
.head-type-2.head-type-3 .mean-container .mean-bar {
  background: none;
}
.head-type-2 .mean-container .mean-nav {
  background: none;
  height: 0;
}
.head-type-2 .meanmenu-reveal.meanclose + .mean-nav {
  height: auto;
  overflow: auto;
}
.bottom-head .meanmenu-reveal.meanclose + .mean-nav {
  margin-top: 43px;
  overflow: auto;
}
.mean-container a.meanmenu-reveal span.close-menu {
  background: none;
  font-size: 21px;
}
/*header type 2*/
.head-type-2 {
  padding: 15px 0px;
}
.head-1-book-2 {
  padding: 9px 18px;
  font-size: 15px;
  color: #fff;
  height: 42px;
}
.head-1-book-2:hover {
  color: #2f846e;
  background: #dcf6f0;
  border: 1px solid #2f846e;
  transition: 0.4s;
}
.head-1-book-call {
  border: 1px solid #fff;
  padding: 7px 18px;
  font-size: 15px;
  color: #fff;
}
.head-type-2 .main-menu-1 ul li a {
  color: #fff;
}
.head-type-2 .main-menu-1 ul li a.active {
  color: #fff;
}
.head-type-2 .main-menu-1 li .submenu li:not(:last-child) {
  border-bottom: 1px solid rgba(247, 247, 247, 0.05);
}
.head-type-2 .main-menu-1.menu-2 ul li a.active:after {
  position: absolute;
  content: close-quote;
  left: 13px;
  right: 17px;
  bottom: -24px;
  height: 1px;
  background-color: #fff;
}
.head-type-2.fixed-nav .main-menu-1.menu-2 ul li a.active:after {
  bottom: -15px;
}
.head-type-2.head-type-3.fixed-nav .main-menu-1.menu-2 ul li a.active:after {
  position: absolute;
  content: close-quote;
  left: 13px;
  right: 17px;
  bottom: -10px;
  height: 2px;
  background-color: #fff;
}
.head-type-2 .main-menu-1 ul li {
  display: inline-block;
  margin-right: 2px;
  position: relative;
}
.head-1-book-call:hover {
  color: #fff;
}
/*header type 3*/
.head-type-2.head-type-3 {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 99;
  padding: 32px 0px;
}
.head-type-2.head-type-3 .main-menu-1 ul > li:hover > .submenu,
.head-type-2.head-type-3 .main-menu-1 ul > li > .submenu {
  background-color: #fff;
  padding: 10px 0px;
}
.head-type-2.head-type-3 .main-menu-1 li .submenu li:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.head-type-2.head-type-3.fixed-nav {
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(40, 47, 65, 0.1);
  padding: 12px 0px;
}
.head-type-2.head-type-3 .container-fluid,
.slider-3 .container-fluid {
  /* max-width: 1200px; */
}
.head-type-2.head-type-3 .head-1-book-call {
  padding: 9px 18px;
}
.main-menu-1 > div > ul > li > a {
  display: inline-flex;
  flex-direction: row-reverse;
  width: 100%;
}
/* .main-menu-1>div>ul>li>a:before{
     content: '+';
     margin-left: 5px;
} */
.head-type-2.head-type-3 .main-menu-1 > div > ul > li > a {
  padding: 15px 12px;
}
.head-type-2.head-type-3 .main-menu-1 li .submenu {
  top: 100%;
}
.head-type-2.head-type-3 .main-menu-1 li .submenu:after {
  width: 0px;
  position: absolute;
  content: close-quote;
  height: 0px;
  border-top: 10px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  top: -9px;
  transform: translateY(-50%);
  left: 23px;
  opacity: 1;
}
.head-type-2.head-type-3 .head-1-book-call:hover {
  color: #fff;
}
/*header type 4*/
.left-head .social-head ul {
  display: inline-block;
}
.topheader {
  padding: 19px 0px;
}
.social-head ul li a {
  font-size: 16px;
}
.social-head ul li {
  margin-right: 14px;
}
.left-head .email {
  font-size: 15px;
}
.right-head .btn.btn-blue {
  padding: 8px 17px;
  font-weight: 500;
}
.bottom-head .header-1 ul li a {
  color: #fff;
}
.bottom-head .main-menu-1 > div > .menu > ul > li {
  margin-right: 10px;
  padding: 0;
}
.ns-header-1 .main-menu-1 ul li.mega-menu-dropdown,
.head-type-1 .main-menu-1 ul li.mega-menu-dropdown,
.head-type-2 .main-menu-1 ul li.mega-menu-dropdown,
.bottom-head .main-menu-1 > div > ul > li.mega-menu-dropdown {
  position: static;
}
.bottom-head .main-menu-1 li .submenu li:not(:last-child) {
  border-bottom: 1px solid rgba(247, 247, 247, 0.1);
}
.bottom-head .main-menu-1 > div > .menu > ul > li > a.active,
.bottom-head .main-menu-1 > div > .menu > ul > li > a:hover {
  color: #fff;
  border-radius: 0px;
}
.bottom-head .main-menu-1 > div > .menu > ul > li > a {
  padding: 12px 15px;
  display: block;
}
.bottom-head .main-menu-1 li .submenu {
  top: 100%;
}
.search-popup .modal-content {
  background: none;
  border: none;
}
.search-popup .modal-dialog {
  max-width: 1000px;
  width: 100%;
}
.input-search {
  height: 70px;
  background: #fff;
  border-radius: 0;
  border: 2px solid;
  padding-left: 40px;
  border: none;
  font-family: "Roboto Slab", serif;
  font-size: 20px;
  font-weight: 400;
}
.input-search + i {
  position: absolute;
  right: 40px;
  font-size: 20px;
}
.close-search-modal {
  position: fixed;
  right: 50px;
  top: 50px;
  font-size: 25px;
  color: #fff;
  opacity: 0.5;
}
.mean-container .mean-nav ul li a {
  border-top: 1px solid rgba(255, 255, 255, 0.14);
  font-size: 14px;
}
.main-menu-1 li .mega-menu.exd-mega-menu {
  width: 950px;
  right: 0;
  left: auto;
}
.main-menu-1 ul > li:hover > .exd-mega-menu {
  left: auto;
}
.rating-component {
  position: absolute;
  top: 24px;
  left: 90px;
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: 100px;
  border: 1px solid #f4f4f4;
  /* padding: 5px 10px;
  margin-left: 10px; */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Optional: for a subtle shadow */
}

.rating-reviews {
  font-size: 12px;
  color: #888888;
}

.rating-component .rating-stars .fa-star-half {
  margin-right: 0;
}
/* .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 50%;

  margin-right: 40px;
} */

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f7ff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 14px;
  margin-right: 10px;
  margin-left: 5px;
}
.stars {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-top: 2px;
}

.star-icon {
  margin-right: 2px;
}

.reviews {
  font-size: 12px;
  color: #999;
}
.header-mob {
  display: none;
}
.download-bar-mob {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: #f3f7ff;

  margin-top: -1rem;
}
.download-app-mob {
  display: flex;
  justify-content: center;
  gap: 3px;
}
.download-app-mob span {
  color: gray;
}
.rightArrow-mob svg {
  font-size: 1.2rem;
  color: gray;
}

.header-mob .logo-review-menu-mob {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  padding: 8px;
}
.logo-review-mob {
  display: flex;
}
.logo-mob {
  width: 45px;
}
.basket-menu-mob {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.basket-mob,
.menu-mob {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}
.basket-mob button {
  background: transparent;
  border: none;
}
.basket-mob svg {
  font-size: 22px;
}
.menu-mob svg {
  font-size: 18px;
}
.menu-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0s 0.3s;
}
.menu-main {
  display: flex;
  gap: 5px;
  /* top: -10rem; */
  margin-left: 50px;
  margin-top: -15rem;
}
.menu-dialog {
  background: white;
  overflow: hidden;
  width: 228px;
  height: 246px;
  padding: 10px;
  border-radius: 8px;
  position: relative;
  z-index: 1001;

  .menu-dialog-items {
    margin-top: 20px;

    .menu-dialog-logo {
      width: 50px;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        margin: 10px 0;
        font-size: 15px;
        padding: 5px;
        display: flex;

        /* justify-content: center; */
        align-items: center;
        .pro-badge {
          background: linear-gradient(#e0a269, #e96d6d);
          color: white;
          padding: 2px 5px;
          margin-left: 5px;
          font-size: 12px;
          border-radius: 3px;
        }
      }
      li img {
        width: 24px;
        margin-right: 5px;
      }
    }
  }
}
.menu-dialog::before {
  content: "";
  position: absolute;

  z-index: -1;

  width: 157px;
  height: 44px;
  left: calc(50% - 20px / 2 + 20.5px);
  top: 120px;

  background: linear-gradient(#b0fffa, #bcf7f3);
  opacity: 0.6;
  /* Layer Blur */
  filter: blur(25px);
  transform: rotate(-90deg);
}
.menu-dialog-overlay.open {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out;
}
.menu-dialog-overlay.open .menu-main {
  left: 50px;
}
.menu-dialog-close {
  width: 30px;
  height: 30px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}
/* .................................... 3. Banner .......................................*/

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  /* make things invisible upon start */
  -webkit-animation: fadeIn ease-in 1;
  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;
  -webkit-animation-fill-mode: forwards;
  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

#banner-sec .fade-in.one {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  animation-delay: 0.3s;
  position: relative;
  left: 0px;
  bottom: 0px;
}

#banner-sec .fade-in.two {
  -webkit-animation-delay: 0.7s;
  -moz-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

#banner-sec .fade-in.three {
  -webkit-animation-delay: 0.9s;
  -moz-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

/*---make a basic box ---*/

#banner-sec .w-30 {
  position: absolute;
  left: 63%;
  top: 6%;
}
#banner-sec .three {
  position: absolute;
  top: 60%;
  left: -4%;
}
#banner-sec .box-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
#banner-sec {
  background: #f8f7f3;
}
#banner-sec h1 {
  color: #000;
  font-weight: 500;
  font-size: 3.3rem;
}
#banner-sec span {
  color: #000;
  font-weight: 500;
  font-size: 2.8rem;
}
#banner-sec .green-text {
  color: #6fd1ba;
  border: 1px solid #6fd1ba;
  background: #dcf6f0;
  border-radius: 10px;
  font-weight: 500;
  padding: 0rem 1rem;
  font-size: 2.5rem;
}
#banner-sec .blue-text {
  font-weight: 400 !important;
}
#banner-sec .box-cont {
  border: 0.5px solid #000000;
  padding: 1rem 1.5rem;
  background: #fff;
}
#banner-sec .box-cont .dropdown-btn,
#freequote .sbmt-btn {
  padding: 0.5rem 1rem;
  background: #6fd1ba;
  color: #000;
  box-shadow: rgb(0 0 0 / 64%) 3.4px 3.4px 2.2px;
}
#banner-sec .box-cont .banner-btn,
#banner-sec .req-btn {
  padding: 0.5rem 1rem;
  background: #fff;
  color: #000;
  box-shadow: rgb(0 0 0 / 64%) 3.4px 3.4px 2.2px;
}
#banner-sec .box-cont .boston-dropdown {
  border-right: 1px solid #000;
  width: 50%;
}
#freequote input {
  background-color: #fafafa;
  border: 1px solid #a9a9a9;
  box-sizing: border-box;
  color: #000;
  height: 46px;
  padding: 10px;
  position: relative;
}

#freequote .form-check {
  position: relative;
  display: flex;
  padding-left: 1.25rem;
  align-items: center;
}

#freequote .form-check input {
  height: 25px;
}
#banner-sec .box-cont-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
#banner-sec .each-slider {
  min-height: 670px;
  background-size: cover;
  background-position: 32%;
}
#banner-sec .dropdown-sec {
  display: flex;
  width: 77%;
}
#banner-sec .dropdown {
  padding: 0 10px;
}
#banner-sec .banner-text-left {
  margin-left: 90px;
  max-width: 750px;
}
#banner-sec .banner-text-left .banner-check-list {
  margin-bottom: 15px;
}
#banner-sec .banner-text-left h1 {
  font-size: 66px;
}
#banner-sec .banner-check-list li {
  display: inline-block;
  margin-right: 30px;
  font-weight: 400;
}
#banner-sec .banner-check-list li:last-child {
  margin: 0;
}
#banner-sec .banner-check-list li i {
  margin-right: 7px;
}
#banner-sec .banner-text-left p {
  max-width: 550px;
}
#banner-sec select.form-control:not([size]):not([multiple]) {
  border: unset;
  height: unset;
  font-weight: 400;
  width: 70%;
}
.css-1okebmr-indicatorSeparator {
  background-color: unset;
}
.css-14el2xx-placeholder,
.css-tlfecz-indicatorContainer {
  color: #000 !important;
}
.css-1okebmr-indicatorSeparator {
  display: none;
}
.css-1s2u09g-control:hover,
.css-1s2u09g-control {
  border-color: unset !important;
}
.css-1s2u09g-control {
  border: unset;
  border-width: 0px;
  width: 130px;
}
.slider-3.owl-carousel .owl-item.active .banner-text-left ul li {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInDown;
  animation-delay: 0.6s;
}
.slider-3.owl-carousel .owl-item.active .banner-text-left h1 {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInDown;
  animation-delay: 0.8s;
}
.slider-3.owl-carousel .owl-item.active .banner-text-left p {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInDown;
  animation-delay: 1s;
}
.slider-3.owl-carousel .owl-item.active .banner-text-left a {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInDown;
  animation-delay: 1.3s;
}
.dots-img {
  position: absolute;
  max-width: 111px;
  left: -20px;
  top: 0;
}
.main-slider.owl-carousel .owl-nav > div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 56px;
  height: 75px;
  color: #fff;
  border: 2px solid;
  margin: 0;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-slider.owl-carousel .owl-nav .owl-prev {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  left: 0;
  font-size: 20px;
}
.main-slider.owl-carousel .owl-nav .owl-next {
  right: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  font-size: 20px;
}
.main-slider.owl-carousel .owl-nav .owl-next:hover i,
.main-slider.owl-carousel .owl-nav .owl-prev:hover i {
  color: #fff;
}
.slider-2 {
  min-height: 710px;
}
.banner-check-list.white li i {
  color: #fff;
}
.banner-text-left.slide-type-2 {
  margin-left: 0px;
}
.banner-quote-form {
  padding: 38px 28px;
  text-align: center;
  border-radius: 6px;
  padding-top: 26px;
}
.input-white.select-nice {
  width: 100%;
}
.banner-quote-form .input-white {
  padding: 6px 14px;
}
.banner-quote-form .form-group {
  margin-bottom: 15px;
}
.banner-quote-form .input-white:focus + i {
  opacity: 1;
}
.banner-quote-form .input-white + i {
  font-size: 14px;
}
.banner-quote-form .btn-blue {
  font-size: 14px;
  box-shadow: none;
}
.sp-padd-sel1 {
  padding-right: 7px;
}
.sp-padd-sel2 {
  padding-left: 7px;
}
.slider-3.vector-slide {
  min-height: 785px;
  background-position: 50%;
  background-repeat: no-repeat;
}
.zip-code-input {
  max-width: 400px;
  height: 55px;
}
.slider-3.vector-slide .banner-text-left {
  margin-top: 65px;
}
.zip-code-input .input-white {
  height: 55px;
}
.zip-code-input .btn-black {
  min-width: 72px;
}
.zip-code-input .input-white + i {
  position: absolute;
  right: 93px;
  font-size: 15px;
  z-index: 9;
  opacity: 0.3;
}
.slider-3 .banner-text-left {
  margin: 0;
}
.banner-3-check li img {
  max-width: 23px;
}
.banner-3-check li h5 {
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 15px;
}
.banner-3-check {
  max-width: 550px;
}
.banner-3-check.check-list2 li {
  padding-left: 40px;
}
/*banner type 4*/
.banner-type-4 {
  min-height: 573px;
}
.play-btn-white {
  height: 62px;
  width: 62px;
  display: inline-block;
  color: #fff;
  border: 2px solid #fff;
  line-height: 62px;
  border-radius: 50%;
}
.play-btn-white:hover {
  color: #fff;
}
.banner-form-4 {
  background-color: rgba(19, 27, 35, 0.3);
  padding: 20px 20px;
  border-radius: 8px;
  max-width: 800px;
  margin: auto;
}
.banner-form-4 .select-nice:after {
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  height: 9px;
  width: 9px;
  right: 20px;
  top: 48%;
}
.banner-form-4 ul.list {
  width: 100%;
}
.banner-form-4 .form-control {
  padding: 7px 19px;
  font-weight: normal;
}
.banner-form-4 .btn.btn-black {
  min-width: 80px;
}
.feature-bg-icon2 {
  opacity: 0.03;
  position: absolute;
  right: 40vw;
  top: 20px;
  z-index: -1;
}
.mainBanner {
  /* background: #f8f7f3; */
  /* padding: 19%; */

  position: relative;
  overflow: hidden;
  box-shadow: inset 0px 6px 8px -10px #ccc, inset 0px -6px 8px -10px #ccc;
}
.laundry-text {
  font-size: 55px;
  font-weight: bold;
  text-transform: uppercase;

  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2),
    0px 0px 15px rgba(255, 255, 255, 0.7);
  text-align: center;
}

/* .eclipse4 {
  border: 1px solid black;
  height: 200px;
  width: 200px;
  margin-left: 30%;
  margin-top: -10rem;
  padding: 0;
} */
.eclipse4 img {
  width: 500px;
}
.washinImg {
  display: flex;
  justify-content: center;
  margin-top: -1.2rem;
}
.washinImg img {
  width: 250px;
  height: 350px;
}
.washinImg::before {
  content: "";
  position: absolute;
  top: 30%;
  left: 60%;
  transform: translate(-50%, -50%);
  width: 20%;
  height: 50%;
  background: #b6e5df;
  filter: blur(80px);
  border-radius: 50%;
  z-index: -1;
}
.washinImg::after {
  content: "";
  position: absolute;
  top: 40%;
  left: 45%;
  transform: translate(-50%, -50%);
  width: 20%;
  height: 20%;
  background: #94c5bf;
  filter: blur(80px);
  border-radius: 50%;
  z-index: -1;
}
.made-easy {
  font-size: 68px;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: -4.8rem;
  color: #6ed1ba;

  text-align: center;
}
.schedule-text {
  text-align: center;
  font-size: 26px;
  margin-bottom: 1rem;
  font-family: "Inter", sans-serif;
}
.search-bar {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 2px 2px 2px 15px;
  max-width: 530px;
  margin: 0 auto;
  overflow: hidden;
}

.search-input-mob {
  border: none;
  outline: none;
  flex-grow: 1;
  font-size: 16px;
  padding: 10px;
  color: #333;
}
.search-bar-mobile {
  display: none;
  /* display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; */
}
.mobile-search {
  width: 95%;
}
.location-selector-mob {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 2px 15px;
  border: 1px solid #8c8c8c;
  max-width: 100%;
  min-width: 100%;
}
.select-service-mob {
  margin-top: 10px;
  display: flex;
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 3px;
  border: 1px solid #8c8c8c;
  max-width: 100%;
  min-width: 100%;
}
.search-input-mob {
  border: none;
  border-radius: 50px;
  outline: none;
  flex-grow: 1;
  font-size: 16px;
  padding: 10px;
  color: #333;
}
.location-selector {
  display: flex;
  align-items: center;
  padding-right: 10px;
  border-right: 1px solid #e0e0e0;
  margin-right: 10px;
  white-space: nowrap;
}

.icon-location {
  font-size: 1rem;
}

.text {
  font-size: 14px;
  color: #8c8c8c;
}

.location {
  font-weight: bold;
  color: #000;
}

.dropdown-icon {
  margin-left: 5px;
  font-size: 12px;
  color: #8c8c8c;
}

.search-input {
  border: none;
  outline: none;
  flex-grow: 1;
  font-size: 16px;
  padding: 10px;
  color: #333;
}
/* .search-input select {
  width: 100%;
} */
.search-input::placeholder {
  color: #aaa;
  font-size: 15px;
}

.search-button {
  background-color: #3dc0ae;
  border: none;
  border-radius: 50%;
  color: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.search-button:hover {
  background-color: #35ab99;
}

.icon-search {
  font-size: 18px;
}
.discount {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.ribbonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  /* border: 1px solid black; */
}
.ribbon {
  font-size: 16px;
  font-weight: 600;
  color: #e573dc;
  padding: 2px 20px;
  font-family: "Inter", sans-serif;
}
.ribbon {
  --r: 0.8em; /* control the ribbon shape */

  padding-inline: calc(var(--r) + 0.3em);
  line-height: 1.8;
  clip-path: polygon(
    0 0,
    100% 0,
    calc(100% - var(--r)) 50%,
    100% 100%,
    0 100%,
    var(--r) 50%
  );
  background: linear-gradient(90deg, #e5768d21 13%, #d75be221 13%);

  width: fit-content;
}

.bannerEnding {
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  /* background: linear-gradient(to right, #f5f7fa, #c3cfe2); */
}
/* .bannerEnding ul::after {
  content: "";
  position: absolute;
  top: 670px;
  left: 55%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 5%;
  background: #b6e5df;
  filter: blur(27px);
  border-radius: 50%;
  z-index: -1;
} */
.bannerEnding ul {
  display: flex;
  justify-content: space-between;
  background-image: url("../img/Frame\ 9.png");
  background-size: cover; /* Adjust as needed */
  background-position: center; /* Adjust as needed */
  background-repeat: no-repeat; /* Ensure the image doesn't repeat */
  width: 90%;
}
.bannerEnding ul li {
  display: flex;
  justify-content: center;
}
.bannerEnding li img {
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
}
.bannerEnding-mob {
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  /* background: linear-gradient(to right, #f5f7fa, #c3cfe2); */
}
.bannerEnding-mob ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: space-between;
  background-image: url("../img/Frame\ 9.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}
.bannerEnding-mob ul li {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bannerEnding-mob li img {
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
}
.bannerEnding-mob ul li p {
  line-height: normal;
  margin-bottom: 2px;
}
.marquee-text {
  display: none;
  box-sizing: border-box;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  overflow: hidden;
  background: #fff;
}
.marquee-text .top-info-bar {
  font-size: 12px;
  width: 515%;
  display: flex;
  -webkit-animation: marquee 25s linear infinite running;
  -moz-animation: marquee 25s linear infinite running;
  -o-animation: marquee 25s linear infinite running;
  -ms-animation: marquee 25s linear infinite running;
  animation: marquee 25s linear infinite running;
}

.marquee-text .top-info-bar:hover {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  -ms-animation-play-state: paused;
  animation-play-state: paused;
}

.marquee-text .top-info-bar .info-text {
  padding: 10px 30px;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.marquee-text .top-info-bar li {
  color: #000;
  text-decoration: none;
}

@-moz-keyframes marquee {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -o-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}

@-webkit-keyframes marquee {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -o-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}

@-o-keyframes marquee {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -o-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}

@keyframes marquee {
  0% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -o-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}

/* .................................... 4. About .......................................*/
.about-count {
  position: absolute;
  top: -10px;
  left: -10px;
  text-align: center;
  padding: 40px 30px;
  border-radius: 6px;
  padding-top: 30px;
}
.about-count p {
  margin-bottom: 0;
}
.about-count h3 {
  margin-bottom: 0px;
  margin-top: 0px;
}
.about-count a {
  margin-top: 8px;
}
.about-count:before {
  position: absolute;
  content: close-quote;
  left: 7px;
  right: 7px;
  bottom: 7px;
  top: 7px;
  border: 1px solid #fff;
  border-radius: 6px;
  opacity: 0.5;
}
.sub-head span {
  position: relative;
  top: -4px;
  margin: 0px 15px;
  display: inline-block;
}
.about-div .check-list {
  margin-top: 24px;
  margin-bottom: 21px;
}
.check-list li {
  position: relative;
  padding-left: 36px;
  margin-bottom: 12px;
  font-size: 17px;
}
.check-list li span {
  position: absolute;
  left: 0;
  width: 23px;
  height: 23px;
  border: 2px solid;
  border-radius: 50%;
  text-align: center;
  font-size: 10px;
  line-height: 20px;
  top: 2px;
}
.check-list.half-list li {
  width: 49%;
  vertical-align: top;
  display: inline-block;
}
.bg-dot-right {
  background-image: url(../img/other/dots2.png);
  background-repeat: repeat-y;
  background-position: right top;
  background-size: 330px;
}
.bg-dot-full {
  background-image: url(../img/other/dots2.png);
  background-size: 330px;
}
.bg-dot-full2 {
  background-image: url(../img/other/dots3.png);
  background-size: 330px;
}
.about-bg-icon {
  position: absolute;
  top: -150px;
  right: 131px;
  opacity: 0.03;
}
/*about type 2*/
.about-2 {
  position: relative;
  overflow: hidden;
}
.about-bg-dot-icon {
  position: absolute;
  right: -17vw;
  top: 0;
}
.vector-abt-img {
  position: absolute;
  right: 49vw;
  top: 0;
  width: 973px;
}
.vector-abt-img img {
  width: 973px;
}
/*about type 3*/
.abt-brush {
  position: absolute;
  right: 70px;
  top: -2px;
  z-index: -1;
  max-width: 130px;
  opacity: 0.04;
}
.abt-bubble {
  position: absolute;
  left: 6px;
  bottom: -25px;
  z-index: -1;
  max-width: 150px;
  opacity: 0.04;
}
/*about type 4*/
.lady-img {
  float: right;
}

/* small cards sec start */
#small-cards .card-content {
  display: flex;
  height: 100%;
  align-items: center;
}

#small-cards .card-content .card-mg {
  width: 50px;
}

#small-cards .card-box {
  display: flex;
  width: 100%;
  justify-content: space-around;
  border: 1px solid #000;
  padding: 2rem;
  margin-top: -30px;
  background: #fff;
  box-shadow: rgb(0 0 0 / 64%) 7.4px 7.4px 2.2px;
}
/* small cards sec end */

/* booking sec */
#booking-sec {
  margin-bottom: 7rem;
}

#booking-sec .card-box {
  background: #2f846e;
  display: flex;
  height: 100%;
  align-items: center;
  position: relative;
  padding: 4rem;
  border-radius: 24px;
}

#booking-sec h2 {
  font-size: 2rem;
  padding: 0rem 6rem;
  margin-bottom: 2rem;
  font-weight: 600;
}

#booking-sec .book-btn {
  background: #fff;
  border-radius: 24px;
  color: #2f846e;
  padding: 0.5rem 2rem;
  border: 1px solid #2f846e;
}

#booking-sec .book-btn:hover {
  background: #2f846e;
  color: #fff;
  transition: 0.4s;
  border: 1px solid #fff;
}

#booking-sec .leftImg {
  width: 75px;
  position: absolute;
  top: 0;
  left: 0;
}

#booking-sec .rightImg {
  width: 75px;
  position: absolute;
  right: 0;
  bottom: 0;
}
/* help center css starts */

#helpBanner .search {
  position: relative;
  box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
  width: 50%;
  margin-top: 2rem;
}

#helpBanner .search input {
  height: 60px;
  text-indent: 25px;
  border: 2px solid #d6d4d4;
  border-radius: 30px;
}

#helpBanner .search input:focus {
  box-shadow: none;
  border: 2px solid #d6d4d4;
}

#helpBanner .search .fa-search {
  position: absolute;
  top: 22px;
  left: 15px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 20px;
}

#helpBanner .search button {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 50px;
  width: 110px;
  background: #2f846e;
  border-radius: 30px;
}
/* help center ends */

/* local business sec */
.green-text {
  color: #2f846e;
  font-weight: 600;
}
#BussineSupport {
  margin-top: 6rem;
}
#BussineSupport .read-btn {
  background: #2f846e;
  border-radius: 24px;
  color: #fff;
  padding: 0.5rem 2rem;
  float: left;
}

#BussineSupport .read-btn:hover {
  background: #fff;
  color: #2f846e;
  transition: 0.4s;
  border: 1px solid #2f846e;
}

#BussineSupport .quoted-text {
  background: #edfbf7;
  border-radius: 39px 39px 0px 39px;
  padding: 20px 190px 10px 31px;
  margin-top: 1rem;
}

#BussineSupport .quoted-text img {
  width: 23px;
}

#BussineSupport .quoted-text p {
  font-weight: 600;
  padding-top: 10px;
  line-height: 1.5;
}

#BussineSupport .local-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  margin-right: 2rem;
}
#EverydayTask .content-bg {
  /* background: url("../img/bg/light-circle.png"); */
  background-repeat: no-repeat;
  background-size: 35%;
  background-position-x: right;
  background-position-y: inherit;
  position: relative;
}
#EverydayTask .local-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  justify-content: center;
  z-index: 3;
  position: relative;
  width: 50%;
}
#EverydayTask .dbl-trianle {
  position: absolute;
  left: 76%;
  z-index: 2;
  width: 20%;
}
#EverydayTask h2 {
  margin-bottom: 1rem;
}
/* need sec */
#WhateverUneed {
  margin-bottom: 7rem;
}
#WhateverUneed .need-img {
  float: right;
  width: 52%;
}

#GetTheMost {
  background: linear-gradient(#effffb 60%, #fff 20%);
}
#GetTheMost h2 {
  color: #2f846e;
  font-weight: 700;
}
#GetTheMost .card {
  background: #6fd1ba;
  border-radius: 51px 100px 51px 51px;
  color: #fff;
}

#GetTheMost .card-body {
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

#GetTheMost .card-body p {
  font-size: 1.1rem;
  line-height: 1.3;
}

#GetTheMost .card-body small a {
  text-decoration: underline;
}

#WhateverUneed h2 {
  color: #00554a;
  padding-top: 4rem;
  font-weight: 600;
}

#WhateverUneed .whatev-row {
  background: linear-gradient(#f6fdfb 80%, #fff 20%);
}

#WhateverUneed .local-content {
  padding-left: 4rem;
}

#WhateverUneed .img-align {
  float: right;
  margin-right: -33px;
}

#WhateverUneed .left-sec {
  padding-right: unset;
}

#WhateverUneed .right-sec {
  padding-left: unset;
}

/* .................................... 5. Service .......................................*/
#tab-cards.pt-90 {
  padding-top: 85px;
}
#tab-cards .prdt-tabs {
  /* margin-top: 2rem; */
  /* background: linear-gradient(
    to bottom,
    #6fd1ba 8.29%,
    #6fd1ba 0%,
    #fff 0%,
    #fff 82%
  ); */
}
#tab-cards .prdt-tab {
  /* background: unset !important; */
}
#tab-cards .nav-tabs .nav-link {
  border: 1px solid #000;
  margin-right: 1rem;
  border-radius: 8px;
}
#tab-cards .nav-tabs .nav-link.active,
#tab-cards .nav-tabs .show > .nav-link {
  background: #fff;
  border: 1px solid #fff;
  transition: 0.4s;
}
#tab-cards .green-bg {
  background: #6fd1ba;
  padding: 1rem 13rem;
  /* padding-top: 1rem;
  padding-left: 1rem;
  padding-bottom: 1rem; */
}
#tab-cards .prdt-tab .tab-nav {
  background: #6fd1ba;
  padding-bottom: 1rem;
  /* padding-left: 1rem; */
  padding: 1rem 13rem;
}
#tab-cards .prdt-tab .tab-nav .nav-item {
  margin-bottom: 1rem;
}
#summay-service-box {
  padding-top: 10rem;
  padding-bottom: 5rem;
}
#service-box {
  padding: 2rem 0;
  /* background: #effffb; */
}
#service-box .all_cards {
  margin-bottom: 2rem;
}
#selected-card .card,
#service-box .card {
  border-radius: 13px;
  margin-bottom: 2rem;
  border: 2px solid #ececec;
}
#service-box .card.service-card {
  background: #f4f4f4;
  padding: 10px;
  display: flex;
  height: 100%;
  align-items: center;
}
#service-box .col-md-4.wow.fadeInUp {
  margin-bottom: 2rem;
}
#selected-card .card {
  border: unset;
}
#selected-card .small-text {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: unset;
}
#selected-card .nimnimIt {
  /* border: 3px solid #cccccc;
  background: #fff; */
  border-radius: 8px;
  color: #fff;
  margin-top: 1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 9.5px;
  border: 1px solid #6fd1ba;
  background: #6fd1ba !important;
}
#selected-card .rush-dlvr {
  border: 1px solid #6fd1ba;
  background: #6fd1ba !important;
  border-radius: 8px;
  color: #fff;
  margin-top: 1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 9.5px;
}
#selected-card .nimnimIt:hover,
#selected-card .rush-dlvr:hover {
  border: 1px solid #cccccc;
  color: #2f846e;
  background: #fff !important;
  transition: 0.4s;
}
#selected-card .tab-content .note-textarea {
  border-width: 0px 0px 2px 0px;
  background: transparent;
}
.tab-content {
  /* background: #effffb; */
}
#preferences,
.notes {
  background: #f6f6f6;
  padding: 2rem;
}
#service-box .card.service-card:hover {
  border: 2px solid #6fd1ba;
  background: #6fd1ba;
  transition: 0.4s;
}
#service-box .card.service-card:hover .card-footer {
  background: #fff;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
  transition: 0.4s;
}
#service-box .book-btn {
  background: #fff;
  color: #000;
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 1px solid #000;
}
#service-box .card:hover .book-btn {
  background: #6fd1ba;
}
#summary-contentTable .card:hover .card-footer {
  background: unset !important;
}
#summary-item-card .time-content {
  border: 1px solid rgba(51, 51, 51, 0.6);
  margin: 6px;
  flex: 0 0 27%;
  padding: 8px 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
}
#summary-item-card p {
  font-size: 11px;
  color: rgba(51, 51, 51, 0.6);
  font-weight: 300;
  margin-bottom: 0;
}
#summary-item-card h6 {
  font-size: 12px;
  color: rgba(51, 51, 51, 0.6);
  font-weight: 400;
  margin-bottom: 0;
  width: max-content;
  display: contents;
}
#service-box .card:hover .includes span {
  background: rgba(111, 209, 186, 0.1);
  border: 1px solid #6fd1ba;
  color: #6fd1ba;
  transition: 0.4s;
}
#summary-contentTable .btn {
  padding: 0px 10px !important;
  margin-top: 0.5rem;
}
#summary-contentTable tr {
  background: unset !important;
  margin-bottom: unset !important;
  padding: unset !important;
}
#summary-contentTable td {
  /* line-height: 3; */
  font-size: 14px;
  text-align: left;
}
/* form toggle radio start */
#radio-toggler.wrapper {
  display: inline-flex;
  width: 100%;
}
#radio-toggler.wrapper .option {
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 0.6rem;
}
#radio-toggler.wrapper .option .dot {
  display: none;
}
#radio-toggler.wrapper .option .dot::before {
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #0069d9;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}
#radio-toggler input[type="radio"] {
  display: none;
}
#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2 {
  border-color: #6fd1ba;
  background: #6fd1ba;
}
#option-1:checked:checked ~ .option-1 .dot,
#option-2:checked:checked ~ .option-2 .dot {
  background: #fff;
}
#option-1:checked:checked ~ .option-1 .dot::before,
#option-2:checked:checked ~ .option-2 .dot::before {
  opacity: 1;
  transform: scale(1);
}
.wrapper .option span {
  font-size: 20px;
  color: #808080;
}
#option-1:checked:checked ~ .option-1 span,
#option-2:checked:checked ~ .option-2 span {
  color: #000;
}
.switch-field {
  display: flex;
  overflow: hidden;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  line-height: 1;
  text-align: center;
  padding: 8px 8px;
  margin-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  transition: all 0.1s ease-in-out;
  border-radius: 10px;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: #6fd1ba;
  color: #fff;
  box-shadow: none;
  border: 1px solid #6fd1ba;
}
#Search {
  position: relative;
  margin-top: 2rem;
}
#Search input[type="text"] {
  position: relative;
  width: 100%;
  height: 45px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  padding: 2px 40px 2px 10px;
  top: 7px;
}
#Search input[type="submit"] {
  position: absolute;
  width: 70px;
  height: 33px;
  top: 5px;
  right: 0px;
  border: none;
  color: white;
  background: #6fd1ba;
  display: block;
  margin: 8px;
  cursor: pointer;
  border-radius: 8px;
}
#service-box .card:hover .card-footer span {
  color: #000;
  transition: 0.4s;
}
#service-box .card img {
  margin-top: 0rem;
}
#service-box .card-text,
#selected-card .card-text {
  padding: 0 0rem 1.25rem;
  font-weight: 500;
}
#service-box .card-body,
#selected-card .card-body {
  display: flex;
  height: 100%;
  align-items: stretch;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem 0.3rem;
}
#service-box .card-footer {
  display: flex;
  width: 100%;
  text-align: left;
  background: #fff;
  border-top: unset;
  border: 1px solid #fff;
  border-radius: 10px;
}
#service-box .card-footer,
#selected-card .card-footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
#service-box .price {
  display: grid;
  color: #000;
}
#selected-card .card-footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
#service-box .includes,
#selected-card .includes {
  float: right;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: left;
  margin-top: 0rem;
  align-items: center;
}
#service-box .includes span {
  background: #ececec;
  text-transform: uppercase;
  font-weight: 500;
  padding: 0px 6px;
  border-radius: 5px;
  font-size: 11px;
  margin: 5px 5px;
  line-height: 2.5;
  border: 1px solid #ececec;
}
#selected-card .includes span {
  text-transform: uppercase;
  font-weight: 500;
  padding: 4px;
  border-radius: 5px;
  font-size: 11px;
  /* margin: 0 5px; */
  background: rgba(111, 209, 186, 0.1);
  border: 1px solid #6fd1ba;
  color: #6fd1ba;
}
#selected-card .prdt-tabs {
  background: unset !important;
}
#service-faq .faq-boxes .collapseparent {
  background: #effffb;
}
#service-faq .faq-boxes .accordion > .card:hover #service-faq .card-header {
  background: #effffb;
}
#tab-selected-card .select-container {
  margin-top: 2rem;
}

#tab-selected-card .nav-tabs .nav-link.active {
  background-color: unset !important;
  border-bottom-color: #6fd1ba;

  border-radius: 0;
  border-width: 2px;
  color: #828282 !important;
}
#tab-selected-card .table th {
  color: rgba(51, 51, 51, 0.5);
}
#tab-selected-card table.mt-4 {
  background: #fff;
  border-radius: 7px;
}
#tab-selected-card table {
  border-collapse: separate;
  border-spacing: 0 0px;
  border: none;
}
#tab-selected-card table tr,
#summary-detail-table tr,
#service-box tr {
  /* background: #effffb; */
  background: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 1rem; */
  /* border-radius: 6px; */
  padding: 0.5rem 0.5rem;
}

.bg-green {
  background: #effffb;
}
#tab-selected-card table tr {
  justify-content: space-between;
  cursor: pointer;
  /* background: #f6f6f6; */
}
#summary-detail-table th,
#summary-detail-table td {
  border-top: unset;
}
#tab-selected-card #summary-table tr {
  background: transparent;
}
#summary-right-card #summary-table td,
#summary-item-card #summary-table td {
  font-size: 12px;
  font-weight: 400 !important;
  width: 100%;
  line-height: 1.5;
}

#summary-table .add-to-cart-button,
#add-to-cart-button {
  height: 35px;
  width: auto;
  background: #ffffff;
  border: 0.5px solid #333333;
  border-radius: 10px;
  color: #000000;
  margin: 0 5px 0 5px;
  padding: 0 10px;
}

#summary-table .plus-minus-button,
#plus-minus-button {
  height: 25px;
  width: 70px;
  background: #aee5d8;
  border-radius: 8px;
  color: #000000;
  margin: 0 5px 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
}
/* #summary-table .quantity-td {
  display: none;
} */
#summary-item-card .card-item-space {
  margin: 1.7rem 0;
}
#summary-item-card .coupon {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
#summary-item-card .coupon,
#summary-item-card .address,
#summary-item-card .time {
  padding: 10px;
}
#summary-item-card .card-body {
  padding: 0rem 0.3rem;
}
#summary-item-card .card:hover .card-footer {
  background: unset;
}
#summary-item-card .preference-font {
  font-size: 14px;
}
#tab-selected-card .table td,
#tab-selected-card .table th,
#service-box .table td,
#service-box .table th {
  border-top: unset;
  font-weight: 500;
  text-align: left;
  /* width: 25%; */
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: left;
  /* justify-content: space-evenly; */
}
#service-box .table td span {
  background: #6fd1ba;
  padding: 1px 4px;
  color: #fff;
}
#selected-card .tab-pane .scroll-table {
  /* height: 370px;
  overflow-y: scroll; */
}
#selected-card .card-mg {
  width: 10%;
}
.scroll-table .gray-bg {
  background: #f6f6f6;
  padding: 2rem 1rem;
}
#selected-card table .td-content {
  display: flex;
  width: 65%;
  justify-content: space-between;
}
#selected-card .fix-header {
  position: sticky;
  top: 0;
  background: #fff;
  width: 100%;
}
#tab-selected-card .table td span.digit {
  float: left;
}
#tab-selected-card .table td span.piece {
  background: #6fd1ba;
  color: #fff;
  padding: 2px;
  font-size: 11px;
  margin-left: 1rem;
  float: right;
}
#tab-selected-card .table td span.radio-btn {
  background: transparent;
  color: #000;
  margin-right: 8px;
  padding-top: unset;
}
#tab-selected-card .tabl td.d-flex input {
  margin-right: 3px;
}

#tab-selected-card .looking-for,
#service-box .looking-for-content {
  border: 1px dashed #bebfbf;
  border-radius: 16px;
}
#tab-selected-card p.rush-delivery {
  background: #effffb;
  padding: 5px 10px;
}
#summary-right-card .card-header,
#summary-item-card .card-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: unset;
  border-bottom: unset;
}
#summary-right-card .card {
  border: 2px solid #fff;
  border-radius: 15px;
  background: unset !important;
}
#summary-right-card .card,
#summary-item-card .card {
  border: unset;
  border-radius: 15px;
}
#summary-right-card hr,
#summary-item-card hr {
  margin: 0.5rem 0rem;
}
#summary-right-card .card-footer,
#summary-item-card .card-footer {
  background: unset;
  border-top: unset;
}
#form-modal .modal-content .delete-btn {
  padding-left: 4rem;
  color: #01729e;
}
#form-modal .modal-content .edit-btn {
  color: #01729e;
  padding-left: 1rem;
}
#summary-right-card .card-text {
  /* background: #F2F2F2; */
  background: #fff;
  /* border: 1px dashed #bebfbf; */
  /* border-radius: 16px; */
  /* padding: 1rem 1.3rem; */
  font-size: 12px;
  width: 100%;
}
#summary-item-card .card-text {
  background: #fff;
  border: 1px dashed #bebfbf;
  border-radius: 16px;
  border: unset;
  padding: 0rem 1rem;
  font-size: 12px;
  width: 100%;
}
#summary-right-card .btn,
#summary-button-submit,
#summary-item-card .btn {
  background: #6fd1ba;
  border-radius: 8px;
  width: 100%;
  padding: 10px;
  color: #fff;
  font-size: 13px;
  border: 1px solid #6fd1ba;
}
#summary-right-card .btn:hover,
#summary-item-card .btn:hover {
  background: #fff;
  color: #6fd1ba;
  transition: 0.4s;
}
#card-sec .service-btn-sec,
#partner-card-sec .service-btn-sec {
  width: 20%;
  display: inline-block;
  margin-bottom: 1rem;
}
#card-sec h3,
#partner-card-sec h3 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
  width: 60%;
}
#card-sec h2,
#partner-card-sec h2,
#BussineSupport h2,
#EverydayTask h2 {
  font-weight: 700;
  color: #000;
  font-size: 2rem;
  margin-bottom: 1rem;
  width: 100%;
}
#card-sec .feature-div,
#partner-card-sec .feature-div {
  display: flex;
  width: 100%;
  justify-content: center;
}
#card-sec .feature-text,
#partner-card-sec .feature-text {
  border: 1px solid #dcf6f0;
  background: rgba(220, 247, 240, 0.5);
  padding: 0.5rem 1rem;
  border-radius: 25px;
  margin: 0 0.6rem;
}
#card-sec .green-text,
#partner-card-sec .green-text {
  color: #2f846e;
  font-weight: 800;
}
#card-sec .service-text {
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 70%;
  align-items: flex-start;
  justify-content: space-between;
}
#partner-card-sec .service-text {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
#card-sec .service-each,
#partner-card-sec .service-each {
  background-color: #fff;
  padding: 40px 20px;
  padding-bottom: 35px;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  justify-content: space-between;
}

#card-sec .service-each a,
#partner-card-sec .service-each a {
  display: flex;
}

#card-sec .service-btn,
#partner-card-sec .service-btn {
  width: 100%;
  color: #6fd1ba;
  border: 1px solid #6fd1ba;
  border-radius: 20px;
  text-align: center;
  background: rgba(111, 209, 186, 0.1);
}

#partner-card-sec .service-btn {
  border-radius: 10px;
}

#small-cards .service-text {
  text-align: left;
  padding-left: 11px;
}

.linkText:hover {
  font-weight: bold;
}

.service-icn {
  /* height: 90px;
     width: 90px; */
  margin: auto;
  border-radius: 60px;
  margin-bottom: 20px;
}
.line-servcie {
  width: 40px;
  height: 2px;
  display: inline-block;
}
.service-each:hover .service-text {
  /* color: #fff; */
}

/* service details procss flow start */
#tab-selected-card .tracking-detail {
  padding: 3rem 0;
}
#tab-selected-card h4 {
  color: #000;
  font-size: 1.2rem;
  text-align: left;
}
#tab-selected-card p {
  text-align: left;
}
#tracking {
  margin-bottom: 1rem;
}
[class*="tracking-status-"] p {
  margin: 0;
  font-size: 1.1rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}
[class*="tracking-status-"] {
  padding: 1.6rem 0;
}
#tracking .tracking-item {
  border-left: 2px solid #ececec;
  position: relative;
  padding: 2rem 1.5rem 0.5rem 1.5rem;
  font-size: 0.9rem;
  margin-left: 1rem;
  min-height: 5rem;
}
#tracking .tracking-item:last-child {
  padding-bottom: 4rem;
}
#tracking .tracking-item .tracking-date {
  margin-bottom: 0.5rem;
}
#tracking .tracking-item .tracking-date span {
  color: #888;
  font-size: 85%;
  padding-left: 0.4rem;
}
#tracking .tracking-item .tracking-content {
  padding: 0.5rem 0.8rem;
  background-color: #f4f4f4;
  border-radius: 0.5rem;
  text-align: left;
}
#tracking .tracking-item .tracking-content span {
  display: block;
  color: #767676;
  font-size: 13px;
}
#tracking .tracking-item .tracking-icon {
  position: absolute;
  left: -1rem;
  width: 1.7rem;
  height: 1.7rem;
  text-align: center;
  border-radius: 50%;
  background-color: #fff;
}

#tracking .tracking-item-pending {
  border-left: 4px solid #d6d6d6;
  position: relative;
  padding: 2rem 1.5rem 0.5rem 2.5rem;
  font-size: 0.9rem;
  margin-left: 3rem;
  min-height: 5rem;
}
#tracking .tracking-item-pending:last-child {
  padding-bottom: 4rem;
}
#tracking .tracking-item-pending .tracking-date {
  margin-bottom: 0.5rem;
}
#tracking .tracking-item-pending .tracking-date span {
  color: #888;
  font-size: 85%;
  padding-left: 0.4rem;
}
#tracking .tracking-item-pending .tracking-content {
  padding: 0.5rem 0.8rem;
  background-color: #f4f4f4;
  border-radius: 0.5rem;
}
#tracking .tracking-item-pending .tracking-content span {
  display: block;
  color: #767676;
  font-size: 13px;
}
#tracking .tracking-item-pending .tracking-icon {
  line-height: 2.6rem;
  position: absolute;
  left: -0.7rem;
  width: 1.1rem;
  height: 1.1rem;
  text-align: center;
  border-radius: 50%;
  font-size: 1.1rem;
  color: #d6d6d6;
}
#tracking .tracking-item-pending .tracking-content {
  font-weight: 600;
  font-size: 17px;
}

#tracking .tracking-item .tracking-icon.status-current {
  width: 1.9rem;
  height: 1.9rem;
  left: -1.1rem;
}
#tracking .tracking-item .tracking-icon.status-intransit {
  color: transparent;
  font-size: 0.6rem;
}
#tracking .tracking-item .tracking-icon.status-current {
  color: #d9d9d9;
  font-size: 0.6rem;
}
@media (min-width: 992px) {
  #tracking .tracking-item .tracking-date {
    position: absolute;
    left: -10rem;
    width: 7.5rem;
    text-align: right;
  }
  #tracking .tracking-item .tracking-date span {
    display: block;
  }
  #tracking .tracking-item .tracking-content {
    padding: 0;
    background-color: transparent;
  }

  #tracking .tracking-item-pending {
    margin-left: 10rem;
  }
  #tracking .tracking-item-pending .tracking-date {
    position: absolute;
    left: -10rem;
    width: 7.5rem;
    text-align: right;
  }
  #tracking .tracking-item-pending .tracking-date span {
    display: block;
  }
  #tracking .tracking-item-pending .tracking-content {
    padding: 0;
    background-color: transparent;
  }
}

#tracking .tracking-item .tracking-content {
  font-weight: 600;
  font-size: 17px;
}

#tracking .blinker {
  border: 7px solid #e9f8ea;
  animation: blink 1s;
  animation-iteration-count: infinite;
}
@keyframes blink {
  50% {
    border-color: #fff;
  }
}

/* service details procss flow end */
.service-each:hover .line-servcie {
  background-color: #fff;
}
.service-each:hover .service-icn {
  background-color: #fff;
}
.service-each:hover {
  background-color: unset !important;
}
.service-cg-icon {
  position: absolute;
  left: -30px;
  top: 10px;
  opacity: 0.04;
}
.btn-blue.btn-md {
  min-width: 150px;
  font-size: 14px;
  padding: 10px 24px;
}
.service-text h3 {
  line-height: 1.2;
}
.service-each .service-text h3 {
  margin-bottom: 8px;
  font-size: 22px;
  margin-top: 24px;
}
.service-each .service-text p {
  margin-bottom: 2px;
}

/* popup form start */
.modal-backdrop.show {
  opacity: 0.4;
}
#form-modal .countDown {
  background: rgba(129, 215, 194, 0.1);
  border: 1px solid #6fd1ba;
  border-radius: 12px;
  padding: 0.5rem;
  color: #6fd1ba;
  margin-top: 1rem;
}
.gray-color {
  color: #898989;
  font-weight: 400;
}
#form-modal .countDown p {
  margin-bottom: unset;
  font-size: 13px;
  font-weight: 500;
}
select.form-control:not([size]):not([multiple]) {
  border: 1px solid #898989;
  border-radius: 9px;
  font-weight: 700;
}
#form-modal .btn,
#form-modal .proceed-btn,
#contact-modal .btn,
#contact-modal .proceed-btn {
  border: 1px solid #898989;
  border-radius: 8px;
  background: #fff;
  color: #000;
  padding: 0.7rem;
  font-weight: 600;
}
#form-modal .proceed-btn,
#contact-modal .proceed-btn {
  background: #81d7c2;
  border: 1px solid #81d7c2;
  color: #fff;
  font-weight: 500;
}
#contact-modal .modal-body {
  padding: 0 0;
}
#contact-modal .btn {
  /* border: 1px solid rgba(0, 0, 0, 0.5); */
  padding: 0.4rem 1rem;
  font-weight: 500;
}
#form-modal hr {
  background: #000;
}
#form-modal .btn:hover,
#form-modal .proceed-btn:hover,
#contact-modal .btn:hover {
  background: rgba(111, 209, 186, 0.1);
  border: 1px solid #6fd1ba;
  color: #6fd1ba;
  transition: 0.4s;
}
#contact-modal .modal-footer {
  border-top: unset;
}
/* popup form end */

/*service type 2*/
.service-2-each {
  text-align: center;
  position: relative;
  background-color: #fff;
}
.service2-img .icon {
  display: inline-block;
  width: 74px;
  height: 74px;
  padding: 18px;
  border-radius: 50%;
  position: absolute;
  bottom: -36px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.service2-content {
  padding: 25px 30px;
  padding-top: 57px;
}
.service2-img {
  position: relative;
}
.service2-img img {
  width: 100%;
}
.service2-content .line {
  width: 41px;
  height: 2px;
  display: block;
  margin: auto;
  margin-bottom: 7px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.service-2-each:hover .service2-content .line {
  width: 60px;
}
.service-2-each .service2-content h3 {
  margin-bottom: 8px;
}
.service-2-each .service2-content p {
  margin-bottom: 23px;
}
.service-2-each .service2-content h3 a {
  font-size: 26px;
}
/*service type 3*/
.service-each-3 {
  background-color: #fff;
  padding: 40px 20px;
  border-radius: 7px;
  margin-bottom: 30px;
}
.service-icon-3 {
  width: 141px;
  height: 119px;
  position: relative;
  margin: auto;
  border-top-left-radius: 115px;
  border-top-right-radius: 185px;
  border-bottom-left-radius: 125px;
  border-bottom-right-radius: 85px;
}
.service-each-3 .service-text-3 h3 {
  margin-top: 25px;
  font-size: 22px;
  margin-bottom: 4px;
}
.service-each-3 .service-text-3 p {
  margin-top: 8px;
  margin-bottom: 15px;
}
.service-icon-3:after {
  position: absolute;
  content: close-quote;
  width: 20px;
  height: 20px;
  background-color: #eef4f8;
  border-radius: 50%;
  right: -3px;
  top: 0;
}
.service-icon-3:before {
  position: absolute;
  content: close-quote;
  width: 15px;
  height: 15px;
  background-color: #eef4f8;
  border-radius: 50%;
  left: -9px;
  bottom: 0;
}
.service-icon-3 img {
  -webkit-filter: grayscale(80%) contrast(90%) brightness(30%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(80%) contrast(90%) brightness(30%);
}
.service-each-3,
.service-icon-3,
.service-icon-3:hover,
.service-each-3:hover,
.service-icon-3:before,
.service-icon-3:after,
.service-icon-3 img {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.service-each-3:hover .service-icon-3 img {
  filter: none;
}
.service-each-3:hover .service-text-3,
.service-each-3:hover .service-text-3 h3 a {
  color: #fff;
}
.service-each-3:hover .btn-blue-border {
  color: #fff;
}
.service-each-3:hover .service-icon-3 {
  background-color: #fff;
}
.service-each-3:hover .service-icon-3:before,
.service-each-3:hover .service-icon-3:after {
  opacity: 0.3;
}
/* .................................... 6. Cta .......................................*/
.cta-line-after:before {
  position: absolute;
  content: close-quote;
  width: 2px;
  bottom: 0;
  background-color: #fff;
  right: -117px;
  top: 0;
  opacity: 0.6;
}
.cta.bg-blue.pt-100 {
  padding-bottom: 100px;
}
.cta h1 {
  margin-bottom: 7px;
}
.cta-line-after:after {
  width: 0px;
  position: absolute;
  content: close-quote;
  height: 0px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #fff;
  top: 50%;
  transform: translateY(-50%);
  right: -124px;
  opacity: 0.6;
}
.callback-form .form-control {
  min-width: 340px;
}
.callback-footer-2 {
  max-width: 545px;
  width: 100%;
  text-align: right;
  margin-left: auto;
}
#form-modal .modal-footer {
  justify-content: center;
  border-top: unset;
}

#inputCity {
  width: 70%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

#inputState {
  width: 20%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

#inputZip {
  width: 15%;
  border-radius: 5px;
}

#form-modal textarea.form-control {
  border-radius: 5px;
}
.cta-footer .cta-cl-bg {
  right: 39vw;
  max-width: 230px;
  top: -42px;
}
.cta-footer h2 {
  margin-bottom: 0px;
}
/* .................................... 7. How it works .......................................*/
#workFlow h4 {
  color: #2f846e;
  margin-bottom: 10px;
}
#workBanner .pickUp-btn {
  color: #2f846e;
  border: 1px solid #fff;
  border-radius: 20px;
  text-align: center;
  background: #fff;
  padding: 10px 40px;
  font-size: 14px;
  font-weight: 700;
}
#workBanner .pickUp-btn:hover {
  background: transparent;
  color: #fff;
  transition: 0.4s;
}
#workBanner h1 {
  font-weight: 500;
}
#workFlow .left-bg-position {
  position: relative;
}
#workFlow .arrow-1,
#workFlow .arrow-2 {
  position: absolute;
}
#workFlow .leftflow-img,
#workFlow .rightflow-img {
  position: relative;
}
#workFlow .large_tim {
  position: absolute;
  top: 32%;
  left: 80%;
}
#workFlow .small_tim {
  position: absolute;
  top: 5%;
}
#workFlow .rightflow-img {
  margin-top: -170px;
  position: relative;
}
#workFlow .rightsmall_tim {
  position: absolute;
  left: 76%;
  top: -33%;
}
#workFlow .rightlarge_tim {
  position: absolute;
  top: 5%;
  left: 7%;
}
#workFlow .arrow-2 {
  width: 80%;
}
.each-hw-works {
  padding: 0px 60px;
}
.each-hw-works .hw-text h3 {
  margin-bottom: 5px;
}
.hw-steps {
  display: inline-block;
  color: #fff;
  font-size: 18px;
  width: 38px;
  height: 38px;
  position: relative;
  border-radius: 50%;
  line-height: 38px;
  font-weight: bold;
}
.hw-steps:after {
  position: absolute;
  content: "...................................................................";
  height: 31px;
  width: 360px;
  top: -4px;
  left: 37px;
  font-size: 17px;
  overflow: hidden;
}
.each-hw-works .hw-steps {
  margin-top: 33px;
  margin-bottom: 20px;
}
.hw-steps.no-line:after {
  display: none;
}
/*how it works 2*/
.hw-it-wrks2 {
  position: relative;
}
.hw-it-wrks2:before {
  position: absolute;
  content: close-quote;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url(../img/other/dots2.png);
  background-size: 330px;
  background-repeat: repeat;
}
/*how it works 3*/
.how-work-img {
  position: relative;
  width: 134px;
  height: 134px;
  border-radius: 50%;
  border: 2px solid;
  margin: auto;
  background-color: #fff;
}
.how-work-img .step-count {
  right: -7px;
  display: inline-block;
  color: #fff;
  font-size: 18px;
  width: 38px;
  height: 38px;
  position: absolute;
  border-radius: 50%;
  line-height: 38px;
  font-weight: bold;
}
.how-works-2 {
  padding: 0px 40px;
}
.how-work-img:after {
  position: absolute;
  content: close-quote;
  width: 360px;
  top: 50%;
  font-size: 17px;
  overflow: hidden;
  height: 1px;
  z-index: -1;
}
.how-works-2 .how-work-text h3 {
  margin-bottom: 6px;
  margin-top: 22px;
}
.how-work-img:before {
  width: 0px;
  position: absolute;
  content: close-quote;
  height: 0px;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 17px solid;
  top: 50%;
  right: -9px;
  transform: translateY(-50%);
  left: 192%;
  opacity: 0;
}
.no-step-border .how-work-img:before,
.no-step-border .how-work-img:after {
  display: none;
}
.how-work-img img {
  max-width: 59px;
}
.why-brush {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0.04;
  max-width: 150px;
}
.why-bubble {
  position: absolute;
  bottom: 0;
  max-width: 160px;
  left: 29%;
  opacity: 0.04;
}
.why-choose-3 .check-list2 {
  margin-top: 25px;
  margin-bottom: 29px;
}
.why-choose-3 .check-list2 h5 {
  font-size: 17px;
}
/* .................................... 8. Why us .......................................*/
.play-btn {
  width: 75px;
  height: 75px;
  text-align: center;
  border-radius: 50%;
}
.play-btn i {
  color: #fff;
  line-height: 75px;
}
.why-video {
  border: 7px solid;
  border-left: 0;
}
.check-list2 li {
  position: relative;
  padding-left: 48px;
}
.check-list2 li img {
  position: absolute;
  left: 0;
  top: 6px;
}
.check-list2.half-list li {
  width: 49%;
  display: inline-block;
  vertical-align: top;
}
.why-us-content {
  padding: 95px 60px 100px 60px;
}
.why-us-content .btn {
  margin-top: 0;
}
.check-list2 h5 {
  margin-bottom: 0px;
}
.why-bg-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0.04;
}
.why-us-content .check-list2 h5 {
  font-size: 17px;
}
.why-us-content .check-list2 p {
  line-height: 1.5;
  margin-top: 4px;
}
/*why us 2*/
.why-choose-2 {
  overflow: hidden;
}
.why-us-bg-2 img {
  min-width: 583px;
  position: relative;
  left: -180px;
  top: 13px;
}
.why-bg-icon2 {
  position: absolute;
  left: -90px;
  top: -110px;
  opacity: 0.04;
}
.why-bg-icon2a {
  position: absolute;
  right: 129px;
  bottom: -210px;
  opacity: 0.04;
}
.why-choose-2 .check-list2 h5 {
  font-size: 18px;
  font-weight: 500;
}
.why-choose-2 .check-list2 {
  margin-top: 27px;
  margin-bottom: 16px;
}
/* .................................... 9. Testimonial .......................................*/
#life-easy .each-blog .quote {
  width: 12%;
}
#life-easy .each-blog .icon {
  width: 20%;
}
#life-easy .strive {
  position: absolute;
  top: 30%;
  left: 20%;
  right: 20%;
  bottom: 20%;
}
#life-easy .green-banner {
  position: relative;
  /* background-image: url("../img/bg/cleanitems2.jpg"); */
}
#life-easy .blog-content {
  padding: 30px 0px;
}
#life-easy .blog-content p {
  font-weight: 400;
}
#life-easy .owl-carousel.owl-drag .owl-item {
  padding-top: 9rem;
}
#life-easy .blog-image-infos {
  display: flex;
  height: 100%;
  align-items: center;
}
#partner-slider .blog-image-infos .icon {
  border-radius: 10px;
}
.link-line {
  border-bottom: 1px solid;
  padding-bottom: 6px;
  display: inline-block;
}
.client-image img {
  max-width: 50px;
}
.testimonial-quote {
  padding: 40px 30px;
  padding-top: 40px;
  border-radius: 30px;
  position: relative;
  padding-top: 59px;
  padding-bottom: 32px;
}
.testimonial-each .testimonial-quote h4 {
  margin-bottom: 7px;
  font-size: 22px;
}
.cta-callback h1 {
  margin-bottom: 30px;
}
.each-blog .blog-content h4 {
  margin-bottom: 7px;
}
.each-blog .blog-content h4 a {
  font-weight: bold;
}
.each-blog .blog-content .hr-1 {
  margin-top: 17px;
  margin-bottom: 19px;
}
.testimonial-quote .quote-blue {
  font-size: 50px;
  position: absolute;
  left: 30px;
  top: -22px;
}
.testimonial-quote:after {
  width: 0px;
  height: 0px;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-top: 18px solid #eef4f8;
  position: absolute;
  content: close-quote;
  bottom: -16px;
  left: 42px;
}
/*testimonial type 2*/
.testimonial-2-each {
  padding: 55px 45px;
}
.quote-testimonial-2 {
  position: absolute;
  right: 45px;
  top: -10px;
  font-size: 74px;
  opacity: 0.13;
}
.testimonial-2-each h3 {
  line-height: 1.6;
  margin-bottom: 2px;
  margin-top: 5px;
}
.blog-image-2 img {
  width: 100%;
}
.testimonial-2-slide.owl-carousel .owl-nav {
  position: absolute;
  right: 45px;
  bottom: 58px;
}
.testimonial-2-slide.owl-carousel .owl-nav > div {
  width: 35px;
  height: 35px;
  border: 2px solid;
  border-radius: 50%;
  background: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.testimonial-2-slide.owl-carousel .owl-nav .owl-prev {
  margin-right: 15px;
}
.testimonial-2-slide.owl-carousel .owl-nav > div:hover {
  color: #fff;
}
.split-line {
  --divider-width: 4px;
  --divider-color: #ffffff;
  --divider-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
  --handle-opacity: 1;
  --handle-opacity-active: 1;
  --handle-border-radius: 50%;
  --handle-size: 40px;
  --handle-color: #0c2278;
}
.split-line .handle {
  border-radius: 50%;
}
/*testimonial type 3*/
.testi-by-img {
  max-width: 75px;
  margin: auto;
}
.testimonial-3-tp:before {
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  opacity: 0.92;
  background-color: #fff;
}
.testimonial-3-tp {
  position: relative;
  background-size: 90%;
}
.testimonial-3-slide .owl-nav {
  top: 21px;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
}
.testimonial-3-slide.owl-carousel .owl-nav > div {
  width: 35px;
  height: 35px;
  border: 1px solid;
  border-radius: 50%;
  margin: 0px 133px;
  background: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.testimonial-3-slide.owl-carousel .owl-nav > div:hover {
  color: #fff;
}
.each-quote-3 {
  padding: 0px 35px;
}
.testimonial-3-text .testimonial-3-by {
  margin-top: 22px;
}
/* .................................... 10. Pricing .......................................*/
.price-row-width {
  max-width: 1017px;
  margin: auto;
}
.each-price {
  background-color: #fff;
  padding: 40px 45px;
  border-radius: 6px;
  overflow: hidden;
}
.lined-head span {
  font-size: 15px;
  margin: 0px 13px;
  position: relative;
  top: -5px;
}
.price-features li {
  font-size: 15px;
  font-weight: normal;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.price-features li:last-child {
  border: none;
  padding: 0;
  margin: 0;
}
.main-price {
  font-size: 50px;
  font-weight: bold;
  position: relative;
  display: inline-block;
}
.each-price h4 {
  font-size: 26px;
}
.main-price .sup,
.main-price .sub {
  font-size: 13px;
  font-weight: normal;
}
.main-price .sup {
  position: absolute;
  top: 5px;
  left: -14px;
}
.main-price .sub {
  margin-left: 2px;
}
.lined-head {
  display: inline-block;
  position: relative;
}
.lined-head:before {
  position: absolute;
  content: "........";
  left: -49px;
  font-size: 14px;
  top: 2px;
}
.lined-head:after {
  position: absolute;
  content: "........";
  right: -49px;
  font-size: 14px;
  top: 2px;
}
.each-price.active .price-features li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.22);
}
.each-price.active .lined-head:after,
.each-price.active .lined-head:before {
  color: #fff;
}
.each-price.active {
  transform: scale(1.03);
  z-index: 4;
  box-shadow: 0 12px 12px -6px rgba(41, 137, 216, 0.38);
}
.price-cl-bg {
  position: absolute;
  right: 140px;
  top: 37px;
  z-index: 1;
  opacity: 0.04;
}
.price-cl-bg2 {
  position: absolute;
  left: 200px;
  top: 67px;
  z-index: 1;
  opacity: 0.04;
}
/*price type 2*/
.each-price-2 {
  padding: 35px 27px;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  border-radius: 6px;
  padding-bottom: 42px;
}
.each-price-2:after {
  position: absolute;
  content: close-quote;
  width: 250px;
  height: 250px;
  opacity: 0.15;
  border-radius: 50%;
  right: -100px;
  top: -90px;
}
.price-2-heads img {
  position: absolute;
  right: 27px;
}
.price-2-heads .line {
  display: block;
  width: 33px;
  height: 2px;
  margin-top: 26px;
  margin-bottom: 23px;
}
.price-2-heads p {
  line-height: 1;
}
.prive-2-list li {
  position: relative;
  padding-left: 24px;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 3px;
}
.prive-2-list li i {
  position: absolute;
  left: 0;
  top: 8px;
  font-size: 12px;
  color: #3dba69;
}
.prive-2-list li span {
  font-weight: bold;
}
.price-row-width2 {
  max-width: 916px;
  margin: auto;
}
.each-price-2 .btn-block {
  padding: 13px 25px;
  font-size: 14px;
}
.each-price-2.active {
  color: #fff;
}
.each-price-2.active .line {
  background-color: #fff;
}
.each-price-2.active .prive-2-list li i {
  color: #fff;
}
.each-price-2.active:after {
  background-color: #fff;
  z-index: 10;
}
.each-price-2.active .price-2-heads img {
  right: 0;
}
/* .................................... 11. Blog .......................................*/
.blog-imgs img {
  width: 100%;
}
.blog-imgs {
  position: relative;
}
.date-tag {
  color: #fff;
  padding: 4px 15px;
  border-radius: 50px;
}
.blog-image-info {
  position: absolute;
  left: 25px;
  bottom: -8px;
}
.blog-content {
  padding: 30px 25px;
  padding-bottom: 30px;
}
.read-blog {
  display: block;
  margin-top: 16px;
}
.read-blog i {
  float: right;
  margin-top: 4px;
}
.blog-imgs:after {
  position: absolute;
  content: close-quote;
  left: 0;
  top: 30%;
  opacity: 0.5;
  bottom: 0;
  right: 0;
  background: rgb(19, 27, 35);
  background: -moz-linear-gradient(
    0deg,
    rgba(19, 27, 35, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(19, 27, 35, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(19, 27, 35, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#131b23", endColorstr="#ffffff", GradientType=1);
}
.blog-nav-icon,
.service-nav-icon,
.team-nav-icon,
.prdt-nav-left,
.prdt-nav-right {
  width: 38px;
  height: 38px;
  display: inline-block;
  text-align: center;
  line-height: 38px;
  border-radius: 50px;
  border: 1px solid;
}
.blog-nav-icon:hover,
.service-nav-icon:hover,
.team-nav-icon:hover {
  color: #fff !important;
  background: #2f846e !important;
}

.blog-nav-icon .fa-chevron-right,
.blog-nav-icon .fa-chevron-left {
  color: #2f846e;
}

.each-blog:hover .read-blog {
  letter-spacing: 1px;
}
.each-blog:hover {
  box-shadow: none;
}
/*blog type 2*/
.blog-image-2 {
  position: relative;
}
.blog-date-bg {
  position: absolute;
  top: 24px;
  left: 21px;
}
.blog-date-bg .date {
  color: #fff;
  position: absolute;
  top: 49px;
}
.blog-date-bg .date h3 {
  line-height: 0.9;
}
.blog-image-2:before {
  position: absolute;
  content: close-quote;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.reabmore-blog-2 {
  color: #fff;
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
  opacity: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.reabmore-blog-2:hover {
  color: #fff;
}
.each-blog-2:hover .blog-image-2:before {
  opacity: 0.5;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.each-blog-2:hover .reabmore-blog-2 {
  opacity: 1;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.blog-text-2 {
  padding: 16px 25px;
  background-color: #fff;
}
.each-blog-2 .blog-text-2 h3 {
  line-height: 1;
  margin-bottom: 0px;
}
.each-blog-2 .blog-text-2 h3 a {
  font-size: 19px;
  font-weight: 500;
}
.blog-info-list {
  position: relative;
  padding: 12px 25px;
  background-color: rgba(255, 255, 255, 0.6);
}
.blog-info-list .list-inline-item:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  padding-right: 12px;
}
.blog-info-list:after {
  position: absolute;
  content: close-quote;
  width: 0%;
  height: 2px;
  right: 0;
  bottom: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  left: 0;
}
.each-blog-2:hover .blog-info-list:after {
  width: 100%;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.blog-info-list .list-inline-item {
  line-height: 0;
  font-weight: normal;
}
.sub-head {
  text-transform: uppercase;
}
.each-blog-2:hover {
  box-shadow: 0 13px 14px -10px rgba(0, 0, 0, 0.1);
}
.blog-bg-icon2 {
  position: absolute;
  right: 31%;
  top: -30px;
  max-width: 200px;
  opacity: 0.04;
}
section.blog-2 {
  overflow: hidden;
}
/* .................................... 12. Cta .......................................*/
.cta-cl-bg {
  position: absolute;
  opacity: 0.05;
  right: 28vw;
  top: -2px;
  z-index: 1;
}
.cta-footer {
  overflow: hidden;
}
/* .................................... 13. Footer .......................................*/
footer {
  background: #353535;
}
footer .app-img {
  width: 12%;
  padding-right: 10px;
}
footer p {
  font-weight: 400;
}
footer a.privacy,
footer a.terms {
  text-decoration: underline;
  font-weight: normal;
}
.contact-footer ul li {
  position: relative;
  color: #fff;
  /* padding-left: 38px; */
  font-family: "Inter", sans-serif;
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  gap: 8px;
}
.contact-footer ul li a {
  font-family: "Inter", sans-serif;
  color: #353535;
}
.contact-links li {
  display: flex;
  /* align-items: center; */
}
.contact-links li a {
  color: #353535;
  font-size: 18px;
  /* font-weight: 300; */
  display: inline-block;
}
.contact-links li span {
  color: #353535;
  font-size: 18px;
  /* font-weight: 300; */
  display: inline-block;
}
.contact-links li img {
  width: 20px;
  height: 20px;
}
/* .contact-footer ul li span {
  display: flex;
  color: #353535;
  font-family: "Inter" sans-serif;
} */
.contact-links li svg {
  font-size: 24px;
  width: 24px;
  height: 24px;
  font-family: "Inter", sans-serif;
  color: #353535;
}
footer .social-icons .fab {
  border: 1.5px solid #ececec;
  padding: 10px 15px;
  border-radius: 50%;
}

footer .social-icons {
  /* padding-left: 15px; */
}

footer .icon-div {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
/* footer .social-icons li {
  margin-right: 10px;
} */

.contact-footer ul li i {
  position: absolute;
  left: 0;
  top: 5px;
  color: #353535;
}

.footer-1 {
  padding-bottom: 40px;
  background: #6ed1ba;
}
.footer-1::after {
  content: "";
  position: absolute;
  top: -190px;
  left: -120px;
  rotate: -0deg;
  width: 400px;
  height: 380px;
  border: 60px solid #ffffff1a;
  border-radius: 50%;
  background: transparent;
  z-index: 1;
}
.footerLogo {
  /* padding-left: 25px; */
  /* height: 100px;
  width: 100px; */
  /* margin-bottom: 1rem; */
}
.footer-lines {
  width: 43px;
  /* height: 4px; */
  display: inline-block;
}
footer .footer-content {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}
.footer-1 p {
  display: flex;
  color: #000;
}
.footer-1 svg {
  margin-right: 22px;
  font-size: 2rem;
}
.footer-1 p {
  color: #353535;
}
.footer-1 h3 {
  color: #353535;
  font-size: 24px;
  font-family: "Inter", sans-serif;
}
.footer-services {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer-links li a {
  font-size: 18px;
  font-family: "Inter", sans-serif;
}
.footer-links li a:hover i {
  margin-right: 15px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.footer-links li a:hover {
  color: #353535 !important;
}
.footer-services-mob {
  display: none;
}
.footer-services-mob .footer-services-company {
  display: flex;
  /* justify-content: space-between; */
  gap: 20px;
}
.footer-services-mob .footer-work-contact {
  display: flex;
  gap: 60px;
  /* margin-bottom: 1rem; */
  /* justify-content: space-between; */
}
.nice-select .option {
  line-height: 30px;
  min-height: 30px;
}
.footer-links li a {
  color: #353535;
  font-size: 18px;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 10px;
  text-decoration: underline;
}
.footer-links li a i {
  margin-right: 10px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.footer-buttons .btn-white-border {
  border: 1px solid #b3bec5;
  color: #b3bec5;
}
.social-icons.footer a {
  color: #b3bec5;
}
.scroll-btn {
  width: 35px;
  height: 35px;
  position: fixed;
  right: 43px;
  bottom: 37px;
  border-radius: 50%;
  color: #fff;
  line-height: 36px;
  font-size: 12px;
  z-index: 50;
}
.scroll-btn:hover {
  color: #fff;
}
/* .................................... 14. Team .......................................*/
.team-image-part {
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border: 7px solid;
}
.team-image-part img {
  width: 100%;
}
.plus-btn {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 26px;
  text-align: center;
  top: 10px;
  border-radius: 50%;
  color: #fff;
  line-height: 50px;
  font-size: 29px;
  z-index: 5;
}
.plus-btn:hover {
  color: #fff;
}
.each-teams {
  position: relative;
  max-width: 235px;
  margin: auto;
  margin-bottom: 75px;
}
.team.all-team .each-teams {
  margin-bottom: 60px;
}
.meanmenu-reveal.meanclose + .mean-nav {
  margin-bottom: 12px;
  margin-top: 13px;
  background: #212529;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.head-type-1 .meanmenu-reveal.meanclose + .mean-nav {
  margin-bottom: 0px;
}
.head-type-2 .meanmenu-reveal.meanclose + .mean-nav {
  margin-bottom: 0px;
}
.each-teams .plus-btn {
  right: 8px;
}
.owl-carousel .each-teams {
  margin-bottom: 0px;
}
.each-teams .team-text h4 {
  margin-bottom: 5px;
  font-size: 24px;
}
.team-image-part .social-icons {
  width: 100%;
  text-align: center;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: 0.3s;
}
.team-image-part .social-icons li a {
  color: #fff;
}
.team-image-part .social-icons li a:hover i {
  -webkit-transform: scale(1.06);
  transform: scale(1.06);
}
.team-image-part:before {
  position: absolute;
  content: close-quote;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: 0.3s;
}
.each-teams:hover .team-image-part:before {
  opacity: 0.8;
  -webkit-transition: all 0.3s;
  transition: 0.3s;
}
.each-teams:hover .team-image-part .social-icons {
  opacity: 1;
  -webkit-transition: all 0.3s;
  transition: 0.3s;
}
.each-teams:hover .plus-btn {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.each-teams:hover .team-image-part {
  box-shadow: 0 5px 10px 2px rgba(19, 27, 35, 0.1);
}
.team-bg-icon2 {
  position: absolute;
  top: -36px;
  left: 53%;
  max-width: 200px;
  opacity: 0.04;
}
/*team type 2*/
.each-teams-2 {
  padding-top: 30px;
}
.each-teams-2 .team-text h4 {
  margin-top: 30px;
  margin-bottom: 5px;
  font-size: 24px;
}
.each-teams-2 .team-text p {
  margin-top: 5px;
}
.te-img-outer {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  max-width: 198px;
  margin: auto;
}
.te-img-outer .plus-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.team-img-2-outer .bg-team-img {
  position: absolute;
  top: -25px;
  z-index: -1;
  opacity: 0.15;
  max-width: 90%;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
}
.te-img-outer:after {
  position: absolute;
  content: close-quote;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.each-teams-2:hover .te-img-outer .plus-btn {
  opacity: 1;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.each-teams-2:hover .te-img-outer:after {
  opacity: 0.9;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.cta-video[data-overlay]::before {
  opacity: 0.94;
}
.play-btn-big {
  width: 84px;
  text-align: center;
  height: 84px;
  position: relative;
  display: inline-block;
  border-radius: 50%;
}
.play-btn-big i {
  color: #fff;
  font-size: 20px;
  line-height: 84px;
}
.play-btn-big:after,
.play-btn-big:before {
  position: absolute;
  content: close-quote;
  width: 130px;
  height: 130px;
  border: 2px dashed rgba(255, 255, 255, 0.35);
  border-radius: 50%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.play-btn-big:before {
  width: 175px;
  height: 175px;
  border: 2px dashed rgba(255, 255, 255, 0.2);
}
/* .................................... 15. Video area .......................................*/
.video-2 .play-btn {
  margin: auto;
  margin-bottom: 20px;
}
.video-2 h1 {
  font-size: 70px;
  margin-bottom: 7px;
}
.video-p {
  max-width: 600px;
  margin: auto;
  margin-bottom: 25px;
}
.video-im1 {
  position: absolute;
  left: -198px;
  top: -29px;
  z-index: 5;
}
.video-im2 {
  position: absolute;
  right: -120px;
  bottom: -72px;
  z-index: 5;
}
/* .................................... 16. Clents logo .......................................*/
.each-client-img img {
  display: block;
  width: 100%;
  opacity: 0.5;
}
.each-client-img:hover img {
  opacity: 1;
}
.client-bg-icon2 {
  position: absolute;
  right: 38%;
  top: 2%;
  max-width: 230px;
  opacity: 0.04;
}
.each-count-f {
  position: relative;
}
.each-count-f:after {
  position: absolute;
  content: close-quote;
  width: 1px;
  top: 0;
  bottom: 0;
  background-color: #fff;
  right: 0;
  opacity: 0.25;
}
.each-count-f.no-border:after {
  opacity: 0;
  display: none;
}
/* .................................... 17. Faq area .......................................*/
#HelpCenterFAQ1 .card-header,
#HelpCenterFAQ2 .card-header {
  margin-bottom: 0px;
  border-radius: 0px;
  border-top: unset !important;
  border-left: unset !important;
  border-right: unset !important;
  border-bottom: 1px solid #000 !important;
  background: #fff;
}
#HelpCenterFAQ1 .faq-boxes .card-body,
#HelpCenterFAQ2 .faq-boxes .card-body {
  background: #fff;
  padding: 20px;
}
#HelpCenterFAQ1 .faq-boxes .card-header .btn,
#HelpCenterFAQ2 .faq-boxes .card-header .btn {
  font-size: 17px;
  font-weight: 700;
  color: #000;
}
/* #HelpCenterFAQ1 .faq-boxes .card-header .btn:before {
  content: "\f067";
} */
#HelpCenterFAQ1 .faq-boxes .card-header .btn:after,
#HelpCenterFAQ2 .faq-boxes .card-header .btn:after {
  content: "\f067";
}
.faq-boxes .card-header {
  background: #fff;
  border: 1px solid #dbd5d5 !important;
  border: 2px solid;
  border-radius: 7px;
  margin-bottom: 15px;
  box-shadow: none;
  position: relative;
  padding: 17px 16px;
  cursor: pointer;
  width: 100%;
  flex-basis: 100%;
  order: 1;
}
.faq-boxes .collapseparent {
  order: 2;
}
.faq-boxes .accordion > .card > .card-header {
  margin-bottom: 0px;
  border-radius: 7px;
}
.faq-boxes .card {
  border: none;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.faq-boxes .card-header .btn {
  font-size: 16px;
  font-weight: normal;
  font-family: "Roboto", sans-serif;
  line-height: 1.3;
  display: block;
  width: 100%;
  padding: 0;
  text-align: left;
  color: #575a5a;
  border: none;
}
/* #homeFAQ .collapse.show .card {
  border: 2px solid #00554a;
} */
.faq-boxes .card-header:hover {
  /* border: 2px solid #00554a; */
  /* border: unset;
  color: #00554a;
  background: #effffb;
  transition: 0.4s; */
}
.faq-boxes .card-header .btn:after {
  content: "\f067";
  position: absolute;
  right: 15px;
  color: #333333;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  transform: rotate(45deg);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.faq-boxes .card-header .btn:hover,
.faq-boxes .card-header .btn:focus {
  box-shadow: none;
  text-decoration: none;
}
.faq-boxes .card-header h5 {
  font-size: 16px;
  font-weight: normal;
}
.faq-boxes .card-body {
  padding: 21px;
  padding-top: 0px;
  line-height: 26px;
  background: #f9fafc;
}
.faq-boxes .card-header:active {
  /* background: none; */
}
.each-count-f p {
  margin-bottom: 5px;
}
.each-count-f h1 {
  margin-bottom: 5px;
  font-weight: bold;
}
.faq-boxes .card-header h5 i {
  position: absolute;
  right: 15px;
}
.faq-boxes .collapseparent + .card-header .btn:after {
  transform: rotate(0deg);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.faq-boxes .collapseparent.show + .card-header .btn:after {
  transform: rotate(45deg);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.faq-boxes.white-faq .card-header {
  background-color: #fff;
}
/* .................................... 18. Portfolio .......................................*/
.each-portfolio {
  position: relative;
}
.each-portfolio .portfolio-text h4 {
  margin-bottom: 4px;
  font-size: 25px;
}
.each-portfolio .portfolio-text p {
  margin-bottom: 12px;
}
.portfolio-text {
  text-align: center;
  width: 90%;
  color: #fff;
  opacity: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.btn.btn-md {
  padding: 8px 7px;
  min-width: 130px;
  font-size: 14px;
  font-weight: normal;
}
.each-portfolio:before {
  position: absolute;
  content: close-quote;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.each-portfolio:hover:before {
  opacity: 0.9;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.each-portfolio:hover .portfolio-text {
  opacity: 1;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.each-portfolio:after {
  position: absolute;
  content: close-quote;
  left: 10px;
  right: 10px;
  bottom: 10px;
  top: 10px;
  border: 1px solid #fff;
  opacity: 0;
  border-radius: 8px;
}
.each-portfolio:hover:after {
  opacity: 0.3;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.porfolio-slide.owl-carousel .owl-nav {
  position: absolute;
  top: 43%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
}
.porfolio-slide.owl-carousel .owl-nav > div {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
}
.porfolio-slide.owl-carousel .owl-nav .owl-prev {
  position: absolute;
  width: 40px;
  height: 54px;
  color: #fff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  left: 0px;
  text-align: center;
}
.porfolio-slide.owl-carousel .owl-nav .owl-next {
  position: absolute;
  width: 40px;
  height: 54px;
  color: #fff;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  right: 0px;
  text-align: center;
}
.each-feature {
  margin-bottom: 40px;
}
.features .each-feature h3 {
  margin-bottom: 2px;
  margin-top: 20px;
  font-size: 21px;
}
/* .................................... 19. About Us .......................................*/
.inner-banner {
  min-height: 350px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background: #6fd1ba;
}
.inner {
  min-height: 150px;
  background-repeat: no-repeat;

  background-size: cover;
  background-position: center center;
  background: white;
  color: black;
}
.inner-div {
  margin-top: 8%;
}
.inner-div h1 {
  font-size: 1.5rem;
}
.head-type-2.head-type-3.inner-header {
  border-bottom: 2px solid rgba(0, 0, 0, 0.07);
  padding: 0px 0px;
  /* background: rgba(255, 255, 255, 0.44); */
  background: #a2e9d9cf;
  backdrop-filter: blur(20px);
}
.head-type-2.head-type-3.inner-header.fixed-nav {
  padding: 12px 0px;
  border: none;
  z-index: 99;
}
.inner-bnr-nav li {
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
}
.inner-bnr-nav li:last-child {
  margin: 0;
  padding: 0;
}
.inner-bnr-nav li:after {
  position: absolute;
  content: "/";
  margin-left: 9px;
  top: 4%;
  right: -5px;
}
.inner-bnr-nav li:last-child:after {
  display: none;
}
.mission-bg {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  opacity: 0.04;
  max-width: 150px;
}
.mission-bg-2 {
  position: absolute;
  left: -40px;
  bottom: 0;
  z-index: -1;
  max-width: 150px;
  opacity: 0.04;
}
.each-feature h3 {
  line-height: 1.4;
  margin-bottom: 2px;
}
/* .................................... 20. Blog Standared .......................................*/
.blog-standared-img {
  position: relative;
}
.blog-standared-img img {
  width: 100%;
}
.blog-standared-date {
  position: absolute;
  left: 30px;
  top: 30px;
  padding: 10px 19px;
  padding-top: 17px;
  border-radius: 8px;
  z-index: 5;
}
.blog-standared-content {
  padding: 16px 28px;
  border: 3px solid;
}
.blog-standared-content .blog-std-texts h2 {
  margin-bottom: 5px;
}
.tag-blog {
  padding: 5px 15px;
  border-radius: 50px;
  display: inline-block;
}
.blog-std-head ul li {
  position: relative;
}
.blog-std-head ul li:after {
  position: absolute;
  content: "|";
  right: 0;
  opacity: 0.4;
}
.blog-std-head ul li:last-child:after {
  display: none;
}
.blog-std-head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 13px;
  margin-bottom: 18px;
}
.slider-type-blog.owl-carousel .owl-nav .owl-next,
.slider-type-blog.owl-carousel .owl-nav .owl-prev {
  height: 50px;
  width: 50px;
  color: #fff;
  border-radius: 50%;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider-type-blog .owl-prev,
.slider-type-blog .owl-next {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.slider-type-blog .owl-prev {
  left: 30px;
}
.slider-type-blog .owl-next {
  right: 30px;
}
.right-box-head {
  padding: 20px 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.right-box-head h4 {
  font-weight: bold;
  display: inline-block;
  position: relative;
}
.right-box-content {
  padding: 30px 30px;
}
.right-box-head h4:after {
  position: absolute;
  content: close-quote;
  left: 0;
  right: 0;
  height: 2px;
  bottom: -23px;
}
.search-white {
  height: 60px;
}
.search-white + i {
  position: absolute;
  right: 25px;
  opacity: 1;
}
.popular-post {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.popular-post-img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 82px;
  flex: 0 0 82px;
  max-width: 82px;
  position: relative;
}
.popular-post-img .full-cover {
  color: #fff;
  opacity: 0;
}
.popular-post-text p {
  line-height: 1.4;
  font-weight: 400;
  margin-bottom: 0;
}
.popular-post-text span {
  font-weight: 500;
  font-size: 12px;
}
.popular-post:last-child {
  padding: 0;
  margin: 0;
  border: none;
}
a.popular-post:hover .full-cover {
  opacity: 1;
}
.right-box {
  border-radius: 8px;
}
.categories li {
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.categories li a {
  font-size: 14px;
  font-weight: normal;
  display: block;
}
.categories li a span {
  float: right;
  display: block;
  font-size: 11px;
  width: 24px;
  height: 24px;
  text-align: center;
  font-weight: 600;
  color: #fff;
  margin-top: 1px;
  border-radius: 50%;
}
.categories li:last-child {
  padding: 0;
  margin: 0;
  border: none;
}
.tags-widget .tag-link {
  background-color: #fff;
  display: inline-block;
  padding: 5px 24px;
  font-size: 13px;
  font-weight: 400;
  border-radius: 70px;
  margin-bottom: 10px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
}
.tags-widget .tag-link:hover {
  color: #fff;
}
.social-profile-box {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 39px;
  text-align: center;
  border: 1px solid;
  font-size: 16px;
  margin-right: 8px;
  border-radius: 50%;
}
.social-profile-box:hover {
  color: #fff;
}
.pagination-type1 ul li {
  display: inline-block;
  margin-right: 15px;
}
.pagination-type1 ul li a {
  width: 42px;
  height: 42px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 42px;
}
.pagination-type1 ul li a:hover,
.pagination-type1 ul li.active a {
  color: #fff;
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.18);
}
.blog-grid-2 .blog-info-list {
  background-color: rgba(238, 244, 248, 0.5);
}
.pagination-type1 ul li:last-child {
  margin: 0px;
}
/* .................................... 21. Blog Details .......................................*/
.block-quote-2 {
  padding: 35px;
  padding-left: 125px;
  position: relative;
}
.block-quote-2:before {
  position: absolute;
  content: close-quote;
  left: 15px;
  top: 15px;
  right: 15px;
  bottom: 15px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.block-quote-by {
  display: inline-block;
  font-weight: bold;
  padding-left: 35px;
  position: relative;
}
.block-quote-by:before {
  position: absolute;
  content: close-quote;
  left: 0;
  height: 1px;
  background-color: #fff;
  width: 22px;
  top: 50%;
}
.block-quote-2 i.fas {
  font-size: 45px;
  position: absolute;
  left: 45px;
  top: 43px;
}
.blog-de-footer {
  border: 1px solid rgba(19, 27, 35, 0.1);
  border-left: none;
  border-right: none;
}
.tags-small a {
  display: inline-block;
  padding: 7px 18px;
  font-size: 13px;
  font-weight: 400;
  border-radius: 70px;
}
.tags-small a:hover {
  color: #fff;
}
.blog-social li a {
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  line-height: 30px;
  font-size: 13px;
  color: #131b23;
}
.blog-social li {
  margin-right: 6px;
}
.blog-social li a:hover {
  color: #fff;
}
.ayther-img {
  min-width: 140px;
  margin-right: 20px;
}
.blog-auther-social li a {
  opacity: 0.5;
}
.blog-auther-social li a:hover {
  opacity: 1;
}
.blog-next-prev {
  border: 1px solid rgba(19, 27, 35, 0.1);
  border-left: none;
  border-right: none;
}
/*comment*/
.comment-text .top-head h6 {
  display: inline-block;
}
.connent-lists > li {
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid rgba(19, 27, 35, 0.1);
}
.comment-image {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 77px;
  flex: 0 0 77px;
  max-width: 77px;
  margin-right: 30px;
}
.comment-date {
  font-size: 13px;
  text-transform: uppercase;
  display: inline-block;
  margin-left: 15px;
}
.name-replay .reply {
  float: right;
  font-size: 14px;
}
.name-replay .list-inline {
  display: inline-block;
  font-size: 14px;
}
.replay-comment {
  padding-left: 75px;
}
.green-border {
  border: 1px solid;
}
textarea.input-white {
  height: auto;
  padding-top: 20px;
}
.light-border {
  border: 2px solid;
}
.related-blogs .blog-info-list {
  background-color: rgba(238, 244, 248, 0.5);
}
/* .................................... 22. Contact us .......................................*/
.contacts-list li {
  position: relative;
  padding-left: 60px;
}
.contacts-list li .icon {
  position: absolute;
  left: 0;
  width: 45px;
  height: 45px;
  display: inline-block;
  text-align: center;
  color: #fff;
  line-height: 46px;
  border-radius: 50%;
  top: 7px;
}
.contacts-list li .sub-head {
  text-transform: inherit;
  font-size: 14px;
  font-weight: normal;
  margin-top: 1px;
  line-height: 1;
  display: inline-block;
  margin-bottom: 0px;
}
.contacts-list li p {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}
.contact-form {
  padding: 33px;
  border-radius: 8px;
  padding-top: 27px;
}
.contact-form .form-group {
  margin-bottom: 25px;
}
.contact-form .input-white {
  padding: 6px 20px;
}
.contact-form .nice-select:after {
  height: 8px;
  width: 8px;
  right: 25px;
  top: 24px;
}
.contact-form textarea.input-white {
  padding-top: 16px;
}
.contact-form .form-group i {
  right: 20px;
  font-size: 14px;
  position: absolute;
  top: 25px;
}
.contact-form .list {
  width: 100%;
}
.contact-map iframe {
  width: 100%;
  border: none;
}
/* .................................... 23. Portfolio .......................................*/
.portfolio-item-img {
  position: relative;
}
.portfolio-overlay {
  position: absolute;
  top: 8px;
  right: 8px;
  bottom: 8px;
  left: 8px;
  opacity: 0;
}
.portfolio-overlay {
  position: absolute;
  top: 8px;
  right: 8px;
  bottom: 8px;
  z-index: 5;
  left: 8px;
  padding: 30px;
  color: #fff;
}
.portfolio-overlay:before {
  position: absolute;
  content: close-quote;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.9;
  z-index: 1;
}
.portfolio-overlay .date {
  position: absolute;
  top: 25px;
  left: 30px;
}
.portfolio-item-detail {
  position: absolute;
  bottom: 25px;
  left: 30px;
  right: 30px;
}
.plus-line-link {
  position: absolute;
  right: 0;
  width: 36px;
  height: 36px;
  bottom: 0;
  margin-top: -4px;
}
.plus-line-link:before {
  position: absolute;
  content: close-quote;
  height: 36px;
  width: 1px;
  background-color: #fff;
  left: 50%;
  top: 0;
}
.plus-line-link:after {
  position: absolute;
  content: close-quote;
  height: 1px;
  width: 36px;
  background-color: #fff;
  left: 0;
  top: 50%;
}
.portfolio-item-detail .filtered-name {
  position: relative;
}
.filter-gallery button:last-child {
  margin-right: 0px;
}
.portfolio-item {
  margin: 0px 15px;
  margin-bottom: 30px;
}
.portfolio-item.mar-0 {
  margin: 0px 0px;
  margin-bottom: 30px;
}
.portfolio-filter {
  margin-left: -15px;
  margin-right: -15px;
}
.portfolio-item:hover .portfolio-overlay {
  opacity: 1;
}
.portfolio-item-img img {
  width: 100%;
  height: auto;
}
.grid-item,
.grid-sizer {
  width: 33.33%;
}
.grid-item {
  float: left;
  height: auto;
}
.filter-gallery > button {
  background: none;
  border: none;
  font-size: 13px;
  font-weight: 500;
  padding: 6px 13px;
  margin-right: 10px;
  border-radius: 8px;
  border: 2px solid #fff;
  text-transform: uppercase;
}
.filter-gallery {
  text-align: center;
}
.filter-gallery > button.active,
.filter-gallery > button:hover {
  border: 2px solid;
}
.filter-gallery > button:last-child {
  margin: 0;
  margin: 0px 15px;
  margin-bottom: 12px;
}
.portfolio-item-detail h5 a:hover {
  color: #fff;
}
/* .................................... 24. Service .......................................*/
#LuxuryServices .service-each .service-text h3 {
  color: #2f846e;
  font-size: 1.7rem;
  line-height: 1.5;
}
#LuxuryServices .service-each .service-text p {
  font-size: 14px;
  line-height: 1.8;
}
#LuxuryServices .service-text {
  padding: 1rem 3rem;
}
.quote-service {
  padding: 80px 70px;
  padding-bottom: 85px;
}
.quote-service .btn.btn-black {
  font-size: 15px;
}
.quote-service .input-white {
  padding: 7px 15px;
}
/* .................................... 25. Service details .......................................*/
.servvice-link-list li a {
  display: block;
  border-bottom: 1px solid #fff;
  padding: 12px 25px;
  font-size: 16px;
  font-weight: normal;
  position: relative;
}
.servvice-link-list li a i {
  margin-right: 10px;
  font-size: 13px;
}
.servvice-link-list li a:after {
  width: 0px;
  position: absolute;
  content: close-quote;
  height: 0px;
  border-top: 27px solid transparent;
  border-bottom: 27px solid transparent;
  border-left: 17px solid #ffffff;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  right: -16px;
}
.servvice-link-list li a:hover,
.servvice-link-list li.active a {
  color: #fff;
}
.servvice-link-list li a:hover:after,
.servvice-link-list li a:after,
.servvice-link-list li.active a:after {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  opacity: 1;
}
.blue-contacts {
  padding: 40px 30px;
  padding-bottom: 40px;
  color: #ffff;
  padding-bottom: 35px;
}
.blue-contacts .icon {
  background-color: #fff;
}
.blue-contacts li:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 16px;
}
.blue-contacts li p {
  font-size: 20px;
}
.check-list.type-2-c li i {
  position: absolute;
  left: 0;
  top: 4px;
}
.check-list.type-2-c li {
  padding-left: 28px;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 8px;
  padding-right: 10px;
}
/* .................................... 26. Error .......................................*/
.error-bg-icon {
  position: absolute;
  left: -13vw;
  top: -22vw;
  opacity: 0.03;
  z-index: -1;
}
section.error:after {
  position: absolute;
  content: close-quote;
  width: 54vw;
  height: 54vw;
  right: -19vw;
  top: 0;
  border-radius: 50%;
  z-index: -1;
  opacity: 0.5;
}
.error-texts h1 {
  font-size: 133px;
  font-weight: 900;
}
/* .................................... 27. Login Signup .......................................*/
.login .form-area {
  padding: 80px 70px 60px 70px;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.label-check {
  position: relative;
  top: 1px;
}
.login .input-white {
  border-radius: 23px;
  border: 0.5px solid #000;
}
.login .shine-btn {
  border-radius: 23px;
  background: #6fd1ba;
}
.login .guest-btn {
  background: rgba(100, 100, 100, 0.6) !important;
  border-radius: 23px;
  color: #fff !important;
}

.login .btn-black:hover {
  background: #fff;
  border-radius: 23px;
  transition: 0.4s;
  color: #6fd1ba;
  border: 1px solid #6fd1ba;
}
/* .................................... 28. Coming soon .......................................*/
.coming-soon {
  min-height: 500px;
  height: 100vh;
}
.week-count h1 {
  font-size: 100px;
  font-weight: bold;
  position: relative;
  display: inline-block;
  line-height: 1;
  margin-bottom: 0px;
}
.clock {
  border-right: 2px solid rgba(255, 255, 255, 0.1);
}
.clock sup {
  font-size: 15px;
  font-weight: 500;
  font-style: italic;
}
.week-count h1 sup {
  position: absolute;
  right: -55px;
  top: 9px;
  font-family: "Montserrat", sans-serif;
}
.hour-week h1 {
  font-weight: bold;
  position: relative;
  font-size: 32px;
  margin-right: 55px;
  font-family: "Montserrat", sans-serif;
  line-height: 67px;
}
.hour-week h1 sup {
  position: static;
  vertical-align: top;
  display: flex;
}
.input-white + .input-group-append .btn {
  border: none;
  border-radius: 0px;
  color: #fff;
  width: 55px;
}
.coming-soon .input-group-append {
  border-radius: 8px;
  overflow: hidden;
}
/* .................................... 29. Partners .......................................*/

#BusinessBox {
  background: #dcf7f0;
}
#BusinessBox .white-bg {
  background: #fff;
  padding: 15px;
  border-radius: 10px;
}
#BusinessBox .blog-image {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
#partner-slider {
  background: #dcf7f0;
}
.partnerBanner {
  background-image: url("../img/partnerwithus-banner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 40px;
  display: flex;
  justify-content: center;
}

.partnerBanner .partnerRow {
  display: flex;
  gap: 30px;
}
.partnerBanner .partnerRow .partnerImage img {
  width: 500px;
  height: 250px;
}

.partnerBanner h3 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 32px;
  color: #353535;
  line-height: 38.73px;
}
.partnerBanner p {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}
.partnerForm {
  margin-top: 20px;
}
.partnerForm .successMessage {
  color: green;
  font-size: 12px;
  /* text-align: center; */
}
.partnerForm .errorMessage {
  color: red;
  font-size: 12px;
  /* text-align: center; */
}
.contactForm {
  display: flex;
  gap: 20px;
  align-items: center;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.inputGroup label {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.emailInput {
  width: 100%;
  padding: 10px 25px;
  border-radius: 30px;
  border: 1px solid #e5e5e5;
  background-color: #f9f9f9;
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}

.phoneNumberInput {
  width: 100%;

  border-radius: 30px;
  border: 1px solid #e5e5e5;
  background-color: #f9f9f9;
  font-size: 14px;
}

.phoneInputGroup .phoneInputWrapper {
  display: flex;
  align-items: center;
  border: 1px solid #e5e5e5;
  border-radius: 30px;
  background-color: #f9f9f9;
}

.dropdownWrapper {
  position: relative;
  padding: 0 10px;
}

.countryCode {
  border: none;
  background-color: transparent;
  /* padding: 10px 15px; */
  border-radius: 30px 0 0 30px;
  font-size: 14px;
  appearance: none;
  -webkit-appearance: none;
}

.downArrow {
  position: absolute;
  top: 10%;

  right: 5px;
  pointer-events: none;

  font-size: 20px;
  color: #353535;
}

.divider {
  height: 30px;
  width: 1px;
  background-color: #e5e5e5;
  margin: 0 2px;
}

.phoneNumberInput {
  border: none;
  border-radius: 0 30px 30px 0;
  padding: 10px;
  font-size: 14px;
  flex-grow: 1;
}

.submitButton {
  background-color: #6ed1ba;
  color: white;
  margin-top: 1.8rem;
  border-radius: 40px;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  border: 2px solid #5dc4ac;
  padding: 8px 20px;
}
.submitButton button {
  font-size: 16px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}
.submitButton:hover {
  background-color: #45b492;
}

.intro-brand-container {
  width: 80%;
  margin: 30px auto;
}

.intro-brand-container h4 {
  font-family: "Inter", sans-serif;
  font-size: 42px;
  color: #353535;
  font-weight: 600;
  position: relative;
}

.intro-brand-container h4::after {
  content: "";
  position: absolute;
  top: 80%;
  margin-left: 20px;
  transform: translateY(-50%);
  width: 60%;
  height: 4px;
  background: linear-gradient(to right, #b2cade 0%, rgba(89, 194, 254, 0) 100%);
}
.brandContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.brandList {
  display: flex;
  /* border: 1px solid black; */
  gap: 10px;
  padding: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  white-space: nowrap;
  -ms-overflow-style: none;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  width: 80%;
}
.brandList::-webkit-scrollbar {
  display: none;
}
.brandList .brand {
  animation: scroll 20s linear infinite;
  flex: 0 0 auto;
  width: 200px;
  height: 100px;
  border: none;
  border-radius: 16px;
  background: #f3f7ff;
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
  transition: transform 0.3s;
}

.brandList .brand img {
  max-width: 100%;
  max-height: 100%;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      calc(-80px * 6)
    ); /* Adjust based on number of brands and their width */
  }
}
.intro-indus-container {
  width: 80%;
  margin: 50px auto 30px auto;
}

.intro-indus-container h4 {
  font-family: "Inter", sans-serif;
  font-size: 42px;
  color: #353535;
  font-weight: 600;
  position: relative;
}

.intro-indus-container h4::after {
  content: "";
  position: absolute;
  top: 80%;
  margin-left: 20px;
  transform: translateY(-50%);
  width: 60%;
  height: 4px;
  background: linear-gradient(to right, #b2cade 0%, rgba(89, 194, 254, 0) 100%);
}
.industryContainer {
  display: flex;
  justify-content: center;
}
.industryContainer .industryList {
  display: flex;
  justify-content: space-between;
  width: 70%;
}
.industryList .industry {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.industryList .industry .industryImage {
  width: 82px;
  height: 82px;
  background: #f3f7ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}
.industryList .industry .industryName {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  width: 99%;
}
.industryList .industry .industryName p {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 22px;
}
.intro-benefit-container {
  width: 80%;
  margin: 50px auto 30px auto;
}

.intro-benefit-container h4 {
  font-family: "Inter", sans-serif;
  font-size: 42px;
  color: #353535;
  font-weight: 600;
  position: relative;
}

.intro-benefit-container h4::after {
  content: "";
  position: absolute;
  top: 80%;
  margin-left: 20px;
  transform: translateY(-50%);
  width: 40%;
  height: 4px;
  background: linear-gradient(to right, #b2cade 0%, rgba(89, 194, 254, 0) 100%);
}
.benefitRow {
  width: 80%;
}
.benefitFirstRow,
.benefitSecRow {
  display: flex;
  gap: 100px;
  align-items: flex-start;
}
.benefitContainer {
  display: flex;
  justify-content: center;
}
.efficiency,
.hygiene {
  /* border: 1px solid black; */
  width: 50%;
}
.benefitDes h3 {
  margin-top: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 32px;
  color: #353535;
}
.benefitDes p {
  margin-top: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 22px;
  color: #787878;
}
.imageBenefit {
  width: 125px;
  height: 125px;
  background: #f3f7ff;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.intro-part-container {
  width: 80%;
  margin: 50px auto 30px auto;
}

.intro-part-container h4 {
  font-family: "Inter", sans-serif;
  font-size: 42px;
  color: #353535;
  font-weight: 600;
  position: relative;
}

.intro-part-container h4::after {
  content: "";
  position: absolute;
  top: 80%;
  margin-left: 20px;
  transform: translateY(-50%);
  width: 40%;
  height: 4px;
  background: linear-gradient(to right, #b2cade 0%, rgba(89, 194, 254, 0) 100%);
}
.review-container {
  width: 85%;
  margin: 50px auto;
}

.review-carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.carousel-track {
  display: flex;
  align-items: stretch;
  transition: transform 0.5s ease-in-out;
}

.slide {
  min-width: 50%;
  box-sizing: border-box;
}

.review-box {
  display: flex;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 10px;
  position: relative;
}

.review-image {
  flex: 1.3;
  padding-right: 20px;
}

.review-image img {
  width: 100%;
}

.review-content {
  flex: 2;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #787878;
}

.review-content h3 {
  font-size: 20px;
  color: #353535;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}

.review-content .company {
  font-size: 15px;
  color: #353535;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}
.review-content .des {
  font-size: 14px;
  color: #787878;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  /* margin-top: -1rem; */
}
.review-content span {
  position: absolute;
  right: 5%;
  bottom: 0%;
}
.arrow {
  font-size: 15px;
  border: 1.5px solid #d2dff1;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: #f1f3f5;
  padding: 5px 10px;
  border-radius: 50%;
  display: block;
}

.left-arrow {
  left: 0;
}

.right-arrow {
  right: 0;
}
.partner {
  text-align: center;
}
.partner-logo {
  position: relative;
  height: 132px;
  border: 3px solid;
  margin-bottom: 10px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  border-radius: 15px;
}
.partner-logo:after {
  width: 0px;
  height: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid;
  position: absolute;
  content: close-quote;
  bottom: -10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.partner-name {
  margin-bottom: 60px;
}
.partner-name a {
  font-size: 19px;
  font-weight: 500;
  margin-top: 12px;
  display: inline-block;
  opacity: 0.6;
}
.partner:hover .partner-name a {
  opacity: 1;
}
.partner:hover .partner-logo {
  border: 3px solid;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.partner:hover .partner-logo:after {
  border-top: 10px solid;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.partner-logo img {
  opacity: 0.6;
}
.partner:hover .partner-logo img {
  opacity: 1;
}
/* .................................... 30. Portfolio details .......................................*/
.line-right {
  position: relative;
}
.line-right:after {
  position: absolute;
  content: close-quote;
  height: 1px;
  width: 20px;
  top: 50%;
  margin-left: 15px;
}
.case-info p {
  margin: 0;
}
.case-quote a {
  text-decoration: underline;
}
.each-gallery.case-gallery-links {
  margin-bottom: 30px;
}
.gallery-links {
  position: relative;
  display: block;
}
.each-gallery {
  margin-right: 12px;
}
.gallery-links .full-cover {
  color: #fff;
}
.each-gallery:last-child {
  margin: 0;
}
.gallery-links .full-cover {
  opacity: 0;
}
.gallery-links:hover .full-cover {
  opacity: 0.9;
}
.each-gallery.case-gallery-links {
  margin-bottom: 30px;
}
/* .................................... 31. Shop listing .......................................*/
.shop-prdt-img {
  position: relative;
}
.shop-prdt-img img {
  width: 100%;
}
.shop-prdt-img:before {
  position: absolute;
  content: close-quote;
  left: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  right: 0;
  opacity: 0;
}
.shop-buttons {
  opacity: 0;
  top: 55%;
  width: 100%;
  text-align: center;
}
.shop-buttons .btn {
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 43px;
  padding: 0;
  margin: 0px 5px;
}
.shop-buttons .btn-view {
  border: 2px solid;
}
.shop-buttons .btn-cart {
  padding: 0px 11px;
  width: auto;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
}
.shop-prdt-data .stars-rate i {
  font-size: 14px;
  margin-right: 0px;
  color: #d0d0d0;
}
.shop-prdt-data {
  background-color: #fff;
  padding: 20px 17px;
  padding-bottom: 15px;
}
.shop-prdt-data .hr-1 {
  margin-bottom: 10px;
  margin-top: 13px;
}
.real-price,
.lined-price {
  font-weight: bold;
  font-size: 16px;
}
.shop-prdt-img span {
  position: absolute;
  right: 15px;
  top: 15px;
  padding: 1px 10px;
  border-radius: 50px;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
}
.shop-prdt:hover:hover .shop-buttons {
  opacity: 1;
  top: 50%;
}
.shop-prdt:hover:hover .shop-prdt-img:before {
  opacity: 0.8;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.shop-buttons .btn-view:hover {
  border-color: #fff;
  color: #fff;
}
.lined-price {
  opacity: 0.4;
  text-decoration: line-through;
  font-size: 14px;
  margin-left: 5px;
}
.nice-select.sort-nice {
  float: right;
  padding: 0px 14px;
  min-width: 160px;
  height: auto;
}
.prdt-price {
  margin-top: 8px;
}
/* .................................... 32. Cart .......................................*/
.cart-table {
  width: calc(100% - 3px);
  min-width: 680px;
}
.cart-table tbody tr td {
  border-bottom: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  border-left: 1px solid #eaeaea;
  text-align: center;
  padding: 10px;
}
.cart-table thead tr th {
  border-bottom: 3px solid;
  font-size: 16px;
  padding: 10px;
  text-align: center;
  font-weight: 600;
  text-transform: capitalize;
  padding: 10px;
  text-align: center;
  padding: 12px 10px;
}
.prdt-thumb img {
  max-width: 75px;
  border: 1px solid #c3c3c3;
}
.prdt-remove {
  height: 110px;
}
.prdt-quantity .form-control {
  width: 68px;
  display: inline-block;
  margin-left: 2px;
}
.bg-calendar {
  position: relative;
}
.bg-calendar i {
  position: absolute;
  right: 20px;
  top: 15px;
}
/*custome date calendar*/
.datepicker td,
.datepicker th {
  font-size: 14px;
  width: 25px;
  height: 25px;
}
.datepicker {
  padding: 14px;
}
.datepicker table tr td.active.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active.disabled:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active[disabled] {
  background-color: #201c15;
}
.datepicker table tr td.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active:hover {
  background-image: none;
  background: #201c15;
}
.clear-cart {
  font-weight: bold;
  margin-top: 13px;
  margin-left: 11px;
  color: #f15933;
  border: none;
  background: none;
  padding: 0;
}
ul.total-table li span {
  float: left;
}
ul.total-table li {
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
  padding-bottom: 8px;
  /* margin-bottom: 8px; */
  font-size: 14px;
  text-align: right;
  font-weight: normal;
}
ul.total-table li:last-child {
  margin: 0;
  padding: 0;
  border: none;
}
ul.total-table li.total {
  font-size: 15px;
  font-weight: 700;
}
.cart-box-head {
  padding: 13px 25px;
  font-size: 18px;
}
.inner-cart-box {
  padding: 25px;
  border: 1px solid;
}
/* .................................... 33. Checkout .......................................*/
.login-section .card-header .btn:after,
.login-section .card-header.icon-change:after,
h4.ship-address.icon-change:after {
  content: "\f067";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  position: absolute;
  top: 15px;
  color: #111111;
  right: 20px;
  font-family: "Font Awesome 5 Free";
}
.login-section .collapseparent.show + .card-header .btn:after,
h4.ship-address:after {
  font-family: "Font Awesome 5 Free";
  content: "\f068";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  position: absolute;
  top: 15px;
  color: #111111;
  right: 20px;
}
.login-section .card-header {
  border-radius: 0px;
  border: none;
  padding: 17px 25px;
  width: 100%;
  flex-basis: 100%;
  order: 1;
}
.login-section .collapseparent {
  order: 2;
}
.login-section .card-header .btn {
  padding: 0;
  display: block;
  font-size: 15px;
  color: #14287b;
  line-height: 1.3;
  font-weight: 500;
  width: 100%;
  text-align: left;
  border: none;
}
.login-section .card-header .btn:hover,
.login-section .card-header .btn:focus {
  box-shadow: none;
  text-decoration: none;
}
.login-section .card {
  border-radius: 0px;
  border: none;
  margin-bottom: 20px;
  border-bottom: 2px solid;
  display: flex;
  flex-wrap: wrap;
}

.login-section .accordion > .card:not(:last-of-type) {
  border-bottom: 2px solid #0c2278;
}
select.form-control:not([size]):not([multiple]) {
  height: 50px;
}
.login-section .card-body {
  background-color: #f8f8f8;
  border-top: 1px solid #ccc;
}
.odered-items {
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 74px;
}
.odered-items .details {
  padding-left: 75px;
  position: relative;
  top: 2px;
  width: 100%;
}
.odered-items img {
  position: absolute;
  width: 60px;
  top: 0;
  left: 0;
}
.odered-items .details h4 {
  font-size: 14px;
  margin-bottom: 1px;
  text-align: left;
  color: #000;
}
.odered-items .details p.category {
  margin-bottom: 0px;
}
.quantity {
  font-size: 14px;
  text-align: left;
}
.details .amount {
  position: absolute;
  right: 0;
  top: 0;
  color: #333333;
}
.odered-items .details a {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0.3;
}
.your-order {
  padding: 35px 31px;
}
.left-checkout .input-white {
  border: 1px solid #ccc;
}
.left-checkout .input-white {
  border: 1px solid #ccc;
  padding: 4px 25px;
}
.left-checkout .nice-select {
  width: 100%;
}
.price-detail ul li {
  text-align: right;
}
.payment-method .card {
  background: none;
  border: none;
  margin-bottom: 10px;
}
.odered-items:last-child {
  border: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.payment-method .card h5 {
  font-size: 15px;
  color: #223645;
}
.payment-method .card-header {
  padding: 0;
  background-color: #ffffff;
  border: none;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #201c15;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #201c15;
}
.price-detail ul {
  background-color: #fff;
  padding: 20px;
}
.top-login-sign .cart-top i {
  margin-right: 2px;
}
.login-section .card-header.icon-change:after,
h4.ship-address.icon-change:after {
  content: "\f068";
}
.left-checkout textarea.input-white {
  padding-top: 20px;
}
/* .................................... 34. Product detail .......................................*/
#tab-selected-card .prdt-cat span {
  padding: 2px 9px;
  border-radius: 50px;
  font-weight: normal;
  font-size: 12px;
  background-color: #56dc95;
  color: #fff;
}
#tab-selected-card .prdt-reviews .stars-rate {
  font-size: 11px;
}
#tab-selected-card .rate-detail p {
  font-size: 22px;
  font-weight: 500;
  margin-top: 10px;
}
#tab-selected-card .rate-detail p .per-day {
  font-size: 13px;
  font-weight: normal;
}
#tab-selected-card .prdt-form .input-white {
  padding: 14px;
  border: 1px solid #ccc;
}
#tab-selected-card .prdt-form .input-white + i {
  top: 12px;
  font-size: 14px;
  right: 14px;
}
#tab-selected-card .rate-detail p .lined {
  text-decoration: line-through;
  opacity: 0.3;
  margin-right: 4px;
}
#tab-selected-card .prdt-form label {
  font-size: 13px;
  margin-bottom: 4px;
  font-weight: 500;
}
#tab-selected-card .prdt-tab {
  /* padding: 25px 25px; */
  /* margin-bottom: 2rem; */
}
#tab-selected-card .prdt-tab .nav-pills {
  border-bottom: 2px solid #bdbdbd;
}
#tab-selected-card .review-prdt {
  position: relative;
  padding-left: 75px;
  padding-bottom: 18px;
  margin-bottom: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
#tab-selected-card .review-prdt .user-img {
  position: absolute;
  left: 0;
  width: 56px;
  border-radius: 50%;
  padding: 2px;
  border: 1px solid #ccc;
}
#tab-selected-card .prdt-tab .nav-pills .nav-item {
  text-transform: uppercase;
  font-size: 14px;
  margin-right: 10px;
}
#tab-selected-card .nav-tabs .nav-link {
  border: 1px solid transparent;
  color: #828282;
  font-weight: 400;
}
#tab-selected-card ul.blue-dot-list.mt-3 {
  text-align: left;
}
#tab-selected-card .nav-tabs .nav-link.active,
#tab-selected-card .nav-tabs .show > .nav-link {
  background: unset;
  border-bottom: 2px solid #6fd1ba;
  transition: 0.4s;
  color: #000 !important;
  font-weight: 600;
}
#tab-selected-card .nav-tabs .nav-link.active {
  padding-left: 21px;
}
/* .scroll-table .pricing-table-tr,
.scroll-table .pricing-table-td,
.scroll-table .pricing-table-th {
  width: 100% !important;
} */
#tab-selected-card .prdt-tab .nav-pills .nav-item:last-child {
  margin-right: 0px;
}
#tab-selected-card .prdt-form .btn {
  padding: 12px 25px;
  font-size: 15px;
}
#tab-selected-card .user-img,
#service-box .user-img {
  width: 60px;
  height: 60px;
}
.blue-dot-list li {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7;
  position: relative;
  display: flex;
  margin-bottom: 0.5rem;
  align-items: center;
  border-radius: 7px;
  justify-content: left;
  /* padding: 10px; */
}
.blue-dot-list li:hover {
  /* box-shadow: rgb(0 0 0 / 15%) 4.4px 3.4px 14.2px;
  transition: 0.4s; */
}
#card-sec span img.icon,
#partner-card-sec span img.icon {
  background: #d9d9d9;
  border-radius: 20px;
  padding: 4px;
}
#card-sec span.list-text,
#partner-card-sec span.list-text {
  padding-left: 1rem;
  width: 64%;
}

.add-to-cart-btn {
  height: 35px;
  width: 95px;
  background: #6fd1ba;
  border: 0.5px solid #6fd1ba;
  border-radius: 8px;
  color: #fff;
  margin: 0 5px 0 5px;
}
.inner-header.fixed-nav .main-menu-1.menu-2 ul li a.active:after {
  bottom: -11px;
  height: 2px;
}
.column-4-prtflo .grid-item {
  width: 25%;
}
/* .................................... 35. Cost Calculator .......................................*/

#payment .expiry-date-group {
  float: left;
  width: 48%;
}

#payment .cards {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

#payment .expiry-date-group input {
  width: calc(100% + 1px);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-radius: 10px;
}

#payment .expiry-date-group input:focus {
  position: relative;
  z-index: 10;
}

#payment .security-code-group {
  float: right;
  width: 48%;
}

#payment .security-code-group input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-radius: 10px;
}

#PayButton {
  outline: 0 !important;
  height: 100%;
  align-items: center;
  font-size: 16px;
  background: #6fd1ba;
  border: none;
  border: 1px solid #6fd1ba;
  border-radius: 10px;
}

#PayButton:hover {
  background-color: #fff;
  transition: 0.4s;
  color: #6fd1ba;
}

#PayButton:active {
  background-color: #4fbcb9 !important;
}

#PayButton:disabled {
  background: rgba(84, 199, 195, 0.5) !important;
  color: #fff !important;
}

#payment .Checkout {
  z-index: 100001;
  width: 50%;
  /* min-width: 300px; */
  height: 100%;
  min-height: 100%;
  background: 0 0 #ffffff;
  border-radius: 8px;
  border: 1px solid #dedede;
  margin: 7rem auto;
  display: block;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

#payment .Checkout > h1 {
  margin: 0;
  padding: 20px;
  /* text-align: center;
     background: #EEF2F4;
     color: #5D6F78; */
  font-size: 24px;
  font-weight: 700;
  /* border-bottom: 1px solid #DEDEDE; */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

#payment .Checkout > form {
  margin: 0 25px 25px;
}

#payment label {
  margin-bottom: 8px;
  color: rgba(51, 51, 51, 0.6);
  font-weight: 500;
}

#payment .input-container {
  position: relative;
}

#payment .input-container input {
  padding-right: 25px;
}

#payment .input-container > i,
a[role="button"] {
  color: #d3d3d3;
  width: 25px;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  position: absolute;
  top: 2px;
  right: 2px;
  cursor: pointer;
  text-align: center;
}

#payment .input-container > i:hover,
a[role="button"]:hover {
  color: #777;
}
#payment .amount-placeholder {
  font-size: 20px;
  height: 34px;
}

#payment .amount-placeholder > button {
  float: right;
  width: 60px;
}

#payment .amount-placeholder > span {
  line-height: 34px;
}

#payment .card-row {
  text-align: center;
  /* margin: 20px 25px 10px; */
}

#payment .card-row span {
  width: 48px;
  height: 30px;
  margin-right: 3px;
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
}

#payment .card-image {
  background-repeat: no-repeat;
  padding-right: 50px;
  background-position: right 2px center;
  background-size: auto 90%;
  border-radius: 10px;
}

#payment .align-middle {
  vertical-align: middle;
}

#payment input {
  box-shadow: none !important;
}

#payment input:focus {
  border-color: #b0e5e3 !important;
  background-color: #eef9f9 !important;
}
#payment .calculation .nice-select {
  width: 100%;
  float: none;
}
#payment .calculation .input-white {
  padding: 9px 19px;
  height: 60px;
}
#payment .calculation .nice-select .list {
  width: 100%;
}
#payment .calculation .nice-select:after {
  right: 20px;
  height: 8px;
  width: 8px;
}
#payment .calculation .form-group {
  padding: 23px 25px;
  padding-top: 18px;
  margin-bottom: 30px;
}
#payment .total-cost-calc {
  max-width: 240px;
  margin: auto;
}
#payment .right-box-content .check-list li:last-child {
  margin-bottom: 0px;
}
#payment .right-box-content .check-list li {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 21px;
  padding-left: 46px;
}
#payment .right-box-content .check-list li i {
  color: #fff;
}
#payment .right-box-content .check-list li span {
  width: 27px;
  height: 27px;
  border: 1px solid #fff;
  line-height: 27px;
  top: 1px;
}
#payment .right-box-content .check-list {
  margin-top: 8px;
  margin-bottom: 8px;
}
#payment .book-service .input-white {
  border: 1px solid;
}
#thanku-modal .shine-btn,
#ThankYou .shine-btn {
  background: #a2a2a2;
  width: 20%;
  border-radius: 25px;
  margin: 1rem;
}
#thanku-modal .guest-btn,
#ThankYou .guest-btn {
  background: #6fd1ba;
  color: #fff;
  width: 20%;
  border-radius: 25px;
  margin-top: auto;
  margin: 1rem;
}
#thanku-modal .modal-header {
  border-bottom: unset;
}

#thanku-modal .thankU-btns,
#ThankYou .thankU-btns {
  display: flex;
  width: 100%;
  justify-content: center;
}
/* .................................... 36.Team .......................................*/
.team-member-image img {
  width: 100%;
}
.experience li span {
  display: block;
  font-size: 14px;
  font-weight: 600;
}
.experience li {
  position: relative;
  padding-left: 41px;
  padding-bottom: 21px;
  font-size: 14px;
}
.experience li:after {
  content: close-quote;
  position: absolute;
  width: 10px;
  height: 10px;
  left: 1px;
  top: 10px;
  border-radius: 50%;
}
.experience li:before {
  content: close-quote;
  position: absolute;
  left: 5px;
  top: 10px;
  height: 100%;
  width: 2px;
}
.experience li:last-child:before {
  height: 0;
}
.experience {
  margin-top: 20px;
}
.team-detail .check-list li {
  font-size: 14px;
  font-weight: normal;
}
.team-detail .check-list li span {
  width: 21px;
  height: 21px;
  border: 1px solid;
  font-size: 9px;
  top: 4px;
}
/* .................................... 37.Gallery .......................................*/
.each-work {
  position: relative;
}
.work-text {
  position: absolute;
  bottom: 35px;
  left: 50px;
  right: 50px;
  z-index: 10;
}
.each-work.center-plus .plus-btn .h-line {
  display: block;
  width: 2px;
  height: 50px;
  background-color: #ffffff;
  left: 49%;
  position: absolute;
}
.each-work.center-plus .plus-btn .v-line {
  display: block;
  width: 50px;
  height: 2px;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.each-work.center-plus .plus-btn {
  top: 50px;
  left: 50px;
  position: absolute;
  height: 50px;
  width: 50px;
  z-index: 10;
}
.each-work:hover .plus-btn {
  transform: rotate(90deg);
}
.each-work .work-text {
  opacity: 0.7;
}
.each-work:hover .work-text {
  opacity: 1;
}
.each-work .work-text p {
  max-height: 0;
  opacity: 0;
  padding-top: 10px;
  -webkit-transition: max-height 0.4s ease-in, opacity 0.4s ease-in;
  transition: max-height 0.4s ease-out, opacity 0.4s ease-in;
  overflow: hidden;
}
.each-work.center-plus .plus-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
}
.each-work.center-plus:hover .plus-btn {
  opacity: 1;
}
.each-work:hover img {
  opacity: 0.5;
}
.each-work:before {
  position: absolute;
  content: close-quote;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 6px solid;
  opacity: 0;
  z-index: 9;
}
.each-work:hover:before {
  opacity: 1;
}
/* .....................................track order ....................................... */
#TrackingOrder .hh-grayBox {
  background: #b8e9dd;
  padding: 4rem;
  border-radius: 10px;
}
#TrackingOrder .hh-grayBox .thread {
  position: absolute;
  top: 0%;
  left: 0%;
}
#TrackingOrder .pt45 {
  padding-top: 45px;
}
#TrackingOrder .order-tracking {
  text-align: center;
  width: 19.33%;
  position: relative;
  display: block;
}
#TrackingOrder .order-tracking .is-complete {
  display: block;
  position: relative;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: 0px solid #afafaf;
  background-color: #f7be16;
  margin: 0 auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
  z-index: 2;
}
#TrackingOrder .order-tracking .is-complete:after {
  display: block;
  position: absolute;
  content: "";
  height: 14px;
  width: 7px;
  top: -2px;
  bottom: 0;
  left: 5px;
  margin: auto 0;
  border: 0px solid #afafaf;
  border-width: 0px 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}
#TrackingOrder .order-tracking.completed .is-complete {
  border-color: #27aa80;
  border-width: 0px;
  background-color: #27aa80;
}
#TrackingOrder .order-tracking.completed .is-complete:after {
  border-color: #fff;
  border-width: 0px 3px 3px 0;
  width: 7px;
  left: 11px;
  opacity: 1;
}
#TrackingOrder .order-tracking p {
  color: #a4a4a4;
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 0;
  line-height: 20px;
}
#TrackingOrder .order-tracking p span {
  font-size: 12px;
}
#TrackingOrder .order-tracking.completed p {
  color: #000;
}
#TrackingOrder .order-tracking::before {
  content: "";
  display: block;
  height: 3px;
  width: calc(100% - 20px);
  background-color: #f7be16;
  top: 62px;
  position: absolute;
  left: calc(-50% + 6px);
  z-index: 0;
}
#TrackingOrder .header {
  background: #f6fafd;
  padding: 18px 35px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
#TrackingOrder .header h6 {
  color: #000;
  font-weight: 700;
}
#TrackingOrder .inner-div {
  box-shadow: rgb(0 0 0 / 70%) 8px 8px 23px, rgb(0 0 0 / 28%) 11px 15px 10px;
  border: 2px solid #2f846e;
  border-radius: 16px;
}
#TrackingOrder .bg-white {
  padding: 2rem;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
}
#TrackingOrder .address {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  height: 100%;
  align-items: center;
  margin: 2rem 0;
}
#TrackingOrder .address img {
  margin-right: 12px;
}
#TrackingOrder .address .dlvr-date {
  background: #fcf6bf;
  padding: 10px 30px;
  border: 2px solid #2f846e;
  border-radius: 10px;
}
#TrackingOrder .address .dlvr-location {
  background: #b8e9dd;
  padding: 10px 30px;
  border: 2px solid #2f846e;
  border-radius: 10px;
}
#TrackingOrder .order-tracking:first-child:before {
  display: none;
}
#TrackingOrder .order-tracking.completed:before {
  background-color: #27aa80;
}

#TrackingOrder .gray-bg {
  display: flex;
  width: 100%;
  align-items: center;
  background: #f6fafd;
  justify-content: space-between;
  padding: 10px 65px;
}

#TrackingOrder .profile-content {
  display: flex;
  align-items: center;
  background: #f6fafd;
  justify-content: space-around;
}

#TrackingOrder .profile img {
  width: 55%;
}
#TrackingOrder .content {
  margin-left: -27px;
}

#TrackingOrder .content .desg {
  font-size: 12px;
}

/* .................................... non use css .......................................*/
/*switch color css*/
.color-theme {
  position: fixed;
  top: 120px;
  z-index: 3333;
  left: -209px;
}
.theme-colors {
  width: 209px;
  background-color: #fff;
  padding: 18px 20px 15px 28px;
  padding-bottom: 6px;
  box-shadow: 1px 2px 10px -2px rgba(0, 0, 0, 0.25);
}
ul.theme-ul li {
  display: inline-block;
  width: 26%;
  margin-bottom: 13px;
  margin-right: 5%;
}
ul.theme-ul li img {
  width: 100%;
}
.theme-colors h4 {
  font-size: 15px;
  font-weight: bold;
  color: #16202b;
  margin-bottom: 10px;
}
.theme-switch {
  position: absolute;
  right: -50px;
  top: 0;
  background-color: #000;
  color: #fff;
  width: 50px;
  height: 50px;
  font-size: 18px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}
.color-theme:hover {
  left: 0;
}
.each-color {
  cursor: pointer;
  display: inline-block;
}
.theme-colors p {
  line-height: 19px;
}
/*menu style */
.menu-tp-22 .main-link {
  font-size: 30px;
  font-weight: bold;
  padding: 13px 0px;
  display: block;
  position: relative;
}
.menu-tp-22 .main-link i {
  position: absolute;
  right: 0;
  font-size: 15px;
  top: 36%;
}
.menu-tp-22 .main-link.icon-change i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.menu-tp-22 .main-link + ul li {
  padding-left: 25px;
  padding-bottom: 4px;
}
.menu-tp-22 .main-link + ul li:last-child {
  margin-bottom: 1px;
}
#image-gallery > div {
  height: 400px;
  overflow: hidden;
}
#image-gallery > div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
#image-gallery .owl-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  left: 0;
  right: 0;
}
#image-gallery .owl-nav > div {
  margin: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #979592;
  color: #979592;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
#image-gallery .owl-nav > div:hover {
  border-color: #14287b;
  color: #14287b;
}
#image-gallery .owl-nav > .owl-prev {
  left: 10px;
  position: absolute;
}
#image-gallery .owl-nav > .owl-next {
  right: 10px;
  position: absolute;
}
.prdt-thumbslider {
  margin-top: 5px;
}

.alert.d-block {
  display: block !important;
}

/* .................................... 38.My Account .......................................*/

#account h2 {
  margin-top: 5rem;
}
#account .nav-pills .nav-link.active,
#account .nav-pills .show > .nav-link {
  color: #01729e;
  background: #f0f0f0;
  border-radius: 4px;
}

#account .contact-form,
#account .Address-sec {
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 3px 5px 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
#account .Address-sec {
  padding: 33px;
}

#account .address-btn,
#account .details-btn {
  padding: 8px 36px;
  min-width: fit-content;
  border-radius: 0px;
}

#account .contact-form .input-white {
  padding: 6px 20px;
  background: #f3f3f3;
  border: 1px solid rgba(156, 156, 156, 0.5);
}
#account .Address-sec .input-white {
  padding: 6px 20px;
  background: #f3f3f3;
  border: 1px solid rgba(156, 156, 156, 0.5);
}
#account .nav-pills .nav-link {
  border-radius: 0.25rem;
  color: #3e3e3f;
}
#account .nav-link {
  padding: 1rem 1rem;
}

#account .outer-space {
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 3px 5px 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 3rem;
}
#account .tab-content {
  background: rgba(255, 255, 255, 0.5);
}
#account .table-sm td {
  padding: 20px 0px;
  font-size: 12px;
}
#account .table-sm th {
  padding: 20px 0px;
  font-size: 14px;
}
#account .order-id {
  background: #f3f3f3;
  border-radius: 33.799px;
  font-size: 13px;
  line-height: 44px;
  letter-spacing: 0.6px;
  color: #01729e;
  padding: 15px;
}
#account .order-placed {
  font-weight: 500;
  font-size: 13px;
  line-height: 44px;
  letter-spacing: 0.6px;
  color: rgba(0, 0, 0, 0.5);
  padding-left: 1rem;
}
#account .track-btn {
  background: #2f846e;
  color: #fff;
  font-size: 13px;
  text-shadow: none;
  border-radius: 25px;
  padding: 13px;
}
#account .green-bg {
  background: #b8e9dd;
  box-shadow: 0px 3px 5px 3px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 5rem;
}
#account .logout-card {
  border-radius: 22px;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  margin: 5rem;
}
#account .cancel-btn {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  padding: 10px 20px;
  color: #000;
}
#account .logout-btn {
  background: #2f846e;
  border: 1px solid #2f846e;
  color: #fff;
  border-radius: 8px;
  padding: 10px 20px;
  margin-left: 1rem;
}
#account .modal-header {
  border-bottom: unset;
}
#account .modal-footer {
  border-top: unset;
}
#account .modal {
  z-index: 2;
}
#account .modal-content {
  border: 1px solid #fff;
}
#account .Address-sec .text-field,
#account .Address-sec .text-field-border {
  color: #01729e;
  background: #f0f0f0;
  border-radius: 4px;
  padding: 1rem;
  border: 1px solid rgba(156, 156, 156, 0.5);
}
#account .Address-sec .text-field-border .btn {
  background: #d9d9d9;
  font-size: 15px;
  letter-spacing: 0.6px;
  color: rgba(0, 0, 0, 0.5);
}
#account .Address-sec .text-field-border p {
  letter-spacing: 0.6px;
  color: rgba(0, 0, 0, 0.5);
  padding-top: 6px;
  margin-bottom: unset;
}
#account .Address-sec .text-field-border {
  margin-top: 1rem;
  border: 1px solid rgba(156, 156, 156, 0.5);
}
.main-menu-1 {
  height: unset;
  width: 100%;
}
/* media query */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .main-menu-1 {
    overflow-y: unset;
    width: 80%;
  }
  .head-type-1 {
    height: 116px !important;
  }
  .mobile-trigger {
    top: 20px;
  }
  .main-menu-1 > div {
    background: #fff;
  }
  .head-1-book-2 {
    display: block;
  }
  #form-modal form .d-flex {
    display: unset !important;
  }
  #form-modal form {
    padding-top: 3px;
    margin-bottom: 1rem;
  }
  #form-modal .modal-body .d-flex.w-100 {
    margin-bottom: 1rem;
  }
  #form-modal .proceed-btn {
    width: 100% !important;
  }
  #helpBanner .search {
    width: 100%;
  }
  #LuxuryServices .d-flex {
    display: unset !important;
  }
  #BussineSupport .quoted-text {
    padding: 20px 30px 10px 31px;
  }
  #partner-card-sec .service-btn-sec {
    width: 60%;
  }
  #BusinessBox .blog-image {
    margin-bottom: 3rem;
  }
  #tab-cards .prdt-tab .tab-nav,
  #tab-cards .green-bg {
    padding: 1.1rem;
    justify-content: flex-start !important;
  }
  #tab-cards .prdt-tabs {
    background: linear-gradient(
      to bottom,
      #6fd1ba 13.85%,
      #6fd1ba 0%,
      #fff 0%,
      #fff 82%
    );
  }
  #selected-card .card-mg {
    width: 25%;
    height: 50px;
  }
  .prdt-tab {
    padding: 15px 0px !important;
  }
  #small-cards .card-box,
  #card-sec .feature-div {
    display: grid;
  }
  #small-cards .card-content,
  #card-sec .feature-text {
    margin-bottom: 1rem;
  }
  #card-sec .service-each .service-box {
    display: unset !important;
  }
  #card-sec .service-text {
    height: unset;
  }
  .service-icn img {
    margin: 1rem 0;
  }
  #card-sec h3,
  #partner-card-sec h3 {
    width: 100%;
  }
  #ThankYou .btn-black {
    min-width: 128px;
    padding: 6px 6px;
  }
  #booking-sec h2 {
    font-size: 1.5rem;
    padding: 0rem 2rem;
  }
  #BussineSupport h2 {
    margin-top: 2rem;
  }
  #EverydayTask .local-content {
    width: 100%;
    margin-top: 4rem;
  }
  #GetTheMost .card-body p.card-text {
    padding: 17px 42px 0 20px;
  }
  #GetTheMost img.rounded-start {
    padding-left: 2rem;
  }
  #banner-sec .three {
    left: 3%;
  }
  #banner-sec .w-70 {
    width: 100%;
  }
  #banner-sec .one.box {
    position: relative;
    left: 90%;
    top: -68px;
  }
  /* tracking order*/
  #TrackingOrder .order-tracking {
    width: 100%;
    position: relative;
    margin-bottom: 4rem;
    z-index: 2;
  }
  #TrackingOrder .gray-bg {
    padding: 10px 7px;
  }
  #TrackingOrder .address {
    display: block;
  }
  #TrackingOrder .address .dlvr-date {
    margin-bottom: 1rem;
    display: flex;
  }
  #TrackingOrder .address .dlvr-location {
    display: flex;
  }
  #TrackingOrder .order-tracking::before {
    z-index: -1;
    top: 80px;
    left: calc(-50% + 105px);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  #TrackingOrder .hh-grayBox {
    padding: 2rem;
  }
  #TrackingOrder .pt-95 {
    padding-top: 27px;
  }
  #TrackingOrder .order-tracking.completed p {
    background: #fff;
  }
  #TrackingOrder .order-tracking.completed .is-complete {
    top: 75%;
  }
  /* Account pages */
  #account .table-sm td,
  #account .table-sm th {
    padding: 20px 20px;
  }
  #account .green-bg {
    padding: 0rem;
  }
  #account .logout-card {
    margin: 0rem;
  }
  /* footer */
  footer .our-app,
  footer .icon-div {
    display: unset !important;
  }
  footer .app-img {
    width: 50%;
  }

  #tab-cards .pt-md-25 {
    padding-top: 0px;
  }
  #workBanner {
    padding-bottom: 2rem;
  }
  #workFlow .rightflow-img {
    margin-top: unset;
  }

  #workFlow .arrow-1,
  #workFlow .arrow-2 {
    display: none;
  }

  #partner-card-sec .service-each .d-flex {
    display: unset !important;
  }
  #partner-card-sec .service-each {
    padding: 20px 20px 0px;
  }
  #booking-sec .book-btn {
    padding: 0.5rem 0.5rem;
  }
  #life-easy.pt-95 {
    padding-top: unset;
  }
  #life-easy .owl-carousel.owl-drag .owl-item {
    padding-top: 4rem;
  }
  /* button#cartDropdownMenuButton {
    margin: 0.6rem;
    float: right;
  } */
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  #tab-cards .pt-md-25 {
    padding-top: 0px;
  }
  .head-type-1 {
    height: 116px !important;
  }
  #form-modal form .d-flex {
    display: unset !important;
  }
  #form-modal form {
    margin-bottom: 1rem;
  }
  #summary-table .scroll_table {
    overflow-x: scroll;
    width: 186px;
  }
  #summary-table .scroll_table img {
    max-width: 50px !important;
  }
  #tab-cards .prdt-tab .tab-nav,
  #tab-cards .green-bg {
    padding: 1.1rem;
  }
  .mobile-trigger {
    top: 20px;
    right: 22px;
  }
  footer .app-img {
    width: 40%;
  }
  .container {
    padding-right: unset;
    padding-left: unset;
  }
  #selected-card .card-mg {
    width: 15%;
    height: 50px;
  }
  #banner-sec .w-70 {
    width: 100%;
  }
  #banner-sec .box.two img.banner_img {
    width: 85%;
  }
  #EverydayTask .local-content {
    width: 80%;
  }
  /* tracking order*/
  #TrackingOrder .order-tracking {
    width: 100%;
    position: relative;
    margin-bottom: 5rem;
  }
  #TrackingOrder .gray-bg {
    padding: 10px 7px;
  }
  /* #TrackingOrder .address {
    display: block;
  } */
  #TrackingOrder .address .dlvr-date {
    margin-bottom: 1rem;
    display: flex;
    margin: 0.5rem;
  }
  #TrackingOrder .order-tracking::before {
    width: calc(100% - 371px);
    top: 0px;
    left: calc(-50% + 479px);
    transform: rotate(90deg);
  }
  #TrackingOrder .hh-grayBox {
    padding: 2rem;
  }
  #TrackingOrder .pt-95 {
    padding-top: 27px;
  }
  #TrackingOrder .order-tracking.completed p {
    float: left;
    margin-left: 5rem;
  }
  #TrackingOrder .order-tracking .mt-3 {
    margin-top: -2rem !important;
  }
  #TrackingOrder .address .dlvr-location {
    display: flex;
    margin: 0.5rem;
  }
  #TrackingOrder .address img {
    margin-right: 2rem;
  }
  /* button#cartDropdownMenuButton {
    margin: 0.6rem;
    float: right;
  } */
  .main-menu-1 {
    height: unset;
    width: 91%;
  }
  #BussineSupport .quoted-text {
    padding: 20px 10px 10px 31px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  #tab-cards .prdt-tab .tab-nav,
  #tab-cards .green-bg {
    padding: 1rem 2rem;
  }
  #tab-cards .prdt-tabs {
    /* background: linear-gradient(
      to bottom,
      #6fd1ba 10%,
      #6fd1ba 0%,
      #fff 0%,
      #fff 82%
    ); */
  }
  #life-easy .green-banner {
    top: 20%;
  }
  #selected-card .card-mg {
    width: 10%;
    height: 46px;
  }
  #service-box .all_cards {
    margin-bottom: 2rem;
  }
  .head-type-2.head-type-3 .main-menu-1 > div > ul > li > a {
    padding: 15px 10px;
  }
  .main-menu-1 {
    width: 100%;
  }
  #summary-item-card .card-text {
    padding: 0rem 0rem;
  }
  #selected-card .col-md-4 {
    padding-left: 0;
  }
  /* button#cartDropdownMenuButton {
    margin: 0.6rem;
    float: right;
  } */
  footer .app-img {
    width: 40%;
  }
  #life-easy.pt-95 {
    padding-top: unset;
  }
  .main-menu-1 {
    height: unset;
    width: 93%;
  }
  .mobile-trigger {
    top: 18px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  #selected-card .card-mg {
    width: 10%;
    height: 55px;
  }
  /* #tab-cards .prdt-tabs {
    background: linear-gradient(
      to bottom,
      #6fd1ba 10.29%,
      #6fd1ba 0%,
      #fff 0%,
      #fff 82%
    );
  } */
  #tab-cards .prdt-tab .tab-nav,
  #tab-cards .green-bg {
    padding: 1rem 5rem;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1399px) {
  #selected-card .card-mg {
    width: 10%;
    height: 55px;
  }
  #tab-cards .prdt-tab .tab-nav,
  #tab-cards .green-bg {
    padding: 1rem 8rem !important;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1620px) {
  #selected-card .card-mg {
    width: 10%;
    height: 55px;
  }
}

@media only screen and (min-width: 1621px) and (max-width: 1919px) {
  #tab-cards .prdt-tab .tab-nav {
    padding: 1rem 11rem;
  }
}

@media only screen and (min-width: 2021px) and (max-width: 2499px) {
  #tab-cards .prdt-tab .tab-nav {
    padding: 1rem 43rem;
  }
}

@media only screen and (min-width: 2500px) and (max-width: 3500px) {
  #tab-cards .prdt-tab .tab-nav,
  #tab-cards .green-bg {
    padding: 1rem 45.5rem;
  }
}
a:focus,
a:hover {
  text-decoration: unset !important;
  color: unset !important;
}
