/*============================================*/

/* Deafult css styles */

/*============================================*/

/*-- Margin Top --*/

.mt-5 {
  margin-top: 0px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

/*-- Margin Bottom --*/

.mb-0 {
  margin-bottom: 0px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

/*--margin right--*/

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-65 {
  margin-right: 65px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-75 {
  margin-right: 75px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-85 {
  margin-right: 85px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-95 {
  margin-right: 95px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-105 {
  margin-right: 105px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-115 {
  margin-right: 115px;
}

.mr-120 {
  margin-right: 120px;
}

/*--margin left--*/

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-65 {
  margin-left: 65px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-85 {
  margin-left: 85px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-105 {
  margin-left: 105px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-115 {
  margin-left: 115px;
}

.ml-120 {
  margin-left: 120px;
}

/*-- Padding Top --*/

.pt-00 {
  padding-top: 0px !important;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

/*-- Padding Bottom --*/

.pb-00 {
  padding-bottom: 0px !important;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

/*-- Padding Left --*/

.pl-00 {
  padding-left: 0px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-55 {
  padding-left: 55px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-65 {
  padding-left: 65px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-75 {
  padding-left: 75px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-85 {
  padding-left: 80px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-95 {
  padding-left: 95px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-105 {
  padding-left: 105px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-115 {
  padding-left: 115px;
}

.pl-120 {
  padding-left: 120px;
}

/*-- Padding Right --*/

.pr-00 {
  padding-right: 0px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 35px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-55 {
  padding-right: 55px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-65 {
  padding-right: 65px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-75 {
  padding-right: 75px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-85 {
  padding-right: 80px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-95 {
  padding-right: 95px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-105 {
  padding-right: 105px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-115 {
  padding-right: 115px;
}

.pr-120 {
  padding-right: 120px;
}

/*============================================*/

/*Responsive Margin and Padding */

/*============================================*/

/* Large devices (desktops, 992px and down) */

@media (max-width: 1199px) {
  /*-- Margin Top --*/
  .mt-lg-00 {
    margin-top: 0px !important;
  }
  .mt-lg-5 {
    margin-top: 5px !important;
  }
  .mt-lg-10 {
    margin-top: 10px;
  }
  .mt-lg-15 {
    margin-top: 15px;
  }
  .mt-lg-20 {
    margin-top: 20px;
  }
  .mt-lg-25 {
    margin-top: 25px;
  }
  .mt-lg-30 {
    margin-top: 30px;
  }
  .mt-lg-35 {
    margin-top: 35px;
  }
  .mt-lg-40 {
    margin-top: 40px;
  }
  .mt-lg-45 {
    margin-top: 45px;
  }
  .mt-lg-50 {
    margin-top: 50px;
  }
  .mt-lg-55 {
    margin-top: 55px;
  }
  .mt-lg-60 {
    margin-top: 60px;
  }
  .mt-lg-65 {
    margin-top: 65px;
  }
  .mt-lg-70 {
    margin-top: 70px;
  }
  .mt-lg-75 {
    margin-top: 75px;
  }
  .mt-lg-80 {
    margin-top: 80px;
  }
  .mt-lg-85 {
    margin-top: 85px;
  }
  .mt-lg-90 {
    margin-top: 90px;
  }
  .mt-lg-95 {
    margin-top: 95px;
  }
  .mt-lg-100 {
    margin-top: 100px;
  }
  /*-- Margin Bottom --*/
  .mb-lg-00 {
    margin-bottom: 0px !important;
  }
  .mb-lg-5 {
    margin-bottom: 5px !important;
  }
  .mb-lg-10 {
    margin-bottom: 10px;
  }
  .mb-lg-15 {
    margin-bottom: 15px;
  }
  .mb-lg-20 {
    margin-bottom: 20px;
  }
  .mb-lg-25 {
    margin-bottom: 25px;
  }
  .mb-lg-30 {
    margin-bottom: 30px;
  }
  .mb-lg-35 {
    margin-bottom: 35px;
  }
  .mb-lg-40 {
    margin-bottom: 40px;
  }
  .mb-lg-45 {
    margin-bottom: 45px;
  }
  .mb-lg-50 {
    margin-bottom: 50px;
  }
  .mb-lg-55 {
    margin-bottom: 55px;
  }
  .mb-lg-60 {
    margin-bottom: 60px;
  }
  .mb-lg-65 {
    margin-bottom: 65px;
  }
  .mb-lg-70 {
    margin-bottom: 70px;
  }
  .mb-lg-75 {
    margin-bottom: 75px;
  }
  .mb-lg-80 {
    margin-bottom: 80px;
  }
  .mb-lg-85 {
    margin-bottom: 85px;
  }
  .mb-lg-90 {
    margin-bottom: 90px;
  }
  .mb-lg-95 {
    margin-bottom: 95px;
  }
  .mb-lg-100 {
    margin-bottom: 100px;
  }
  /*--margin right--*/
  .mr-lg-00 {
    margin-right: 0px !important;
  }
  .mr-lg-5 {
    margin-right: 5px !important;
  }
  .mr-lg-10 {
    margin-right: 10px;
  }
  .mr-lg-15 {
    margin-right: 15px;
  }
  .mr-lg-20 {
    margin-right: 20px;
  }
  .mr-lg-25 {
    margin-right: 25px;
  }
  .mr-lg-30 {
    margin-right: 30px;
  }
  .mr-lg-35 {
    margin-right: 35px;
  }
  .mr-lg-40 {
    margin-right: 40px;
  }
  .mr-lg-45 {
    margin-right: 45px;
  }
  .mr-lg-50 {
    margin-right: 50px;
  }
  .mr-lg-55 {
    margin-right: 55px;
  }
  .mr-lg-60 {
    margin-right: 60px;
  }
  .mr-lg-65 {
    margin-right: 65px;
  }
  .mr-lg-70 {
    margin-right: 70px;
  }
  .mr-lg-75 {
    margin-right: 75px;
  }
  .mr-lg-80 {
    margin-right: 80px;
  }
  .mr-lg-85 {
    margin-right: 85px;
  }
  .mr-lg-90 {
    margin-right: 90px;
  }
  .mr-lg-95 {
    margin-right: 95px;
  }
  .mr-lg-100 {
    margin-right: 100px;
  }
  /*--margin left--*/
  .ml-lg-00 {
    margin-left: 0px !important;
  }
  .ml-lg-5 {
    margin-left: 5px !important;
  }
  .ml-lg-10 {
    margin-left: 10px;
  }
  .ml-lg-15 {
    margin-left: 15px;
  }
  .ml-lg-20 {
    margin-left: 20px;
  }
  .ml-lg-25 {
    margin-left: 25px;
  }
  .ml-lg-30 {
    margin-left: 30px;
  }
  .ml-lg-35 {
    margin-left: 35px;
  }
  .ml-lg-40 {
    margin-left: 40px;
  }
  .ml-lg-45 {
    margin-left: 45px;
  }
  .ml-lg-50 {
    margin-left: 50px;
  }
  .ml-lg-55 {
    margin-left: 55px;
  }
  .ml-lg-60 {
    margin-left: 60px;
  }
  .ml-lg-65 {
    margin-left: 65px;
  }
  .ml-lg-70 {
    margin-left: 70px;
  }
  .ml-lg-75 {
    margin-left: 75px;
  }
  .ml-lg-80 {
    margin-left: 80px;
  }
  .ml-lg-85 {
    margin-left: 85px;
  }
  .ml-lg-90 {
    margin-left: 90px;
  }
  .ml-lg-95 {
    margin-left: 95px;
  }
  .ml-lg-100 {
    margin-left: 100px;
  }
  /*-- Padding Top --*/
  .pt-lg-00 {
    padding-top: 0px !important;
  }
  .pt-lg-5 {
    padding-top: 5px !important;
  }
  .pt-lg-10 {
    padding-top: 10px;
  }
  .pt-lg-15 {
    padding-top: 15px;
  }
  .pt-lg-20 {
    padding-top: 20px;
  }
  .pt-lg-25 {
    padding-top: 25px;
  }
  .pt-lg-30 {
    padding-top: 30px;
  }
  .pt-lg-35 {
    padding-top: 35px;
  }
  .pt-lg-40 {
    padding-top: 40px;
  }
  .pt-lg-45 {
    padding-top: 45px;
  }
  .pt-lg-50 {
    padding-top: 50px;
  }
  .pt-lg-55 {
    padding-top: 55px;
  }
  .pt-lg-60 {
    padding-top: 60px;
  }
  .pt-lg-65 {
    padding-top: 65px;
  }
  .pt-lg-70 {
    padding-top: 70px;
  }
  .pt-lg-75 {
    padding-top: 75px;
  }
  .pt-lg-80 {
    padding-top: 80px;
  }
  .pt-lg-85 {
    padding-top: 85px;
  }
  .pt-lg-90 {
    padding-top: 90px;
  }
  .pt-lg-95 {
    padding-top: 95px;
  }
  .pt-lg-100 {
    padding-top: 100px;
  }
  /*-- Padding Bottom --*/
  .pb-lg-00 {
    padding-bottom: 0px !important;
  }
  .pb-lg-5 {
    padding-bottom: 5px !important;
  }
  .pb-lg-10 {
    padding-bottom: 10px;
  }
  .pb-lg-15 {
    padding-bottom: 15px;
  }
  .pb-lg-20 {
    padding-bottom: 20px;
  }
  .pb-lg-25 {
    padding-bottom: 25px;
  }
  .pb-lg-30 {
    padding-bottom: 30px;
  }
  .pb-lg-35 {
    padding-bottom: 35px;
  }
  .pb-lg-40 {
    padding-bottom: 40px;
  }
  .pb-lg-45 {
    padding-bottom: 45px;
  }
  .pb-lg-50 {
    padding-bottom: 50px;
  }
  .pb-lg-55 {
    padding-bottom: 55px;
  }
  .pb-lg-60 {
    padding-bottom: 60px;
  }
  .pb-lg-65 {
    padding-bottom: 65px;
  }
  .pb-lg-70 {
    padding-bottom: 70px;
  }
  .pb-lg-75 {
    padding-bottom: 75px;
  }
  .pb-lg-80 {
    padding-bottom: 80px;
  }
  .pb-lg-85 {
    padding-bottom: 85px;
  }
  .pb-lg-90 {
    padding-bottom: 90px;
  }
  .pb-lg-95 {
    padding-bottom: 95px;
  }
  .pb-lg-100 {
    padding-bottom: 100px;
  }
  /*-- Padding Left --*/
  .pl-lg-00 {
    padding-left: 0px !important;
  }
  .pl-lg-5 {
    padding-left: 5px !important;
  }
  .pl-lg-10 {
    padding-left: 10px;
  }
  .pl-lg-15 {
    padding-left: 15px;
  }
  .pl-lg-20 {
    padding-left: 20px;
  }
  .pl-lg-25 {
    padding-left: 25px;
  }
  .pl-lg-30 {
    padding-left: 30px;
  }
  .pl-lg-35 {
    padding-left: 35px;
  }
  .pl-lg-35 {
    padding-left: 35px;
  }
  .pl-lg-40 {
    padding-left: 40px;
  }
  .pl-lg-45 {
    padding-left: 45px;
  }
  .pl-lg-50 {
    padding-left: 50px;
  }
  .pl-lg-55 {
    padding-left: 55px;
  }
  .pl-lg-60 {
    padding-left: 60px;
  }
  .pl-lg-65 {
    padding-left: 65px;
  }
  .pl-lg-70 {
    padding-left: 70px;
  }
  .pl-lg-75 {
    padding-left: 75px;
  }
  .pl-lg-80 {
    padding-left: 80px;
  }
  .pl-lg-85 {
    padding-left: 85px;
  }
  .pl-lg-90 {
    padding-left: 90px;
  }
  .pl-lg-95 {
    padding-left: 95px;
  }
  .pl-lg-100 {
    padding-left: 100px;
  }
  /*-- Padding Right --*/
  .pr-lg-00 {
    padding-right: 0px !important;
  }
  .pr-lg-5 {
    padding-right: 5px !important;
  }
  .pr-lg-10 {
    padding-right: 10px;
  }
  .pr-lg-15 {
    padding-right: 15px;
  }
  .pr-lg-20 {
    padding-right: 20px;
  }
  .pr-lg-25 {
    padding-right: 35px;
  }
  .pr-lg-30 {
    padding-right: 30px;
  }
  .pr-lg-35 {
    padding-right: 35px;
  }
  .pr-lg-35 {
    padding-right: 35px;
  }
  .pr-lg-40 {
    padding-right: 40px;
  }
  .pr-lg-45 {
    padding-right: 45px;
  }
  .pr-lg-50 {
    padding-right: 50px;
  }
  .pr-lg-55 {
    padding-right: 55px;
  }
  .pr-lg-60 {
    padding-right: 60px;
  }
  .pr-lg-65 {
    padding-right: 65px;
  }
  .pr-lg-70 {
    padding-right: 70px;
  }
  .pr-lg-75 {
    padding-right: 75px;
  }
  .pr-lg-80 {
    padding-right: 80px;
  }
  .pr-lg-85 {
    padding-right: 85px;
  }
  .pr-lg-90 {
    padding-right: 90px;
  }
  .pr-lg-95 {
    padding-right: 95px;
  }
  .pr-lg-100 {
    padding-right: 100px;
  }
}

/* Medium devices (tablets, 768px and up) */

@media (max-width: 991px) {
  /*-- Margin Top --*/
  .mt-md-00 {
    margin-top: 0px !important;
  }
  .mt-md-5 {
    margin-top: 5px !important;
  }
  .mt-md-10 {
    margin-top: 10px;
  }
  .mt-md-15 {
    margin-top: 15px;
  }
  .mt-md-20 {
    margin-top: 20px;
  }
  .mt-md-25 {
    margin-top: 25px;
  }
  .mt-md-30 {
    margin-top: 30px;
  }
  .mt-md-35 {
    margin-top: 35px;
  }
  .mt-md-40 {
    margin-top: 40px;
  }
  .mt-md-45 {
    margin-top: 45px;
  }
  .mt-md-50 {
    margin-top: 50px;
  }
  .mt-md-55 {
    margin-top: 55px;
  }
  .mt-md-60 {
    margin-top: 60px;
  }
  .mt-md-65 {
    margin-top: 65px;
  }
  .mt-md-70 {
    margin-top: 70px;
  }
  .mt-md-75 {
    margin-top: 75px;
  }
  .mt-md-80 {
    margin-top: 80px;
  }
  .mt-md-85 {
    margin-top: 85px;
  }
  .mt-md-90 {
    margin-top: 90px;
  }
  .mt-md-95 {
    margin-top: 95px;
  }
  .mt-md-100 {
    margin-top: 100px;
  }
  /*-- Margin Bottom --*/
  .mb-md-00 {
    margin-bottom: 0px !important;
  }
  .mb-md-5 {
    margin-bottom: 5px !important;
  }
  .mb-md-10 {
    margin-bottom: 10px;
  }
  .mb-md-15 {
    margin-bottom: 15px;
  }
  .mb-md-20 {
    margin-bottom: 20px;
  }
  .mb-md-25 {
    margin-bottom: 25px;
  }
  .mb-md-30 {
    margin-bottom: 30px;
  }
  .mb-md-35 {
    margin-bottom: 35px;
  }
  .mb-md-40 {
    margin-bottom: 40px;
  }
  .mb-md-45 {
    margin-bottom: 45px;
  }
  .mb-md-50 {
    margin-bottom: 50px;
  }
  .mb-md-55 {
    margin-bottom: 55px;
  }
  .mb-md-60 {
    margin-bottom: 60px;
  }
  .mb-md-65 {
    margin-bottom: 65px;
  }
  .mb-md-70 {
    margin-bottom: 70px;
  }
  .mb-md-75 {
    margin-bottom: 75px;
  }
  .mb-md-80 {
    margin-bottom: 80px;
  }
  .mb-md-85 {
    margin-bottom: 85px;
  }
  .mb-md-90 {
    margin-bottom: 90px;
  }
  .mb-md-95 {
    margin-bottom: 95px;
  }
  .mb-md-100 {
    margin-bottom: 100px;
  }
  /*--margin right--*/
  .mr-md-00 {
    margin-right: 0px !important;
  }
  .mr-md-5 {
    margin-right: 5px !important;
  }
  .mr-md-10 {
    margin-right: 10px;
  }
  .mr-md-15 {
    margin-right: 15px;
  }
  .mr-md-20 {
    margin-right: 20px;
  }
  .mr-md-25 {
    margin-right: 25px;
  }
  .mr-md-30 {
    margin-right: 30px;
  }
  .mr-md-35 {
    margin-right: 35px;
  }
  .mr-md-40 {
    margin-right: 40px;
  }
  .mr-md-45 {
    margin-right: 45px;
  }
  .mr-md-50 {
    margin-right: 50px;
  }
  .mr-md-55 {
    margin-right: 55px;
  }
  .mr-md-60 {
    margin-right: 60px;
  }
  .mr-md-65 {
    margin-right: 65px;
  }
  .mr-md-70 {
    margin-right: 70px;
  }
  .mr-md-75 {
    margin-right: 75px;
  }
  .mr-md-80 {
    margin-right: 80px;
  }
  .mr-md-85 {
    margin-right: 85px;
  }
  .mr-md-90 {
    margin-right: 90px;
  }
  .mr-md-95 {
    margin-right: 95px;
  }
  .mr-md-100 {
    margin-right: 100px;
  }
  /*--margin left--*/
  .ml-md-00 {
    margin-left: 0px !important;
  }
  .ml-md-5 {
    margin-left: 5px !important;
  }
  .ml-md-10 {
    margin-left: 10px;
  }
  .ml-md-15 {
    margin-left: 15px;
  }
  .ml-md-20 {
    margin-left: 20px;
  }
  .ml-md-25 {
    margin-left: 25px;
  }
  .ml-md-30 {
    margin-left: 30px;
  }
  .ml-md-35 {
    margin-left: 35px;
  }
  .ml-md-40 {
    margin-left: 40px;
  }
  .ml-md-45 {
    margin-left: 45px;
  }
  .ml-md-50 {
    margin-left: 50px;
  }
  .ml-md-55 {
    margin-left: 55px;
  }
  .ml-md-60 {
    margin-left: 60px;
  }
  .ml-md-65 {
    margin-left: 65px;
  }
  .ml-md-70 {
    margin-left: 70px;
  }
  .ml-md-75 {
    margin-left: 75px;
  }
  .ml-md-80 {
    margin-left: 80px;
  }
  .ml-md-85 {
    margin-left: 85px;
  }
  .ml-md-90 {
    margin-left: 90px;
  }
  .ml-md-95 {
    margin-left: 95px;
  }
  .ml-md-100 {
    margin-left: 100px;
  }
  /*-- Padding Top --*/
  .pt-md-00 {
    padding-top: 0px !important;
  }
  .pt-md-5 {
    padding-top: 5px !important;
  }
  .pt-md-10 {
    padding-top: 10px;
  }
  .pt-md-15 {
    padding-top: 15px;
  }
  .pt-md-20 {
    padding-top: 20px;
  }
  .pt-md-25 {
    padding-top: 25px;
  }
  .pt-md-30 {
    padding-top: 30px;
  }
  .pt-md-35 {
    padding-top: 35px;
  }
  .pt-md-40 {
    padding-top: 40px;
  }
  .pt-md-45 {
    padding-top: 45px;
  }
  .pt-md-50 {
    padding-top: 50px;
  }
  .pt-md-55 {
    padding-top: 55px;
  }
  .pt-md-60 {
    padding-top: 60px;
  }
  .pt-md-65 {
    padding-top: 65px;
  }
  .pt-md-70 {
    padding-top: 70px;
  }
  .pt-md-75 {
    padding-top: 75px;
  }
  .pt-md-80 {
    padding-top: 80px;
  }
  .pt-md-85 {
    padding-top: 85px;
  }
  .pt-md-90 {
    padding-top: 90px;
  }
  .pt-md-95 {
    padding-top: 95px;
  }
  .pt-md-100 {
    padding-top: 100px;
  }
  /*-- Padding Bottom --*/
  .pb-md-00 {
    padding-bottom: 0px !important;
  }
  .pb-md-5 {
    padding-bottom: 5px !important;
  }
  .pb-md-10 {
    padding-bottom: 10px;
  }
  .pb-md-15 {
    padding-bottom: 15px;
  }
  .pb-md-20 {
    padding-bottom: 20px;
  }
  .pb-md-25 {
    padding-bottom: 25px;
  }
  .pb-md-30 {
    padding-bottom: 30px;
  }
  .pb-md-35 {
    padding-bottom: 35px;
  }
  .pb-md-40 {
    padding-bottom: 40px;
  }
  .pb-md-45 {
    padding-bottom: 45px;
  }
  .pb-md-50 {
    padding-bottom: 50px;
  }
  .pb-md-55 {
    padding-bottom: 55px;
  }
  .pb-md-60 {
    padding-bottom: 60px;
  }
  .pb-md-65 {
    padding-bottom: 65px;
  }
  .pb-md-70 {
    padding-bottom: 70px;
  }
  .pb-md-75 {
    padding-bottom: 75px;
  }
  .pb-md-80 {
    padding-bottom: 80px;
  }
  .pb-md-85 {
    padding-bottom: 85px;
  }
  .pb-md-90 {
    padding-bottom: 90px;
  }
  .pb-md-95 {
    padding-bottom: 95px;
  }
  .pb-md-100 {
    padding-bottom: 100px;
  }
  /*-- Padding Left --*/
  .pl-md-00 {
    padding-left: 0px !important;
  }
  .pl-md-5 {
    padding-left: 5px !important;
  }
  .pl-md-10 {
    padding-left: 10px;
  }
  .pl-md-15 {
    padding-left: 15px;
  }
  .pl-md-20 {
    padding-left: 20px;
  }
  .pl-md-25 {
    padding-left: 25px;
  }
  .pl-md-30 {
    padding-left: 30px;
  }
  .pl-md-35 {
    padding-left: 35px;
  }
  .pl-md-35 {
    padding-left: 35px;
  }
  .pl-md-40 {
    padding-left: 40px;
  }
  .pl-md-45 {
    padding-left: 45px;
  }
  .pl-md-50 {
    padding-left: 50px;
  }
  .pl-md-55 {
    padding-left: 55px;
  }
  .pl-md-60 {
    padding-left: 60px;
  }
  .pl-md-65 {
    padding-left: 65px;
  }
  .pl-md-70 {
    padding-left: 70px;
  }
  .pl-md-75 {
    padding-left: 75px;
  }
  .pl-md-80 {
    padding-left: 80px;
  }
  .pl-md-85 {
    padding-left: 85px;
  }
  .pl-md-90 {
    padding-left: 90px;
  }
  .pl-md-95 {
    padding-left: 95px;
  }
  .pl-md-100 {
    padding-left: 100px;
  }
  /*-- Padding Right --*/
  .pr-md-00 {
    padding-right: 0px !important;
  }
  .pr-md-5 {
    padding-right: 5px !important;
  }
  .pr-md-10 {
    padding-right: 10px;
  }
  .pr-md-15 {
    padding-right: 15px;
  }
  .pr-md-20 {
    padding-right: 20px;
  }
  .pr-md-25 {
    padding-right: 35px;
  }
  .pr-md-30 {
    padding-right: 30px;
  }
  .pr-md-35 {
    padding-right: 35px;
  }
  .pr-md-35 {
    padding-right: 35px;
  }
  .pr-md-40 {
    padding-right: 40px;
  }
  .pr-md-45 {
    padding-right: 45px;
  }
  .pr-md-50 {
    padding-right: 50px;
  }
  .pr-md-55 {
    padding-right: 55px;
  }
  .pr-md-60 {
    padding-right: 60px;
  }
  .pr-md-65 {
    padding-right: 65px;
  }
  .pr-md-70 {
    padding-right: 70px;
  }
  .pr-md-75 {
    padding-right: 75px;
  }
  .pr-md-80 {
    padding-right: 80px;
  }
  .pr-md-85 {
    padding-right: 85px;
  }
  .pr-md-90 {
    padding-right: 90px;
  }
  .pr-md-95 {
    padding-right: 95px;
  }
  .pr-md-100 {
    padding-right: 100px;
  }
}

/* Small devices (landscape phones, 576px and up) */

@media (max-width: 767px) {
  /*-- Margin Top --*/
  .mt-sm-00 {
    margin-top: 0px !important;
  }
  .mt-sm-5 {
    margin-top: 5px !important;
  }
  .mt-sm-10 {
    margin-top: 10px;
  }
  .mt-sm-15 {
    margin-top: 15px;
  }
  .mt-sm-20 {
    margin-top: 20px;
  }
  .mt-sm-25 {
    margin-top: 25px;
  }
  .mt-sm-30 {
    margin-top: 30px;
  }
  .mt-sm-35 {
    margin-top: 35px;
  }
  .mt-sm-40 {
    margin-top: 40px;
  }
  .mt-sm-45 {
    margin-top: 45px;
  }
  .mt-sm-50 {
    margin-top: 50px;
  }
  .mt-sm-55 {
    margin-top: 55px;
  }
  .mt-sm-60 {
    margin-top: 60px;
  }
  .mt-sm-65 {
    margin-top: 65px;
  }
  .mt-sm-70 {
    margin-top: 70px;
  }
  .mt-sm-75 {
    margin-top: 75px;
  }
  .mt-sm-80 {
    margin-top: 80px;
  }
  .mt-sm-85 {
    margin-top: 85px;
  }
  .mt-sm-90 {
    margin-top: 90px;
  }
  .mt-sm-95 {
    margin-top: 95px;
  }
  .mt-sm-100 {
    margin-top: 100px;
  }
  /*-- Margin Bottom --*/
  .mb-sm-00 {
    margin-bottom: 0px !important;
  }
  .mb-sm-5 {
    margin-bottom: 5px !important;
  }
  .mb-sm-10 {
    margin-bottom: 10px;
  }
  .mb-sm-15 {
    margin-bottom: 15px;
  }
  .mb-sm-20 {
    margin-bottom: 20px;
  }
  .mb-sm-25 {
    margin-bottom: 25px;
  }
  .mb-sm-30 {
    margin-bottom: 30px;
  }
  .mb-sm-35 {
    margin-bottom: 35px;
  }
  .mb-sm-40 {
    margin-bottom: 40px;
  }
  .mb-sm-45 {
    margin-bottom: 45px;
  }
  .mb-sm-50 {
    margin-bottom: 50px;
  }
  .mb-sm-55 {
    margin-bottom: 55px;
  }
  .mb-sm-60 {
    margin-bottom: 60px;
  }
  .mb-sm-65 {
    margin-bottom: 65px;
  }
  .mb-sm-70 {
    margin-bottom: 70px;
  }
  .mb-sm-75 {
    margin-bottom: 75px;
  }
  .mb-sm-80 {
    margin-bottom: 80px;
  }
  .mb-sm-85 {
    margin-bottom: 85px;
  }
  .mb-sm-90 {
    margin-bottom: 90px;
  }
  .mb-sm-95 {
    margin-bottom: 95px;
  }
  .mb-sm-100 {
    margin-bottom: 100px;
  }
  /*--margin right--*/
  .mr-sm-00 {
    margin-right: 0px !important;
  }
  .mr-sm-5 {
    margin-right: 5px !important;
  }
  .mr-sm-10 {
    margin-right: 10px;
  }
  .mr-sm-15 {
    margin-right: 15px;
  }
  .mr-sm-20 {
    margin-right: 20px;
  }
  .mr-sm-25 {
    margin-right: 25px;
  }
  .mr-sm-30 {
    margin-right: 30px;
  }
  .mr-sm-35 {
    margin-right: 35px;
  }
  .mr-sm-40 {
    margin-right: 40px;
  }
  .mr-sm-45 {
    margin-right: 45px;
  }
  .mr-sm-50 {
    margin-right: 50px;
  }
  .mr-sm-55 {
    margin-right: 55px;
  }
  .mr-sm-60 {
    margin-right: 60px;
  }
  .mr-sm-65 {
    margin-right: 65px;
  }
  .mr-sm-70 {
    margin-right: 70px;
  }
  .mr-sm-75 {
    margin-right: 75px;
  }
  .mr-sm-80 {
    margin-right: 80px;
  }
  .mr-sm-85 {
    margin-right: 85px;
  }
  .mr-sm-90 {
    margin-right: 90px;
  }
  .mr-sm-95 {
    margin-right: 95px;
  }
  .mr-sm-100 {
    margin-right: 100px;
  }
  /*--margin left--*/
  .ml-sm-00 {
    margin-left: 0px !important;
  }
  .ml-sm-5 {
    margin-left: 5px !important;
  }
  .ml-sm-10 {
    margin-left: 10px;
  }
  .ml-sm-15 {
    margin-left: 15px;
  }
  .ml-sm-20 {
    margin-left: 20px;
  }
  .ml-sm-25 {
    margin-left: 25px;
  }
  .ml-sm-30 {
    margin-left: 30px;
  }
  .ml-sm-35 {
    margin-left: 35px;
  }
  .ml-sm-40 {
    margin-left: 40px;
  }
  .ml-sm-45 {
    margin-left: 45px;
  }
  .ml-sm-50 {
    margin-left: 50px;
  }
  .ml-sm-55 {
    margin-left: 55px;
  }
  .ml-sm-60 {
    margin-left: 60px;
  }
  .ml-sm-65 {
    margin-left: 65px;
  }
  .ml-sm-70 {
    margin-left: 70px;
  }
  .ml-sm-75 {
    margin-left: 75px;
  }
  .ml-sm-80 {
    margin-left: 80px;
  }
  .ml-sm-85 {
    margin-left: 85px;
  }
  .ml-sm-90 {
    margin-left: 90px;
  }
  .ml-sm-95 {
    margin-left: 95px;
  }
  .ml-sm-100 {
    margin-left: 100px;
  }
  /*-- Padding Top --*/
  .pt-sm-00 {
    padding-top: 0px !important;
  }
  .pt-sm-5 {
    padding-top: 5px !important;
  }
  .pt-sm-10 {
    padding-top: 10px;
  }
  .pt-sm-15 {
    padding-top: 15px;
  }
  .pt-sm-20 {
    padding-top: 20px;
  }
  .pt-sm-25 {
    padding-top: 25px;
  }
  .pt-sm-30 {
    padding-top: 30px;
  }
  .pt-sm-35 {
    padding-top: 35px;
  }
  .pt-sm-40 {
    padding-top: 40px;
  }
  .pt-sm-45 {
    padding-top: 45px;
  }
  .pt-sm-50 {
    padding-top: 50px;
  }
  .pt-sm-55 {
    padding-top: 55px;
  }
  .pt-sm-60 {
    padding-top: 60px;
  }
  .pt-sm-65 {
    padding-top: 65px;
  }
  .pt-sm-70 {
    padding-top: 70px;
  }
  .pt-sm-75 {
    padding-top: 75px;
  }
  .pt-sm-80 {
    padding-top: 80px;
  }
  .pt-sm-85 {
    padding-top: 85px;
  }
  .pt-sm-90 {
    padding-top: 90px;
  }
  .pt-sm-95 {
    padding-top: 95px;
  }
  .pt-sm-100 {
    padding-top: 100px;
  }
  /*-- Padding Bottom --*/
  .pb-sm-00 {
    padding-bottom: 0px !important;
  }
  .pb-sm-5 {
    padding-bottom: 5px !important;
  }
  .pb-sm-10 {
    padding-bottom: 10px;
  }
  .pb-sm-15 {
    padding-bottom: 15px;
  }
  .pb-sm-20 {
    padding-bottom: 20px;
  }
  .pb-sm-25 {
    padding-bottom: 25px;
  }
  .pb-sm-30 {
    padding-bottom: 30px;
  }
  .pb-sm-35 {
    padding-bottom: 35px;
  }
  .pb-sm-40 {
    padding-bottom: 40px;
  }
  .pb-sm-45 {
    padding-bottom: 45px;
  }
  .pb-sm-50 {
    padding-bottom: 50px;
  }
  .pb-sm-55 {
    padding-bottom: 55px;
  }
  .pb-sm-60 {
    padding-bottom: 60px;
  }
  .pb-sm-65 {
    padding-bottom: 65px;
  }
  .pb-sm-70 {
    padding-bottom: 70px;
  }
  .pb-sm-75 {
    padding-bottom: 75px;
  }
  .pb-sm-80 {
    padding-bottom: 80px;
  }
  .pb-sm-85 {
    padding-bottom: 85px;
  }
  .pb-sm-90 {
    padding-bottom: 90px;
  }
  .pb-sm-95 {
    padding-bottom: 95px;
  }
  .pb-sm-100 {
    padding-bottom: 100px;
  }
  /*-- Padding Left --*/
  .pl-sm-00 {
    padding-left: 0px !important;
  }
  .pl-sm-5 {
    padding-left: 5px !important;
  }
  .pl-sm-10 {
    padding-left: 10px;
  }
  .pl-sm-15 {
    padding-left: 15px;
  }
  .pl-sm-20 {
    padding-left: 20px;
  }
  .pl-sm-25 {
    padding-left: 25px;
  }
  .pl-sm-30 {
    padding-left: 30px;
  }
  .pl-sm-35 {
    padding-left: 35px;
  }
  .pl-sm-35 {
    padding-left: 35px;
  }
  .pl-sm-40 {
    padding-left: 40px;
  }
  .pl-sm-45 {
    padding-left: 45px;
  }
  .pl-sm-50 {
    padding-left: 50px;
  }
  .pl-sm-55 {
    padding-left: 55px;
  }
  .pl-sm-60 {
    padding-left: 60px;
  }
  .pl-sm-65 {
    padding-left: 65px;
  }
  .pl-sm-70 {
    padding-left: 70px;
  }
  .pl-sm-75 {
    padding-left: 75px;
  }
  .pl-sm-80 {
    padding-left: 80px;
  }
  .pl-sm-85 {
    padding-left: 85px;
  }
  .pl-sm-90 {
    padding-left: 90px;
  }
  .pl-sm-95 {
    padding-left: 95px;
  }
  .pl-sm-100 {
    padding-left: 100px;
  }
  /*-- Padding Right --*/
  .pr-sm-00 {
    padding-right: 0px !important;
  }
  .pr-sm-5 {
    padding-right: 5px !important;
  }
  .pr-sm-10 {
    padding-right: 10px;
  }
  .pr-sm-15 {
    padding-right: 15px;
  }
  .pr-sm-20 {
    padding-right: 20px;
  }
  .pr-sm-25 {
    padding-right: 35px;
  }
  .pr-sm-30 {
    padding-right: 30px;
  }
  .pr-sm-35 {
    padding-right: 35px;
  }
  .pr-sm-35 {
    padding-right: 35px;
  }
  .pr-sm-40 {
    padding-right: 40px;
  }
  .pr-sm-45 {
    padding-right: 45px;
  }
  .pr-sm-50 {
    padding-right: 50px;
  }
  .pr-sm-55 {
    padding-right: 55px;
  }
  .pr-sm-60 {
    padding-right: 60px;
  }
  .pr-sm-65 {
    padding-right: 65px;
  }
  .pr-sm-70 {
    padding-right: 70px;
  }
  .pr-sm-75 {
    padding-right: 75px;
  }
  .pr-sm-80 {
    padding-right: 80px;
  }
  .pr-sm-85 {
    padding-right: 85px;
  }
  .pr-sm-90 {
    padding-right: 90px;
  }
  .pr-sm-95 {
    padding-right: 95px;
  }
  .pr-sm-100 {
    padding-right: 100px;
  }
}

/* Small devices (landscape phones, 576px and up) */

@media (max-width: 575px) {
  /*-- Margin Top --*/
  .mt-xs-00 {
    margin-top: 0px !important;
  }
  .mt-xs-5 {
    margin-top: 5px !important;
  }
  .mt-xs-10 {
    margin-top: 10px;
  }
  .mt-xs-15 {
    margin-top: 15px;
  }
  .mt-xs-20 {
    margin-top: 20px;
  }
  .mt-xs-25 {
    margin-top: 25px;
  }
  .mt-xs-30 {
    margin-top: 30px;
  }
  .mt-xs-35 {
    margin-top: 35px;
  }
  .mt-xs-40 {
    margin-top: 40px;
  }
  .mt-xs-45 {
    margin-top: 45px;
  }
  .mt-xs-50 {
    margin-top: 50px;
  }
  .mt-xs-55 {
    margin-top: 55px;
  }
  .mt-xs-60 {
    margin-top: 60px;
  }
  .mt-xs-65 {
    margin-top: 65px;
  }
  .mt-xs-70 {
    margin-top: 70px;
  }
  .mt-xs-75 {
    margin-top: 75px;
  }
  .mt-xs-80 {
    margin-top: 80px;
  }
  .mt-xs-85 {
    margin-top: 85px;
  }
  .mt-xs-90 {
    margin-top: 90px;
  }
  .mt-xs-95 {
    margin-top: 95px;
  }
  .mt-xs-100 {
    margin-top: 100px;
  }
  /*-- Margin Bottom --*/
  .mb-xs-00 {
    margin-bottom: 0px !important;
  }
  .mb-xs-5 {
    margin-bottom: 5px !important;
  }
  .mb-xs-10 {
    margin-bottom: 10px;
  }
  .mb-xs-15 {
    margin-bottom: 15px;
  }
  .mb-xs-20 {
    margin-bottom: 20px;
  }
  .mb-xs-25 {
    margin-bottom: 25px;
  }
  .mb-xs-30 {
    margin-bottom: 30px;
  }
  .mb-xs-35 {
    margin-bottom: 35px;
  }
  .mb-xs-40 {
    margin-bottom: 40px;
  }
  .mb-xs-45 {
    margin-bottom: 45px;
  }
  .mb-xs-50 {
    margin-bottom: 50px;
  }
  .mb-xs-55 {
    margin-bottom: 55px;
  }
  .mb-xs-60 {
    margin-bottom: 60px;
  }
  .mb-xs-65 {
    margin-bottom: 65px;
  }
  .mb-xs-70 {
    margin-bottom: 70px;
  }
  .mb-xs-75 {
    margin-bottom: 75px;
  }
  .mb-xs-80 {
    margin-bottom: 80px;
  }
  .mb-xs-85 {
    margin-bottom: 85px;
  }
  .mb-xs-90 {
    margin-bottom: 90px;
  }
  .mb-xs-95 {
    margin-bottom: 95px;
  }
  .mb-xs-100 {
    margin-bottom: 100px;
  }
  /*--margin right--*/
  .mr-xs-00 {
    margin-right: 0px !important;
  }
  .mr-xs-5 {
    margin-right: 5px !important;
  }
  .mr-xs-10 {
    margin-right: 10px;
  }
  .mr-xs-15 {
    margin-right: 15px;
  }
  .mr-xs-20 {
    margin-right: 20px;
  }
  .mr-xs-25 {
    margin-right: 25px;
  }
  .mr-xs-30 {
    margin-right: 30px;
  }
  .mr-xs-35 {
    margin-right: 35px;
  }
  .mr-xs-40 {
    margin-right: 40px;
  }
  .mr-xs-45 {
    margin-right: 45px;
  }
  .mr-xs-50 {
    margin-right: 50px;
  }
  .mr-xs-55 {
    margin-right: 55px;
  }
  .mr-xs-60 {
    margin-right: 60px;
  }
  .mr-xs-65 {
    margin-right: 65px;
  }
  .mr-xs-70 {
    margin-right: 70px;
  }
  .mr-xs-75 {
    margin-right: 75px;
  }
  .mr-xs-80 {
    margin-right: 80px;
  }
  .mr-xs-85 {
    margin-right: 85px;
  }
  .mr-xs-90 {
    margin-right: 90px;
  }
  .mr-xs-95 {
    margin-right: 95px;
  }
  .mr-xs-100 {
    margin-right: 100px;
  }
  /*--margin left--*/
  .ml-xs-00 {
    margin-left: 0px !important;
  }
  .ml-xs-5 {
    margin-left: 5px !important;
  }
  .ml-xs-10 {
    margin-left: 10px;
  }
  .ml-xs-15 {
    margin-left: 15px;
  }
  .ml-xs-20 {
    margin-left: 20px;
  }
  .ml-xs-25 {
    margin-left: 25px;
  }
  .ml-xs-30 {
    margin-left: 30px;
  }
  .ml-xs-35 {
    margin-left: 35px;
  }
  .ml-xs-40 {
    margin-left: 40px;
  }
  .ml-xs-45 {
    margin-left: 45px;
  }
  .ml-xs-50 {
    margin-left: 50px;
  }
  .ml-xs-55 {
    margin-left: 55px;
  }
  .ml-xs-60 {
    margin-left: 60px;
  }
  .ml-xs-65 {
    margin-left: 65px;
  }
  .ml-xs-70 {
    margin-left: 70px;
  }
  .ml-xs-75 {
    margin-left: 75px;
  }
  .ml-xs-80 {
    margin-left: 80px;
  }
  .ml-xs-85 {
    margin-left: 85px;
  }
  .ml-xs-90 {
    margin-left: 90px;
  }
  .ml-xs-95 {
    margin-left: 95px;
  }
  .ml-xs-100 {
    margin-left: 100px;
  }
  /*-- Padding Top --*/
  .pt-xs-00 {
    padding-top: 0px !important;
  }
  .pt-xs-5 {
    padding-top: 5px !important;
  }
  .pt-xs-10 {
    padding-top: 10px;
  }
  .pt-xs-15 {
    padding-top: 15px;
  }
  .pt-xs-20 {
    padding-top: 20px;
  }
  .pt-xs-25 {
    padding-top: 25px;
  }
  .pt-xs-30 {
    padding-top: 30px;
  }
  .pt-xs-35 {
    padding-top: 35px;
  }
  .pt-xs-40 {
    padding-top: 40px;
  }
  .pt-xs-45 {
    padding-top: 45px;
  }
  .pt-xs-50 {
    padding-top: 50px;
  }
  .pt-xs-55 {
    padding-top: 55px;
  }
  .pt-xs-60 {
    padding-top: 60px;
  }
  .pt-xs-65 {
    padding-top: 65px;
  }
  .pt-xs-70 {
    padding-top: 70px;
  }
  .pt-xs-75 {
    padding-top: 75px;
  }
  .pt-xs-80 {
    padding-top: 80px;
  }
  .pt-xs-85 {
    padding-top: 85px;
  }
  .pt-xs-90 {
    padding-top: 90px;
  }
  .pt-xs-95 {
    padding-top: 95px;
  }
  .pt-xs-100 {
    padding-top: 100px;
  }
  /*-- Padding Bottom --*/
  .pb-xs-00 {
    padding-bottom: 0px !important;
  }
  .pb-xs-5 {
    padding-bottom: 5px !important;
  }
  .pb-xs-10 {
    padding-bottom: 10px;
  }
  .pb-xs-15 {
    padding-bottom: 15px;
  }
  .pb-xs-20 {
    padding-bottom: 20px;
  }
  .pb-xs-25 {
    padding-bottom: 25px;
  }
  .pb-xs-30 {
    padding-bottom: 30px;
  }
  .pb-xs-35 {
    padding-bottom: 35px;
  }
  .pb-xs-40 {
    padding-bottom: 40px;
  }
  .pb-xs-45 {
    padding-bottom: 45px;
  }
  .pb-xs-50 {
    padding-bottom: 50px;
  }
  .pb-xs-55 {
    padding-bottom: 55px;
  }
  .pb-xs-60 {
    padding-bottom: 60px;
  }
  .pb-xs-65 {
    padding-bottom: 65px;
  }
  .pb-xs-70 {
    padding-bottom: 70px;
  }
  .pb-xs-75 {
    padding-bottom: 75px;
  }
  .pb-xs-80 {
    padding-bottom: 80px;
  }
  .pb-xs-85 {
    padding-bottom: 85px;
  }
  .pb-xs-90 {
    padding-bottom: 90px;
  }
  .pb-xs-95 {
    padding-bottom: 95px;
  }
  .pb-xs-100 {
    padding-bottom: 100px;
  }
  /*-- Padding Left --*/
  .pl-xs-00 {
    padding-left: 0px !important;
  }
  .pl-xs-5 {
    padding-left: 5px !important;
  }
  .pl-xs-10 {
    padding-left: 10px;
  }
  .pl-xs-15 {
    padding-left: 15px;
  }
  .pl-xs-20 {
    padding-left: 20px;
  }
  .pl-xs-25 {
    padding-left: 25px;
  }
  .pl-xs-30 {
    padding-left: 30px;
  }
  .pl-xs-35 {
    padding-left: 35px;
  }
  .pl-xs-35 {
    padding-left: 35px;
  }
  .pl-xs-40 {
    padding-left: 40px;
  }
  .pl-xs-45 {
    padding-left: 45px;
  }
  .pl-xs-50 {
    padding-left: 50px;
  }
  .pl-xs-55 {
    padding-left: 55px;
  }
  .pl-xs-60 {
    padding-left: 60px;
  }
  .pl-xs-65 {
    padding-left: 65px;
  }
  .pl-xs-70 {
    padding-left: 70px;
  }
  .pl-xs-75 {
    padding-left: 75px;
  }
  .pl-xs-80 {
    padding-left: 80px;
  }
  .pl-xs-85 {
    padding-left: 85px;
  }
  .pl-xs-90 {
    padding-left: 90px;
  }
  .pl-xs-95 {
    padding-left: 95px;
  }
  .pl-xs-100 {
    padding-left: 100px;
  }
  /*-- Padding Right --*/
  .pr-xs-00 {
    padding-right: 0px !important;
  }
  .pr-xs-5 {
    padding-right: 5px !important;
  }
  .pr-xs-10 {
    padding-right: 10px;
  }
  .pr-xs-15 {
    padding-right: 15px;
  }
  .pr-xs-20 {
    padding-right: 20px;
  }
  .pr-xs-25 {
    padding-right: 35px;
  }
  .pr-xs-30 {
    padding-right: 30px;
  }
  .pr-xs-35 {
    padding-right: 35px;
  }
  .pr-xs-35 {
    padding-right: 35px;
  }
  .pr-xs-40 {
    padding-right: 40px;
  }
  .pr-xs-45 {
    padding-right: 45px;
  }
  .pr-xs-50 {
    padding-right: 50px;
  }
  .pr-xs-55 {
    padding-right: 55px;
  }
  .pr-xs-60 {
    padding-right: 60px;
  }
  .pr-xs-65 {
    padding-right: 65px;
  }
  .pr-xs-70 {
    padding-right: 70px;
  }
  .pr-xs-75 {
    padding-right: 75px;
  }
  .pr-xs-80 {
    padding-right: 80px;
  }
  .pr-xs-85 {
    padding-right: 85px;
  }
  .pr-xs-90 {
    padding-right: 90px;
  }
  .pr-xs-95 {
    padding-right: 95px;
  }
  .pr-xs-100 {
    padding-right: 100px;
  }
}

/*============================================*/

/* Font size options */

/*============================================*/

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.fs-21 {
  font-size: 21px;
}

.fs-22 {
  font-size: 22px;
}

.fs-23 {
  font-size: 23px;
}

.fs-24 {
  font-size: 24px;
}

.fs-25 {
  font-size: 25px;
}

.fs-26 {
  font-size: 26px;
}

.fs-27 {
  font-size: 27px;
}

.fs-28 {
  font-size: 28px;
}

.fs-29 {
  font-size: 29px;
}

.fs-30 {
  font-size: 30px;
}

.fs-31 {
  font-size: 31px;
}

.fs-32 {
  font-size: 32px;
}

.fs-33 {
  font-size: 33px;
}

.fs-34 {
  font-size: 34px;
}

.fs-35 {
  font-size: 35px;
}

.fs-36 {
  font-size: 36px;
}

.fs-37 {
  font-size: 37px;
}

.fs-38 {
  font-size: 38px;
}

.fs-39 {
  font-size: 39px;
}

.fs-40 {
  font-size: 40px;
}

.fs-41 {
  font-size: 41px;
}

.fs-42 {
  font-size: 42px;
}

.fs-43 {
  font-size: 43px;
}

.fs-44 {
  font-size: 44px;
}

.fs-45 {
  font-size: 45px;
}

.fs-46 {
  font-size: 46px;
}

.fs-47 {
  font-size: 47px;
}

.fs-48 {
  font-size: 48px;
}

.fs-49 {
  font-size: 49px;
}

.fs-50 {
  font-size: 50px;
}

.fs-51 {
  font-size: 51px;
}

.fs-52 {
  font-size: 52px;
}

.fs-53 {
  font-size: 53px;
}

.fs-54 {
  font-size: 54px;
}

.fs-55 {
  font-size: 55px;
}

.fs-56 {
  font-size: 56px;
}

.fs-57 {
  font-size: 57px;
}

.fs-58 {
  font-size: 58px;
}

.fs-59 {
  font-size: 59px;
}

.fs-60 {
  font-size: 60px;
}

/*============================================*/

/* Font weight options */

/*============================================*/

.f-900 {
  font-weight: 900;
}

.f-800 {
  font-weight: 800;
}

.f-700 {
  font-weight: 700;
}

.f-600 {
  font-weight: 600;
}

.f-500 {
  font-weight: 500;
}

.f-400 {
  font-weight: 400;
}

.f-300 {
  font-weight: 300;
}

.f-200 {
  font-weight: 200;
}

.f-100 {
  font-weight: 100;
}

.italic {
  font-style: italic;
}

/*============================================*/

/* Line height */

/*============================================*/

.lh-10 {
  line-height: 1;
}

.lh-11 {
  line-height: 1.1;
}

.lh-12 {
  line-height: 1.2;
}

.lh-13 {
  line-height: 1.3;
}

.lh-14 {
  line-height: 1.4;
}

.lh-15 {
  line-height: 1.5;
}

.lh-16 {
  line-height: 1.6;
}

.lh-17 {
  line-height: 1.7;
}

.lh-18 {
  line-height: 1.8;
}

.lh-19 {
  line-height: 1.9;
}

.lh-20 {
  line-height: 2;
}

.lh-21 {
  line-height: 2.1;
}

.lh-22 {
  line-height: 2.2;
}

.lh-23 {
  line-height: 2.3;
}

.lh-24 {
  line-height: 2.4;
}

.lh-25 {
  line-height: 2.5;
}

.lh-26 {
  line-height: 2.6;
}

.lh-27 {
  line-height: 2.7;
}

.lh-28 {
  line-height: 2.8;
}

.lh-29 {
  line-height: 2.9;
}

.lh-30 {
  line-height: 3;
}

/*============================================*/

/* Radius options */

/*============================================*/

.radius-1 {
  border-radius: 1px;
}

.radius-2 {
  border-radius: 2px;
}

.radius-3 {
  border-radius: 3px;
}

.radius-4 {
  border-radius: 4px;
}

.radius-5 {
  border-radius: 5px;
}

.radius-6 {
  border-radius: 6px;
}

.radius-7 {
  border-radius: 7px;
}

.radius-8 {
  border-radius: 8px;
}

.radius-9 {
  border-radius: 9px;
}

.radius-10 {
  border-radius: 10px;
}

.radius-15 {
  border-radius: 15px;
}

.radius-20 {
  border-radius: 20px;
}

.radius-25 {
  border-radius: 25px;
}

.radius-30 {
  border-radius: 30px;
}

.radius-35 {
  border-radius: 35px;
}

.radius-40 {
  border-radius: 40px;
}

.radius-45 {
  border-radius: 45px;
}

.radius-50 {
  border-radius: 50px;
}

.radius-100 {
  border-radius: 100px;
}

/*============================================*/

/* Opacity options */

/*============================================*/

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 0.1;
}

.opacity-2 {
  opacity: 0.2;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-4 {
  opacity: 0.4;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-7 {
  opacity: 0.7;
}

.opacity-8 {
  opacity: 0.8;
}

.opacity-9 {
  opacity: 0.9;
}

.opacity-10 {
  opacity: 1;
}

/*============================================*/

/* z-index values */

/*============================================*/

.z-1,
.z-2,
.z-3,
.z-4,
.z-5,
.z-6,
.z-7,
.z-8,
.z-9,
.z-10,
.z-15,
.z-20,
.z-25,
.z-30,
.z-35,
.z-40,
.z-45,
.z-50 {
  position: relative;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.z-5 {
  z-index: 5;
}

.z-6 {
  z-index: 6;
}

.z-7 {
  z-index: 7;
}

.z-8 {
  z-index: 8;
}

.z-9 {
  z-index: 9;
}

.z-10 {
  z-index: 10;
}

.z-15 {
  z-index: 15;
}

.z-20 {
  z-index: 20;
}

.z-25 {
  z-index: 25;
}

.z-30 {
  z-index: 30;
}

.z-35 {
  z-index: 35;
}

.z-40 {
  z-index: 40;
}

.z-45 {
  z-index: 45;
}

.z-50 {
  z-index: 50;
}

/*============================================*/

/* Overlay color bg options */

/*============================================*/

.bg-cover {
  background-size: cover !important;
  background-position: center center !important;
}

[data-overlay] {
  position: relative;
  background-size: cover;
  background-position: center center;
}

[data-overlay]::before {
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

[data-overlay='1']::before {
  opacity: 0.1;
}

[data-overlay='2']::before {
  opacity: 0.2;
}

[data-overlay='3']::before {
  opacity: 0.3;
}

[data-overlay='4']::before {
  opacity: 0.4;
}

[data-overlay='5']::before {
  opacity: 0.5;
}

[data-overlay='6']::before {
  opacity: 0.6;
}

[data-overlay='7']::before {
  opacity: 0.7;
}

[data-overlay='8']::before {
  opacity: 0.8;
}

[data-overlay='9']::before {
  opacity: 0.9;
}

[data-overlay='94']::before {
  opacity: 0.94;
}

[data-overlay='95']::before {
  opacity: 0.95;
}

[data-overlay='96']::before {
  opacity: 0.96;
}

.o-hidden {
  overflow: hidden;
}

/*============================================*/

/* Custome radio and checkbox */

/*============================================*/

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  /* background-color: #fff; */
  border: 1px solid #000;
}

.custom-control-label::before {
  top: 4px;
  border-radius: 0px;
  width: 14px;
  height: 14px;
  background-color: #fff;
  border: 1px solid #35322b;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0px;
}

.custom-control-label::after {
  width: 14px;
  height: 14px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='black' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  background-size: 10px;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

/*============================================*/

/* Star rating  */

/*============================================*/

[data-starsactive] li i {
  color: rgba(19, 27, 35, 0.2);
}

[data-starsactive~='1'] li i:nth-child(1) {
  color: #f6ae2d;
}

[data-starsactive~='2'] li i:nth-child(1),
[data-starsactive~='2'] li i:nth-child(2) {
  color: #f6ae2d;
}

[data-starsactive~='3'] li i:nth-child(1),
[data-starsactive~='3'] li i:nth-child(2),
[data-starsactive~='3'] li i:nth-child(3) {
  color: #f6ae2d;
}

[data-starsactive~='4'] li i:nth-child(1),
[data-starsactive~='4'] li i:nth-child(2),
[data-starsactive~='4'] li i:nth-child(3),
[data-starsactive~='4'] li i:nth-child(4) {
  color: #f6ae2d;
}

[data-starsactive~='5'] li i:nth-child(1),
[data-starsactive~='5'] li i:nth-child(2),
[data-starsactive~='5'] li i:nth-child(3),
[data-starsactive~='5'] li i:nth-child(4),
[data-starsactive~='5'] li i:nth-child(5) {
  color: #f6ae2d;
}

/*============================================*/

/* Custome animation classess */

/*============================================*/

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20%, 0);
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20%, 0);
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-3%, 0, 0);
    transform: translate3d(-3%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-3%, 0, 0);
    transform: translate3d(-3%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(3%, 0, 0);
    transform: translate3d(3%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(3%, 0, 0);
    transform: translate3d(3%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 5%, 0);
    transform: translate3d(0, 5%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 5%, 0);
    transform: translate3d(0, 5%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

.blob-small {
  animation: pulse-blue-small 2s infinite;
}

/*header animation*/

@-webkit-keyframes showHeader {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}

@-moz-keyframes showHeader {
  0% {
    -moz-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -moz-transform: none;
    transform: none;
  }
}

@-o-keyframes showHeader {
  0% {
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -o-transform: none;
    transform: none;
  }
}

@keyframes showHeader {
  0% {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: none;
    -moz-transform: none;
    -o-transform: none;
    transform: none;
  }
}

@keyframes animatedBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -300px 0;
  }
}

@-moz-keyframes animatedBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -300px 0;
  }
}

@-webkit-keyframes animatedBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -300px 0;
  }
}

@-ms-keyframes animatedBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -300px 0;
  }
}

@-o-keyframes animatedBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -300px 0;
  }
}

@keyframes zoominout {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.08, 1.08);
  }
  100% {
    transform: scale(1, 1);
  }
}

/*============================================*/

/* Other styles */

/*============================================*/

/*img-full wifth*/

.img-100 {
  width: 100%;
}

.full-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

/*transform center*/

.transform-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/*transform v-center*/

.transform-v-center {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

/*transform h-center*/

.transform-h-center {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

/*relative*/

.relative {
  position: relative;
}

/*hr*/

.hr-1 {
  height: 1px;
}

.hr-2 {
  height: 2px;
}

.hr-3 {
  height: 3px;
}

/*transition 0.4s */

.transition-2,
.transition-2:hover {
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.transition-3,
.transition-3:hover {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.transition-4,
.transition-4:hover {
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.transition-5,
.transition-5:hover {
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
  -webkit-transition: all 500ms cubic-bezier(0.855, 0, 0.155, 1);
  -moz-transition: all 500ms cubic-bezier(0.855, 0, 0.155, 1);
  -o-transition: all 500ms cubic-bezier(0.855, 0, 0.155, 1);
  transition: all 500ms cubic-bezier(0.855, 0, 0.155, 1);
}

/*box shadow options */

.shadow-1 {
  -moz-box-shadow: 0px 13px 20px -8px rgba(19, 27, 35, 0.12);
  -webkit-box-shadow: 0px 13px 20px -8px rgba(19, 27, 35, 0.12);
  box-shadow: 0px 13px 20px -8px rgba(19, 27, 35, 0.12);
}

.shadow-2 {
  -moz-box-shadow: 0px 13px 20px -19px rgba(19, 27, 35, 0.12);
  -webkit-box-shadow: 0px 13px 20px -19px rgba(19, 27, 35, 0.12);
  box-shadow: 0px 13px 20px -19px rgba(19, 27, 35, 0.12);
}

.shadow-3 {
  -moz-box-shadow: 0px 5px 19px -7px rgba(0, 0, 0, 0.65);
  -webkit-box-shadow: 0px 5px 19px -7px rgba(0, 0, 0, 0.65);
  box-shadow: 0px 5px 19px -7px rgba(0, 0, 0, 0.65);
}

.shadow-4 {
  -moz-box-shadow: 0px 5px 19px -7px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0px 5px 19px -7px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 5px 19px -7px rgba(0, 0, 0, 0.35);
}

.shadow-5 {
  -moz-box-shadow: 0px 5px 19px -7px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 5px 19px -7px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 5px 19px -7px rgba(0, 0, 0, 0.15);
}

/*flex-center*/

.flex-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/*modal fade*/

.modal-backdrop {
  background-color: #282f41;
}

.modal-backdrop.show {
  opacity: 0.95;
}
